import type { IconNames } from '@fin/icons';
import type { Currency } from '@fin/shared';

import type { TransferType } from './beneficiary';

export enum BankAccountType {
  directDebit = 'direct-debit',
  withdraw = 'withdraw',
}

export enum BankAccountApprovalStatus {
  approved = 'APPROVED',
  pending = 'PENDING',
  rejected = 'REJECTED',
}

export const bankAccountApprovalStatusLabels: Record<BankAccountApprovalStatus, string> = {
  [BankAccountApprovalStatus.approved]: 'Active',
  [BankAccountApprovalStatus.pending]: 'Pending',
  [BankAccountApprovalStatus.rejected]: 'Declined',
};

export const bankAccountApprovalStatusBadgeMapping: Record<
  BankAccountApprovalStatus,
  { color: string; icon: IconNames }
> = {
  [BankAccountApprovalStatus.approved]: { color: 'gray', icon: 'check' },
  [BankAccountApprovalStatus.pending]: { color: 'blue', icon: 'pending' },
  [BankAccountApprovalStatus.rejected]: { color: 'red', icon: 'close' },
};

export type WithdrawalBankAccount = {
  id: string;
  businessId: string;
  country: string;
  currency: Currency;
  bankAccountInformation: {
    awPaymentMethod: TransferType.local;
    fields: Array<{ key: string; value: string }>;
  };
  accountName: string;
  nickname?: string;
  holderName: string;
  bankName: string;
  bankBranch: string;
  bankAddress: string;
  addressLine_1: string;
  addressLine_2: string;
  city: string;
  state: string;
  zip: string;
  email: string | null;
  phone: string | null;
  status: string;
  approvalStatus: BankAccountApprovalStatus;
};

export type WithdrawalPaymentOptions = Record<
  Lowercase<Currency>,
  Record<
    TransferType.local,
    { label: string; fields: Array<{ key: string; label: string; validation: string }>; requiredIdentifiers: string[] }
  >
>;

export type BankBranchInfo = {
  identifierValue: string;
  identifierType: string;
  accountNumber: string | null;
  bicSwift: string;
  bankName: string;
  bankBranch: string;
  bankAddress: string;
  bankCity: string;
  bankState: string;
  bankPostCode: string;
  bankCountryIso: string;
  bankCountry: string;
  currency: Currency | null;
};

export type FundingDetails = {
  holderName: string;
  accountNumber: string;
  accountNumberType: string;
  accountNumberTypeLabel: string;
  allowedFundsSource: string;
  allowedFundsSourceLabel: string;
  bankAddress: string;
  bankAddressDetails: {
    bankName: string;
    bankAddressLine_1: string;
    bankAddressLine_2: string | null;
    bankCity: string;
    bankState: string | null;
    bankZip: string;
    bankCountry: string;
  };
  bankCountry: string;
  bankName: string;
  currency: Currency;
  delayDays: string;
  description: {
    addFunds: string;
    bankFees: string;
  };
  fees: number;
  labelKey: string;
  paymentType: TransferType;
  paymentTypeLabel: string;
  recommended: boolean;
  referenceNumber: string;
  routingFields: Array<{ label: string; value: string; type: string }>;
  showInReceiveFunds: false;
  transferLimit: {
    min: number;
    max: number | null;
  };
  _id: string;
};

export type DDBankAccount = {
  holderName: string;
  nickname: string;
  bankAccountInformation: {
    awPaymentMethod: TransferType.local;
    fields: Array<{ key: string; value: string }>;
  };
  bankBranchInformation: BankBranchInfo;
  businessId: string;
  country: string;
  currency: Currency;
  id: string;
  status: DDBankAccountStatus;
};

export enum DDBankAccountStatus {
  active = 'ACTIVE',
  closed = 'CLOSED',
  requiresAction = 'REQUIRES_ACTION',
  pending = 'PENDING',
  failed = 'FAILED',
}

export const DDBankAccountStatusLabels: Record<DDBankAccountStatus, string> = {
  [DDBankAccountStatus.active]: 'Active',
  [DDBankAccountStatus.closed]: 'Closed',
  [DDBankAccountStatus.requiresAction]: 'Requires Action',
  [DDBankAccountStatus.pending]: 'Pending',
  [DDBankAccountStatus.failed]: 'Failed',
};

export const DDBankAccountStatusBadgeMapping: Record<DDBankAccountStatus, { color: string; icon: IconNames }> = {
  [DDBankAccountStatus.active]: { color: 'gray', icon: 'check' },
  [DDBankAccountStatus.closed]: { color: 'red', icon: 'close' },
  [DDBankAccountStatus.requiresAction]: { color: 'yellow', icon: 'error' },
  [DDBankAccountStatus.pending]: { color: 'yellow', icon: 'pending' },
  [DDBankAccountStatus.failed]: { color: 'red', icon: 'error' },
};

export const enum WithdrawalStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  PAID = 'PAID',
}

export const withdrawalStatusLabels: Record<WithdrawalStatus, string> = {
  [WithdrawalStatus.IN_PROGRESS]: 'In Progress',
  [WithdrawalStatus.CANCELLED]: 'Cancelled',
  [WithdrawalStatus.FAILED]: 'Failed',
  [WithdrawalStatus.PAID]: 'Paid',
};

export const withdrawalStatusBadgeMapping: Record<WithdrawalStatus, { color: string; icon: IconNames }> = {
  [WithdrawalStatus.IN_PROGRESS]: { color: 'blue', icon: 'pending' },
  [WithdrawalStatus.CANCELLED]: { color: 'red', icon: 'close' },
  [WithdrawalStatus.FAILED]: { color: 'red', icon: 'close' },
  [WithdrawalStatus.PAID]: { color: 'gray', icon: 'check' },
};

export type Withdrawal = {
  withdrawalBankAccount: WithdrawalBankAccount;
  amount: number;
  withdrawalBankAccountId: string;
  businessId: string;
  currencyWallet: Currency;
  description: string | null;
  id: string;
  paymentType: unknown | null;
  properties: unknown | null;
  status: WithdrawalStatus;
};
