import { type Currency, ERPPlatform as ERPPlatformShared } from '@fin/shared';

export { ERPPlatformShared as ERPPlatform };

export const ERPPlatformLabels: Record<ERPPlatformShared, string> = {
  [ERPPlatformShared.NETSUITE]: 'NetSuite',
  [ERPPlatformShared.SAGE_INTACCT]: 'Sage Intacct',
  [ERPPlatformShared.DYNAMICS365]: 'Dynamics 365',
  [ERPPlatformShared.SAGE_BUSINESS_CLOUD]: 'Sage Business Cloud',
  [ERPPlatformShared.XERO]: 'Xero',
  [ERPPlatformShared.QUICKBOOKS]: 'Quickbooks',
};

export const ERPIntegrationTypes = {
  [ERPPlatformShared.NETSUITE]: 'netsuite',
  [ERPPlatformShared.SAGE_INTACCT]: 'sage_intacct',
} as const;

export type ERPIntegrationTypeValues = (typeof ERPIntegrationTypes)[keyof typeof ERPIntegrationTypes];

export type ISODateString = string & { __brand: 'ISODateString' };

type IntegrationBankFeedStatus = 'Enabled' | 'Requires configuration';

export type AccountingIntegration<T extends boolean | undefined> = {
  id: string;
  type: 'rutter';
  businessId: string;
  externalIntegrationId: string;
  details: T extends true
    ? {
        integrationBankFeedStatus: IntegrationBankFeedStatus;
        lastExportTime: ISODateString;
        platform: ERPPlatformShared;
      }
    : {
        platform: ERPPlatformShared;
      };
  capabilities: unknown;
};

export type BankFeedStatus = 'INACTIVE' | 'ACTIVE' | 'PENDING' | 'DELETED';

export type BankFeed = {
  businessId: string;
  integrationId: string;
  externalId: string;
  externalGeneralLedgerAccountId: string | null;
  id: string;
  country: string;
  currency: Currency;
  name: string;
  status: BankFeedStatus;
  transactionStartDate: ISODateString | null;
};

export type LedgerAccount = {
  id: string;
  name: string;
  currencyCode: Currency;
};
