import { Box, type BoxProps } from '@chakra-ui/react';

import type { IconNames } from './IconNames';
import sprite from './icons_sprite.svg';

type IconProps = BoxProps & {
  name: IconNames;
  size: number;
};

export const Icon = ({ name, size, ...rest }: IconProps) => {
  return (
    <Box as="svg" width={`${size}px`} height={`${size}px`} flexShrink={0} {...rest}>
      <use href={`${sprite}#${name}`} />
    </Box>
  );
};
