import React, { useMemo, useState } from 'react';

import { Badge, Box, Flex, Heading, Spinner, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';

import { ClipText, SearchBar, Select, Table, useEntitiesSort } from '@fin/components';
import { Icon } from '@fin/icons';
import { CompanyStatus, companyStatusBadgeMapping, companyStatusLabels } from '@fin/types';
import { dayjs, formatDate } from '@fin/utils';

import { useBusinesses, useMemberships } from '@app/hooks';

import { BusinessDetails } from './BusinessDetails';

type StatusOption = { label: string; value: CompanyStatus };

const statusOptions: StatusOption[] = [
  { label: companyStatusLabels[CompanyStatus.ACTIVE], value: CompanyStatus.ACTIVE },
  { label: companyStatusLabels[CompanyStatus.DISABLED], value: CompanyStatus.DISABLED },
  { label: companyStatusLabels[CompanyStatus.INACTIVE], value: CompanyStatus.INACTIVE },
];

export const Impersonation = () => {
  const { businesses, businessesIsLoading } = useBusinesses();
  const { memberships, membershipsIsLoading } = useMemberships();
  const [search, setSearch] = useState('');
  const [searchStatus, setSearchStatus] = useState<CompanyStatus | null>(null);

  const filteredBusinesses = useMemo(() => {
    if (!memberships || !businesses) return [];

    const joinedBusinesses = businesses.map((business) => ({
      ...business,
      users: memberships.filter((m) => m.business.id === business.id).map((m) => m.user),
    }));

    return joinedBusinesses.filter(
      (business) =>
        (!searchStatus || business.status === searchStatus) &&
        ((!search && !business.companyName) ||
          business.companyName?.toLowerCase().includes(search.trim().toLowerCase()) ||
          business.users
            .map((user) => `${user.firstName} ${user.lastName}`)
            .join(' ')
            .toLowerCase()
            .includes(search.trim().toLowerCase())),
    );
  }, [search, businesses, memberships, searchStatus]);

  const { sortedEntities, changeSortBy, sortBy } = useEntitiesSort(filteredBusinesses, 'creationDate', {});

  const renderContent = () => (
    <Table
      templateColumns="minmax(min-content, max-content) minmax(max-content, 1fr) minmax(max-content, 1fr) minmax(max-content, 200px)"
      keyProp="id"
      entities={sortedEntities}
      sortBy={sortBy}
      onChangeSort={changeSortBy}
      rowProps={(item) => ({
        _hover: { '> .cell': { bg: 'bg.100', cursor: 'pointer' } },
        onClick: () => NiceModal.show(BusinessDetails, { business: item }),
      })}
      columns={[
        {
          title: 'Created at',
          keepOnMobile: true,
          field: 'creationDate',
          renderCell: (item) => <Text variant="primary">{formatDate(dayjs(item.creationDate))}</Text>,
        },
        {
          title: 'Company Name',
          field: 'companyName',
          keepOnMobile: true,
          renderCell: (item) => <Text variant="primary">{item.companyName}</Text>,
        },
        {
          title: 'Team Members',
          renderCell: (item) => (
            <ClipText maxLength={50} variant="primary" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              {item.users.map((user) => `${user.firstName} ${user.lastName}`).join(', ')}
            </ClipText>
          ),
        },
        {
          title: 'Status',
          field: 'status',
          keepOnMobile: true,
          renderCell: (item) => (
            <Badge variant="status" colorScheme={companyStatusBadgeMapping[item.status].color}>
              <Icon name={companyStatusBadgeMapping[item.status].icon} size={18} />
              {companyStatusLabels[item.status]}
            </Badge>
          ),
        },
      ]}
      emptyText={search ? 'There are no businesses matching your search criteria' : 'There are no businesses'}
    />
  );

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={4}
        px={{ base: 4, lg: 6 }}
        bg="white"
        borderBottom="1px"
        borderBottomColor="borderStroke"
      >
        <Heading>Impersonation</Heading>
      </Flex>
      <Box py={4} px={{ base: 4, lg: 6 }}>
        <SearchBar
          value={search}
          onChange={setSearch}
          placeHolder="Search by company name or member name"
          mb={3}
          addBtn={
            <Box flex="none" minW="220px">
              <Select<StatusOption>
                options={statusOptions}
                value={searchStatus}
                isClearable
                onChange={setSearchStatus}
                size="sm"
              />
            </Box>
          }
        />
        {businessesIsLoading || membershipsIsLoading ? (
          <Flex flexDirection="column" flex={1} alignItems="center" justifyContent="center" py={4}>
            <Spinner color="brand.500" />
          </Flex>
        ) : (
          renderContent()
        )}
      </Box>
    </>
  );
};
