import { useCallback, useMemo } from 'react';

import { Badge, Box, Flex, Heading, Text } from '@chakra-ui/react';

import { parseAsString, parseAsStringEnum } from 'nuqs';
import { generatePath, useNavigate } from 'react-router-dom';

import { SearchBar, Select, Table, useEntitiesSort, useSearchFilters } from '@fin/components';
import { Icon } from '@fin/icons';
import {
  type CompanyInfoType,
  CompanyStatus,
  companyStatusBadgeMapping,
  companyStatusLabels,
  KYCBusinessStatus,
  KYCStatusBadgeMapping,
  KYCStatusLabels,
} from '@fin/types';
import { formatDate } from '@fin/utils';
import { dayjs } from '@fin/utils/src/dayjsInstance';

import { useBusinesses, useKYCList } from '@app/hooks';
import { routes } from '@app/routing/routes';

type StatusOption = { label: string; value: CompanyStatus | null };

const statusOptions: StatusOption[] = [
  { label: 'All', value: null },
  { label: companyStatusLabels[CompanyStatus.ACTIVE], value: CompanyStatus.ACTIVE },
  { label: companyStatusLabels[CompanyStatus.INACTIVE], value: CompanyStatus.INACTIVE },
  { label: companyStatusLabels[CompanyStatus.DISABLED], value: CompanyStatus.DISABLED },
];

type KYCStatusOption = { label: string; value: KYCBusinessStatus | null };
const kycStatusOptions: KYCStatusOption[] = [
  { label: 'All', value: null },
  { label: KYCStatusLabels[KYCBusinessStatus.APPROVED], value: KYCBusinessStatus.APPROVED },
  { label: KYCStatusLabels[KYCBusinessStatus.NOT_COMPLETED], value: KYCBusinessStatus.NOT_COMPLETED },
  { label: KYCStatusLabels[KYCBusinessStatus.IN_PROGRESS], value: KYCBusinessStatus.IN_PROGRESS },
  { label: KYCStatusLabels[KYCBusinessStatus.UNDER_VERIFICATION], value: KYCBusinessStatus.UNDER_VERIFICATION },
  { label: KYCStatusLabels[KYCBusinessStatus.WAITLISTED], value: KYCBusinessStatus.WAITLISTED },
];

const searchParams = {
  search: parseAsString.withDefault(''),
  kycStatus: parseAsStringEnum(Object.values(KYCBusinessStatus)),
  status: parseAsStringEnum(Object.values(CompanyStatus)),
};

export const DemoToolsCompanies = () => {
  const navigate = useNavigate();
  const { businesses, businessesIsLoading } = useBusinesses();
  const { kycList, kycListIsLoading } = useKYCList();

  const { filters, handleFilterChange, isExactMatch, isMatchingString } = useSearchFilters(searchParams);

  const getKyc = useCallback(
    (businessId: CompanyInfoType['id']) => {
      return kycList?.find((kyc) => kyc.businessId === businessId);
    },
    [kycList],
  );

  const hasSomeFilter = !!Object.values(filters).filter(Boolean).length;

  const filteredBusinesses = useMemo(() => {
    if (!businesses) return [];

    return businesses.filter((business) => {
      const kyc = getKyc(business.id);

      return (
        isExactMatch('status', business.status) &&
        isExactMatch('kycStatus', kyc?.kycStatus) &&
        isMatchingString('search', business.companyName)
      );
    });
  }, [businesses, getKyc, isExactMatch, isMatchingString]);

  const { sortedEntities, changeSortBy, sortBy } = useEntitiesSort(filteredBusinesses, 'creationDate', {});

  const renderContent = () => (
    <Table
      templateColumns="minmax(max-content, 1fr) 180px 180px 230px"
      keyProp="id"
      entities={sortedEntities}
      sortBy={sortBy}
      onChangeSort={changeSortBy}
      rowProps={(item) => ({
        _hover: { '> .cell': { bg: 'bg.100', cursor: 'pointer' } },
        onClick: () => {
          const kyc = getKyc(item.id);

          if (!kyc) return;
          navigate(generatePath(routes.demoToolsForBusiness, { businessId: item.id, kycId: kyc.id }));
        },
      })}
      loading={businessesIsLoading || kycListIsLoading}
      columns={[
        {
          title: 'Company Name',
          field: 'companyName',
          renderCell: (item) => {
            const kyc = getKyc(item.id);

            return <Text variant="primary">{item.companyName || kyc?.companyName || ''}</Text>;
          },
        },
        {
          title: 'Created at',
          field: 'creationDate',
          renderCell: (item) => <Text variant="primary">{formatDate(dayjs(item.creationDate))}</Text>,
        },
        {
          title: 'Status',
          renderCell: (item) => {
            return (
              <Badge variant="status" colorScheme={companyStatusBadgeMapping[item.status].color}>
                <Icon name={companyStatusBadgeMapping[item.status].icon} size={18} />
                {companyStatusLabels[item.status]}
              </Badge>
            );
          },
        },
        {
          title: 'KYC Status',
          renderCell: (item) => {
            const kyc = getKyc(item.id);

            if (!kyc) return null;

            return (
              kyc.kycStatus && (
                <Badge variant="status" colorScheme={KYCStatusBadgeMapping[kyc.kycStatus].color}>
                  <Icon name={KYCStatusBadgeMapping[kyc.kycStatus].icon} size={18} />
                  {KYCStatusBadgeMapping[kyc.kycStatus].label}
                </Badge>
              )
            );
          },
        },
      ]}
      emptyText={hasSomeFilter ? 'There are no businesses matching your search criteria' : 'There are no businesses'}
    />
  );

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={4}
        px={{ base: 4, lg: 6 }}
        bg="white"
        borderBottom="1px"
        borderBottomColor="borderStroke"
      >
        <Heading>Dev&Demo Tools</Heading>
      </Flex>
      <Box py={4} px={{ base: 4, lg: 6 }}>
        <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems="center" gap={2} mb={3}>
          <SearchBar
            value={filters.search || ''}
            onChange={handleFilterChange('search')}
            placeHolder="Search"
            hideOnMobile={false}
            w="full"
          />
          <Box minW={{ base: 'full', md: '270px' }} flex={0}>
            <Select<StatusOption>
              size="sm"
              options={statusOptions}
              isEmptyAllowed
              value={filters.status}
              onChange={handleFilterChange('status')}
              labelInside="Status:"
              isClearable
            />
          </Box>
          <Box minW={{ base: 'full', md: '270px' }} flex={0}>
            <Select<KYCStatusOption>
              size="sm"
              options={kycStatusOptions}
              isEmptyAllowed
              value={filters.kycStatus}
              onChange={handleFilterChange('kycStatus')}
              labelInside="KYC Status:"
              isClearable
            />
          </Box>
        </Flex>
        {renderContent()}
      </Box>
    </>
  );
};
