import { type ReactElement } from 'react';

import {
  Box,
  Flex,
  type FlexProps,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Skeleton,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';

import { Icon } from '@fin/icons';

type SearchBarProps = Omit<FlexProps, 'onChange'> & {
  value: string;
  onChange: (v: string) => void;
  placeHolder?: string;
  addBtn?: ReactElement;
  hideOnMobile?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const SearchBar = (props: SearchBarProps) => {
  const { value, onChange, placeHolder, addBtn, hideOnMobile = true, isLoading, isDisabled, ...rest } = props;
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false });
  const breakpointValue = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    { ssr: false },
  );

  const isMobile = hideOnMobile && breakpointValue;

  const clearSearch = () => {
    onChange('');
    onToggle();
  };

  if (isMobile) {
    return (
      <Flex position="relative" w="full" gap={2} {...rest}>
        {isOpen || value.length > 0 ? (
          <InputGroup size="sm" w="full">
            <InputLeftElement>
              <Icon name="search" size={20} />
            </InputLeftElement>
            <Input
              disabled={isDisabled}
              placeholder={placeHolder}
              size="sm"
              value={value}
              name="search"
              onChange={(e) => onChange(e.target.value)}
              w="full"
              pl={isOpen ? '36px' : '20px'}
              autoFocus
            />
            <InputRightElement onClick={clearSearch}>
              <Icon name="close" size={20} color="contentSecondary" />
            </InputRightElement>
          </InputGroup>
        ) : (
          <>
            <Skeleton isLoaded={!isLoading}>
              <IconButton
                onClick={onToggle}
                aria-label="toggle search bar"
                size="sm"
                variant="secondary"
                borderColor="borderStroke"
                bg="white"
                color="contentPrimary"
                minW="36px"
                isDisabled={isDisabled}
              >
                <Icon name="search" size={20} />
              </IconButton>
            </Skeleton>
            {addBtn && (
              <Skeleton isLoaded={!isLoading} fitContent w="100%">
                <Box flex={1}>{addBtn}</Box>
              </Skeleton>
            )}
          </>
        )}
      </Flex>
    );
  }

  return (
    <Flex gap={2} {...rest}>
      <Skeleton isLoaded={!isLoading} fitContent w="100%">
        <InputGroup size="sm">
          <InputLeftElement pointerEvents="none">
            <Icon name="search" size={16} />
          </InputLeftElement>
          <Input
            isDisabled={isDisabled}
            placeholder={placeHolder}
            size="sm"
            value={value}
            name="search"
            onChange={(e) => onChange(e.target.value)}
          />
          {value.length > 0 && (
            <InputRightElement onClick={() => onChange('')}>
              <Icon name="close" size={16} color="contentSecondary" />
            </InputRightElement>
          )}
        </InputGroup>
      </Skeleton>
      {addBtn}
    </Flex>
  );
};
