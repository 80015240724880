import type { FunctionComponent } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { formatPrice } from '@fin/utils';

import type { InboundFundsSimulationTransaction } from '@app/types';

import { InboundFundsTransactionDetails } from '../TransactionDetails/InboundFundsTransactionDetails';

export const DepositTransactionItem = ({
  transaction,
  as,
}: {
  transaction: InboundFundsSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const { amount, currency, relatedEntity } = transaction;
  const hasEntity = relatedEntity && Object.keys(relatedEntity).length > 0;

  const TransactionItemComponent = as || TransactionItem;

  return (
    <TransactionItemComponent
      icon="transactionReceive"
      title={<Text variant="primary">{hasEntity ? `Received Funds from XXXXXXXX` : 'Funds Receipt'}</Text>}
      amount={
        <Flex alignItems="center" gap={1}>
          <Text size="inherit" variant="number" color="brandGreen.500">{`+${formatPrice(Math.abs(amount))}`}</Text>
          <Badge variant="textual">{currency}</Badge>
        </Flex>
      }
      onClick={() => NiceModal.show(InboundFundsTransactionDetails, { transaction })}
    />
  );
};
