import { useCallback, useEffect, useRef } from 'react';

import { type AxiosRequestConfig } from 'axios';
import useSWRInfinite, { type SWRInfiniteConfiguration } from 'swr/infinite';

const getKey = <T, K extends string>(
  pageIndex: number,
  previousPageData: PaginatedResponse<T, K> | null,
  url: string | null,
  reqParams: AxiosRequestConfig['params'],
  perPage: number,
  dataKey: K,
) => {
  if ((previousPageData && !previousPageData[dataKey]) || url === null) return null;

  if (pageIndex === 0) return { url, params: { limit: perPage, ...reqParams } };

  return { url, params: { limit: perPage, ...reqParams, cursor: previousPageData?.next } };
};

type UseInfiniteFetcherParams<K extends string> = {
  url: string | null;
  dataKey: K;
  reqParams?: AxiosRequestConfig['params'];
  perPage?: number;
  swrConfig?: SWRInfiniteConfiguration;
};

export type PaginatedResponse<T, K extends string> = {
  next: string;
  prev: string;
  limit: string;
} & Record<K, T>;

export const useCursorInfiniteFetcher = <T, K extends string>({
  url,
  reqParams = {},
  perPage = 10,
  swrConfig = {},
  dataKey,
}: UseInfiniteFetcherParams<K>) => {
  const loaderRef = useRef(null);

  const { data, isValidating, setSize, isLoading, ...rest } = useSWRInfinite<PaginatedResponse<T, K>>(
    (index, previousPageData) => getKey<T, K>(index, previousPageData, url, reqParams, perPage, dataKey),
    {
      revalidateFirstPage: false,
      revalidateOnMount: true,
      ...swrConfig,
    },
  );

  const flatData = data?.flatMap((page) => page[dataKey]);

  const isEmpty = Boolean(data && !isLoading && flatData?.length === 0 && !data?.at(-1)?.next);
  const hasMore = Boolean(data?.at(-1)?.next); //Boolean(total && loadedCount < total);

  const handleObserver: IntersectionObserverCallback = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore) {
        setSize((prev) => prev + 1);
      }
    },
    [setSize, hasMore],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '100px',
      threshold: 0,
    });
    const target = loaderRef.current;
    if (target && !isValidating) {
      observer.observe(target);
    }
    return () => {
      if (target) observer.unobserve(target);
    };
  }, [handleObserver, isValidating]);

  return { data: flatData, pagedData: data, isValidating, loaderRef, isEmpty, hasMore, setSize, isLoading, ...rest };
};
