import dayjs, { type ConfigType, type PluginFunc } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { Logger } from '@fin/shared';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);
dayjs.extend(localizedFormat);
dayjs.extend(isSameOrAfter);

type SupportedLocales = { [key: string]: () => Promise<ILocale> };
type SupportedLocalesWithDefault = SupportedLocales & { 'en-ca': () => Promise<ILocale> };

export const loadUserLocale = async (supportedLocales?: SupportedLocales) => {
  const locales: SupportedLocalesWithDefault = { ...supportedLocales, 'en-ca': () => import('dayjs/locale/en-ca') };
  const userLang = navigator?.language?.toLowerCase();

  const lang = locales?.[userLang] ? userLang : 'en-ca';
  const langLoader = locales[lang];

  try {
    const res = await langLoader();
    // @ts-expect-error
    dayjs.locale(res.default);
  } catch (error) {
    Logger.error(error);
  }
};

declare module 'dayjs' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Dayjs {
    differenceInCalendarMonths: (a: ConfigType) => number;
  }
}

const differenceInCalendarMonths: PluginFunc = (o, c, d) => {
  c.prototype.differenceInCalendarMonths = function (a) {
    const dA = d(a);

    const yearDiff = this.year() - dA.year();
    const monthDiff = this.month() - dA.month();

    return yearDiff * 12 + monthDiff;
  };
};
dayjs.extend(differenceInCalendarMonths);

const isDateValid = (date: string, format: string) => dayjs(date).format(format) === date;

export { dayjs, isDateValid };
