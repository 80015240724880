import { type IconNames } from '@fin/icons';
import type { Currency } from '@fin/shared';

import { type UserRoles } from './user';

export enum CompanyRegistrationType {
  FEDERAL = 'FEDERAL',
  PROVINCIAL = 'PROVINCIAL',
}

export const companyRegistrationTypeLabels: Record<CompanyRegistrationType, string> = {
  [CompanyRegistrationType.FEDERAL]: 'Federal',
  [CompanyRegistrationType.PROVINCIAL]: 'Provincial',
};

export enum CompanyStatus {
  'INACTIVE' = 'INACTIVE',
  'ACTIVE' = 'ACTIVE',
  'DISABLED' = 'DISABLED',
}

export const companyStatusLabels: Record<CompanyStatus, string> = {
  [CompanyStatus.INACTIVE]: 'Inactive',
  [CompanyStatus.ACTIVE]: 'Active',
  [CompanyStatus.DISABLED]: 'Disabled',
};

export const companyStatusBadgeMapping: Record<CompanyStatus, { color: string; icon: IconNames }> = {
  [CompanyStatus.INACTIVE]: { color: 'yellow', icon: 'eye' },
  [CompanyStatus.ACTIVE]: { color: 'gray', icon: 'check' },
  [CompanyStatus.DISABLED]: { color: 'red', icon: 'close' },
};

export enum BusinessApprovalStatus {
  approved = 'APPROVED',
  pending = 'PENDING',
  rejected = 'REJECTED',
  draft = 'DRAFT',
}

export type CompanyInfoType = {
  id: string;
  creationDate: string;
  approvalStatus: BusinessApprovalStatus;
  businessDescription: string | null;
  country: string;
  addressLine_1: string;
  addressLine_2: string;
  administrativeArea: string;
  city: string;
  postalCode: string;
  email: string;
  phone: string;
  companyNumber: string;
  registrationType: CompanyRegistrationType;
  administrativeAreaOfRegistration?: string | null;
  companyName: string;
  status: CompanyStatus;
  defaultCurrency: Currency;
};

export type BusinessMembership = {
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string | null;
  };
  business: {
    id: string;
    companyName: string;
  };
  roles: UserRoles[];
};

export type BusinessConfigPaymentMethod = { id: string; country: string; currency: Currency; method: string };

export type BusinessConfigRule = {
  id: string;
  businessId: string | null;
  country: string;
  description: string;
  name: string;
  type: 'default' | 'business';
  outsideGlobalLimits: boolean;
  dailyTransactionLimit: number;
  singleTransactionLimit: number;
  paymentMethods: BusinessConfigPaymentMethod[];
};

export type BusinessConfig = {
  id: string;
  businessId: string;
  ruleId: string;
  enabled: boolean;
  rule: BusinessConfigRule;
  effectiveConfiguration: {
    dailyTransactionLimit: number;
    singleTransactionLimit: number;
  };
  business: CompanyInfoType;
};
