import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Text: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 400,
  },
  sizes: {
    md: {
      fontSize: '16px',
    },
    sm: {
      fontSize: '14px',
    },
    xs: {
      fontSize: '13px',
    },
    '2xs': {
      fontSize: '11px',
    },
  },
  variants: {
    primary: {
      color: 'contentPrimary',
    },
    secondary: {
      color: 'contentSecondary',
    },
    tertiary: {
      color: 'contentTertiary',
    },
    number: {
      fontFamily: "'Hanken Grotesk', sans-serif",
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'secondary',
  },
};
