import { createRef } from 'react';

import { type UseToastOptions } from '@chakra-ui/react';

export type NotificationProps = Pick<
  UseToastOptions,
  'title' | 'description' | 'status' | 'isClosable' | 'id' | 'duration'
> & {
  actionTitle?: string;
  action?: () => void;
};

export const NotificationsRef = createRef<{
  show: (notification: NotificationProps) => {
    close: () => void;
    update: (notification: NotificationProps) => void;
  };
}>();

export const showNotification = (notification: NotificationProps) => NotificationsRef.current!.show(notification);
