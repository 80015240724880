import type { Currency } from '@fin/shared';

import type { BeneficiaryType } from './beneficiary';

const enum TransferReasons {
  business_expenses = 'business_expenses',
  construction = 'construction',
  donation_charitable_contribution = 'donation_charitable_contribution',
  education_training = 'education_training',
  family_support = 'family_support',
  freight = 'freight',
  goods_purchased = 'goods_purchased',
  investment_capital = 'investment_capital',
  investment_proceeds = 'investment_proceeds',
  living_expenses = 'living_expenses',
  loan_credit_repayment = 'loan_credit_repayment',
  medical_services = 'medical_services',
  other_services = 'other_services',
  pension = 'pension',
  personal_remittance = 'personal_remittance',
  professional_business_services = 'professional_business_services',
  real_estate = 'real_estate',
  taxes = 'taxes',
  technical_services = 'technical_services',
  transfer_to_own_account = 'transfer_to_own_account',
  travel = 'travel',
  wages_salary = 'wages_salary',
}

export const enum TransferDirections {
  incoming = 'incoming',
  outgoing = 'outgoing',
}

export type Transfer = {
  id: string;
  direction: TransferDirections;
  description: string;
  beneficiaryId: BeneficiaryType['id'] | null;
  businessName: BeneficiaryType['nickname'];
  transferReason: TransferReasons;
  amount: number;
  currency: Currency;
  status: 'SETTLED' | 'PENDING' | 'FAILED';
  shortReference: string;
  creationDate: string;
};

export type TransferFormType = {
  beneficiaryId: BeneficiaryType['id'];
  amount: number;
  currency: Currency;
  transferReason: TransferReasons;
  description?: string;
};

export const transferReasonsLabels: Record<TransferReasons, string> = {
  [TransferReasons.business_expenses]: 'Business expenses',
  [TransferReasons.construction]: 'Construction',
  [TransferReasons.donation_charitable_contribution]: 'Donation / charitable contribution',
  [TransferReasons.education_training]: 'Education / training',
  [TransferReasons.family_support]: 'Family support',
  [TransferReasons.living_expenses]: 'Living expenses',
  [TransferReasons.medical_services]: 'Medical services',
  [TransferReasons.pension]: 'Pension',
  [TransferReasons.personal_remittance]: 'Personal remittance',
  [TransferReasons.freight]: 'Freight',
  [TransferReasons.goods_purchased]: 'Goods purchased',
  [TransferReasons.investment_capital]: 'Investment capital',
  [TransferReasons.investment_proceeds]: 'Investment proceeds',
  [TransferReasons.loan_credit_repayment]: 'Loan / credit repayment',
  [TransferReasons.professional_business_services]: 'Professional / Business Services',
  [TransferReasons.real_estate]: 'Real Estate',
  [TransferReasons.taxes]: 'Taxes',
  [TransferReasons.technical_services]: 'Technical Services',
  [TransferReasons.transfer_to_own_account]: 'Transfer to own account',
  [TransferReasons.travel]: 'Travel',
  [TransferReasons.wages_salary]: 'Wages / salary',
  [TransferReasons.other_services]: 'Other Services',
};
