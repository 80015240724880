import { theme as defaultTableTheme, defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { colors } from '../colors';

const primary = defineStyle((props) => ({
  ...defaultTableTheme.components.Button.variants?.solid(props),
  boxShadow: '0px 1px 2px 0px rgba(29, 29, 32, 0.05)',
  _hover: {
    bg: 'brand.400',
    _disabled: {
      bg: 'contentDisabled',
    },
  },
  _active: {
    bg: 'brand.600',
  },
  _disabled: {
    bg: 'contentDisabled',
    opacity: 1,
  },
}));

const secondary = defineStyle((props) => ({
  ...defaultTableTheme.components.Button.variants?.outline(props),
  boxShadow: '0px 1px 2px 0px rgba(29, 29, 32, 0.05)',
  bg: 'bg.0',
  borderColor: 'borderStroke',
  color: 'brand.500',
  _hover: {
    bg: 'brand.100',
    _disabled: {
      bg: 'bg.50',
    },
  },
  _disabled: {
    color: 'contentDisabled',
    borderColor: 'borderDisabled',
    bg: 'bg.50',
    opacity: 1,
  },
  _active: {
    borderColor: 'brand.600',
    color: 'brand.600',
    _disabled: {
      color: 'contentDisabled',
      bg: 'bg.50',
      borderColor: 'borderDisabled',
    },
  },
}));

const tertiary = defineStyle((props) => ({
  ...defaultTableTheme.components.Button.variants?.outline(props),
  borderColor: 'transparent',
  color: 'brand.500',
  _hover: {
    bg: 'brand.100',
  },
  _disabled: {
    color: 'contentDisabled',
    opacity: 1,
  },
  _active: {
    bg: 'brand.100',
    color: 'brand.400',
    _disabled: {
      color: 'contentDisabled',
      bg: 'transparent',
    },
  },
}));

const link = defineStyle((props) => ({
  ...defaultTableTheme.components.Button.variants?.link(props),
  _hover: {
    color: 'brand.400',
  },
  _disabled: {
    color: 'contentDisabled',
    opacity: 1,
  },
  _active: {
    color: 'brand.600',
    _disabled: {
      color: 'contentDisabled',
    },
  },
  '.chakra-button__icon': {
    ['rightIcon' in props ? 'ml' : 'mr']: 1,
  },
}));

const accent = defineStyle((props) => ({
  ...defaultTableTheme.components.Button.variants?.solid(props),
  boxShadow: '0px 1px 2px 0px rgba(29, 29, 32, 0.05)',
  bg: 'brandGreen.500',
  color: colors.white,
  _hover: {
    bg: 'brandGreen.400',
    _disabled: {
      bg: 'contentDisabled',
    },
  },
  _active: {
    bg: 'brandGreen.600',
  },
  _disabled: {
    bg: 'contentDisabled',
    opacity: 1,
  },
}));

const negative = defineStyle((props) => ({
  ...defaultTableTheme.components.Button.variants?.solid(props),
  boxShadow: '0px 1px 2px 0px rgba(29, 29, 32, 0.05)',
  bg: 'brandRed.500',
  color: colors.white,
  _hover: {
    bg: 'brandRed.400',
    _disabled: {
      bg: 'contentDisabled',
    },
  },
  _active: {
    bg: 'brandRed.600',
  },
  _disabled: {
    bg: 'contentDisabled',
    opacity: 1,
  },
}));

const hoverStyle = {
  background: `linear-gradient(90deg, ${colors.content50}, ${colors.contentDisabled})`,
  backgroundSize: '200% 100%',
  color: colors.contentSecondary,
  animation: 'bg-position-animation 2.5s ease-in-out infinite',
  cursor: 'not-allowed',
};

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: 4,
    fontWeight: 500,
    _loading: {
      ...hoverStyle,
      '& > .chakra-button__spinner': { display: 'none !important' },
      cursor: 'not-allowed',
      _hover: {
        ...hoverStyle,
      },
    },
  },
  sizes: {
    md: { height: '48px', fontSize: '14px', px: 6 },
    sm: { height: '36px', px: 4 },
    xs: { height: '28px', p: 2, minW: '28px' },
  },
  variants: { primary, secondary, tertiary, link, accent, negative },
  defaultProps: { colorScheme: 'brand', variant: 'primary' },
});
