import { useCallback } from 'react';

import { useQueryStates, type UseQueryStatesKeysMap } from 'nuqs';

export const useSearchFilters = <T extends UseQueryStatesKeysMap>(searchParams: T) => {
  const [filters, setFilters] = useQueryStates(searchParams);

  const handleFilterChange = useCallback(
    <K extends keyof T>(key: K) =>
      (value: ReturnType<T[K]['parse']> | null) =>
        setFilters((prevFilters) => ({ ...prevFilters, [key]: value })),
    [setFilters],
  );

  const clearAllFilters = useCallback(() => setFilters(null), [setFilters]);

  const isMatchingString = useCallback(
    <K extends keyof T>(key: K, str: ReturnType<T[K]['parse']>): boolean =>
      !filters[key] || !!str?.toLowerCase().includes(filters[key].trim().toLowerCase()),
    [filters],
  );

  const isExactMatch = useCallback(
    <K extends keyof T>(key: K, value: ReturnType<T[K]['parse']> | undefined): boolean =>
      filters[key] === null || filters[key] === value,
    [filters],
  );

  return {
    filters,
    setFilters,
    handleFilterChange,
    clearAllFilters,
    isMatchingString,
    isExactMatch,
  };
};
