import { Box, Button, Flex, Heading } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';
import { useParams } from 'react-router-dom';

import { DateRangePicker, TransactionsTable, useSearchDateFilter } from '@fin/components';
import { Icon } from '@fin/icons';

import { Transaction } from '@app/components';
import { useBusinessTransactionsHistory } from '@app/hooks';

import { SimulateNewTransaction } from './SimulateNewTransaction';

export const DemoToolTransactions = () => {
  const params = useParams<{ kycId: string; businessId: string }>();

  const { searchDate, handleSearchDateChange, apiDateFilters } = useSearchDateFilter();

  const {
    transactionsByDate,
    loaderRef,
    isEmpty,
    hasMore,
    isValidating,
    isLoading,
    mutateBusinessTransactionsHistory,
  } = useBusinessTransactionsHistory(params?.businessId, {
    transactionDate: {
      gte: apiDateFilters.start,
      lte: apiDateFilters.end,
    },
  });

  if (!params?.businessId) return null;

  return (
    <>
      <Heading mb={4}>Transactions</Heading>
      <Flex gap={2} mb={3}>
        <DateRangePicker
          value={searchDate}
          onSelectDate={handleSearchDateChange}
          size="sm"
          clearable
          disableFutureDates
        />
        <Box flex={1} />
        <Button
          variant="secondary"
          size="sm"
          leftIcon={<Icon name="refresh" size={18} />}
          onClick={() => mutateBusinessTransactionsHistory()}
          isLoading={!isLoading && isValidating}
          loadingText="Refreshing..."
        >
          Refresh
        </Button>
        <Button
          size="sm"
          leftIcon={<Icon name="add" size={18} />}
          onClick={() =>
            NiceModal.show(SimulateNewTransaction, {
              businessId: params?.businessId,
              mutateTransactions: mutateBusinessTransactionsHistory,
            })
          }
        >
          Simulate New Transaction
        </Button>
      </Flex>

      <TransactionsTable
        transactionsByDate={transactionsByDate}
        loaderRef={loaderRef}
        isValidating={isValidating}
        hasMore={hasMore}
        isEmpty={isEmpty}
        isLoading={isLoading}
        flex={1}
      >
        {(transaction) => <Transaction transaction={transaction} isLoading={isLoading} />}
      </TransactionsTable>
    </>
  );
};
