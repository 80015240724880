/// <reference types="vite-plugin-svgr/client" />
import { Box, type BoxProps } from '@chakra-ui/react';

import LogoSmallIcon from './logo-small.svg?react';
import LogoIcon from './logo.svg?react';

type LogoProps = BoxProps & {
  height?: number;
  type?: 'big' | 'small';
};

export const Logo = ({ height = 20, type = 'big', ...rest }: LogoProps) => {
  return <Box as={type === 'big' ? LogoIcon : LogoSmallIcon} height={`${height}px`} {...rest} />;
};
