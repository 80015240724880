import type {
  BeneficiaryType,
  CardTransaction,
  Conversion,
  DirectDeposit,
  FundsReceipt,
  Payment,
  Transfer,
  Withdrawal,
} from '@fin/types';
import type { Currency } from '@fin/utils';

type SimulationTransaction = {
  id: string;
  sourceId: string;
  action: string;
  amount: number;
  net: number;
  fee: number;
  completedAt: string;
  createdAt: string;
  currency: Currency;
  settlesAt: string | null;
  estimatedSettledAt: string;
  clientRate: string | null;
  currencyPair: string | null;
  updatedAt: string;
  reason: string;
  status: 'PENDING' | 'SETTLED' | 'CANCELLED';
  description: string;
};

export enum SimulationTransactionSourceType {
  CONVERSION = 'CONVERSION',
  PAYMENT = 'PAYMENT',
  PAYOUT = 'PAYOUT',
  DEPOSIT = 'DEPOSIT',
  FUNDS_RECEIPT = 'FUNDS_RECEIPT',
  WITHDRAWAL = 'WITHDRAWAL',
  DIRECT_DEBIT_DEPOSIT = 'DIRECT_DEBIT_DEPOSIT',
  CARD_PURCHASE = 'CARD_PURCHASE',
  CARD_REFUND = 'CARD_REFUND',
  TRANSFER = 'TRANSFER',
  FEE = 'FEE',
}

export type ConversionSimulationTransaction = SimulationTransaction & {
  relatedEntity: Conversion;
  sourceType: SimulationTransactionSourceType.CONVERSION;
};

export type PaymentSimulationTransaction = SimulationTransaction & {
  relatedEntity: Payment & {
    beneficiary: BeneficiaryType;
  };
  sourceType: SimulationTransactionSourceType.PAYMENT;
};

export type PayoutSimulationTransaction = SimulationTransaction & {
  sourceType: SimulationTransactionSourceType.PAYOUT;
  relatedEntity: unknown;
};

export type InboundFundsSimulationTransaction = SimulationTransaction & {
  relatedEntity: Record<string, unknown>;
  sourceType: SimulationTransactionSourceType.DEPOSIT;
};

export type DDDSimulationTransaction = SimulationTransaction & {
  relatedEntity: DirectDeposit;
  sourceType: SimulationTransactionSourceType.DIRECT_DEBIT_DEPOSIT;
  transactionType: 'DEPOSIT' | 'DEPOSIT_REVERSAL';
};

export type FundsReceiptSimulationTransaction = SimulationTransaction & {
  relatedEntity: FundsReceipt;
  sourceType: SimulationTransactionSourceType.FUNDS_RECEIPT;
};

export type WithdrawalSimulationTransaction = SimulationTransaction & {
  relatedEntity: Withdrawal;
  sourceType: SimulationTransactionSourceType.WITHDRAWAL;
  transactionType: 'PAYOUT_REVERSAL' | 'PAYOUT';
};

export type CreditCardSimulationTransaction = SimulationTransaction & {
  relatedEntity: CardTransaction;
  sourceType: SimulationTransactionSourceType.CARD_PURCHASE | SimulationTransactionSourceType.CARD_REFUND;
};

export type TransferSimulationTransaction = SimulationTransaction & {
  relatedEntity: Transfer;
  sourceType: SimulationTransactionSourceType.TRANSFER;
  transactionType: 'DC_DEBIT' | 'DC_CREDIT';
};

export type FeeSimulationTransaction = SimulationTransaction & {
  relatedEntity: unknown;
  sourceType: SimulationTransactionSourceType.FEE;
  transactionType: 'FEE';
};

export type SimulationTransactionUnionType =
  | ConversionSimulationTransaction
  | PaymentSimulationTransaction
  | PayoutSimulationTransaction
  | InboundFundsSimulationTransaction
  | DDDSimulationTransaction
  | FundsReceiptSimulationTransaction
  | WithdrawalSimulationTransaction
  | CreditCardSimulationTransaction
  | TransferSimulationTransaction
  | FeeSimulationTransaction;
