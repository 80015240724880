import { type ReactNode, useMemo } from 'react';

import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';

import { Icon, type IconNames } from '@fin/icons';
import { createSkeletonProps, getRandomPercentage } from '@fin/utils';

export type TransactionItemProps =
  | {
      icon: IconNames;
      title: ReactNode;
      amount: ReactNode;
      shortRef?: string;
      iconColor?: string;
      onClick?: () => void;
      isLoading?: never;
    }
  | {
      isLoading: true;
      icon?: never;
      title?: never;
      amount?: never;
      shortRef?: never;
      iconColor?: never;
      onClick?: never;
    };

export const TransactionItem = ({
  icon,
  title,
  amount,
  iconColor = 'brandGreen.500',
  onClick,
  isLoading,
  shortRef,
}: TransactionItemProps) => {
  const getProps = createSkeletonProps(!!isLoading);
  const [w1, w2] = useMemo(() => [`${getRandomPercentage()}%`, `${getRandomPercentage()}%`], []);

  return (
    <Box
      display="grid"
      gridTemplateColumns="subgrid"
      gridColumn="1/-1"
      className="row"
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'default'}
      _hover={onClick ? { '> .cell': { bg: 'bg.100', cursor: 'pointer' } } : {}}
    >
      <Flex
        alignItems="center"
        gap={2}
        className="cell"
        p={2}
        borderRightWidth={isLoading ? '0 !important' : { base: '0 !important', md: '1px !important' }}
      >
        <Skeleton {...getProps({ rounded: '50%' })}>
          <Icon name={isLoading ? 'account' : icon} size={32} color={iconColor} />
        </Skeleton>
        <Skeleton
          {...getProps({ h: 4, w: w1 })}
          display="flex"
          flexDirection={{ base: 'column', lg: 'row' }}
          alignItems={{ base: 'flex-start', lg: 'center' }}
          flex={1}
          gap={{ base: 0, md: 2 }}
        >
          {title}
          {shortRef && (
            <Text fontWeight={500} ml={{ base: 0, lg: 'auto' }} size="sm" display={{ base: 'none', md: 'block' }}>
              {shortRef}
            </Text>
          )}
          <Text display={{ base: 'block', md: 'none' }} as="div" mt={1} size="sm">
            {amount}
          </Text>
        </Skeleton>
      </Flex>
      <Flex className="cell" alignItems="center" p={2} display={{ base: 'none', md: 'flex' }}>
        <Skeleton {...getProps({ height: 4, w: w2 })}>{amount}</Skeleton>
      </Flex>
    </Box>
  );
};
