import React, { type JSX } from 'react';

import { Box, Flex, type FlexProps, IconButton } from '@chakra-ui/react';

import { Link } from 'react-router-dom';

import { Logo } from '@fin/components';
import { Icon } from '@fin/icons';
import { AppEnv } from '@fin/shared';
import { getAppEnv } from '@fin/utils';

import { AdminLogo } from '@app/icons';
import { routes } from '@app/routing/routes';

import { NavItem } from './NavItem';
import { ProfileAction } from './ProfileAction';

type SidebarProps = FlexProps & {
  closeBtn?: JSX.Element;
  isExpanded: boolean;
  toggle?: () => void;
  closeMobile?: () => void;
};

const appEnv = getAppEnv();

export const Sidebar = (props: SidebarProps) => {
  const { toggle, isExpanded, closeBtn, closeMobile, ...rest } = props;

  return (
    <Flex
      bg="white"
      w={{ base: 'full', md: isExpanded ? 60 : 12 }}
      flex="none"
      pos="sticky"
      h="full"
      top={0}
      left={0}
      bottom={0}
      direction="column"
      zIndex={1}
      boxSizing="content-box"
      {...rest}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        direction={isExpanded ? 'row' : 'column'}
        borderBottom="1px"
        borderBottomColor="borderStroke"
      >
        <Flex
          p={4}
          as={Link}
          to={routes.home}
          w="full"
          borderBottom={isExpanded ? 0 : '1px'}
          borderBottomColor="borderStroke"
          justifyContent={isExpanded ? 'flex-start' : 'center'}
          onClick={closeMobile}
        >
          {isExpanded ? <AdminLogo width={76} height={34} /> : <Logo color="brandGreen.500" type="small" />}
        </Flex>
        <Flex minH="48px" alignItems="center">
          {closeBtn || (
            <IconButton
              aria-label={`${isExpanded ? 'Close' : 'Open'} sidebar`}
              onClick={toggle}
              size="sm"
              variant="ghost"
              mr={isExpanded ? 2 : 0}
            >
              <Icon name={isExpanded ? 'sidebar-collapse' : 'sidebar-expand'} color="contentSecondary" size={18} />
            </IconButton>
          )}
        </Flex>
      </Flex>
      <Flex flex={1} direction="column" alignItems={isExpanded ? 'flex-start' : 'center'} py={2} gap={1}>
        <NavItem isOpen={isExpanded} title="Dashboard" icon="dashboard" link={routes.home} />
        <NavItem isOpen={isExpanded} title="KYC" icon="verification" link={routes.kycList} />
        <NavItem isOpen={isExpanded} title="Impersonation" icon="impersonation" link={routes.impersonation} />
        <NavItem isOpen={isExpanded} title="Config" icon="config" link={routes.config} />
        <Box flex={1} />
        {appEnv !== AppEnv.prod && (
          <NavItem isOpen={isExpanded} title="Dev&Demo Tools" icon="tools" link={routes.demoTools} />
        )}
      </Flex>
      <ProfileAction isExpanded={isExpanded} />
    </Flex>
  );
};
