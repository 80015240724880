import { Fragment, type ReactNode } from 'react';

import { Box, Flex, Skeleton } from '@chakra-ui/react';

const DEFAULT_ROWS = 11;

export const LoadingState = <T,>({ children }: { children: (tr: T) => ReactNode }) => {
  return (
    <>
      <Flex alignItems="center" gridColumn="1/-1" minH="37px" px={2} mb="2px">
        <Skeleton height="18px" width="100px" />
      </Flex>
      {Array.from({ length: DEFAULT_ROWS }, (_, index) => (
        <Fragment key={`dummy-row--${index}`}>
          {children({} as T)}
          {index < DEFAULT_ROWS - 1}
        </Fragment>
      ))}
      <Box w="100%" h="100%" pos="absolute" bgGradient="linear(to-b, transparent, white)" />
    </>
  );
};
