import React, { useState } from 'react';

import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Select } from '@fin/components';
import { Flag, Icon } from '@fin/icons';
import {
  type CardTransaction,
  CardTransactionStatus,
  cardTransactionStatusBadgeMapping,
  cardTransactionStatusLabels,
  cardTransactionTypeLabels,
} from '@fin/types';
import { formatPrice, formatTransactionDate, handleApiError, modalProps, showNotification } from '@fin/utils';

import { SimulationService } from '@app/services/simulation';

const enum CardTransactionStatuses {
  captured = 'captured',
}

export const CardTransactionDetails = NiceModal.create<{ transaction: CardTransaction }>(({ transaction }) => {
  const modal = useModal();

  const [status, setStatus] = useState<null | CardTransactionStatuses>(CardTransactionStatuses.captured);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const simulate = async () => {
    try {
      setIsSubmitting(true);
      await SimulationService.captureCardTransaction(
        transaction.businessId,
        transaction.id,
        transaction.merchant?.name,
      );
      modal.hide();

      showNotification({
        title: 'Success',
        description: 'Card transaction status changed to captured',
        status: 'success',
      });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Drawer {...modalProps(modal)} placement="right" size="md">
      <DrawerOverlay />

      <DrawerContent>
        <Flex py={{ base: 3, md: 4 }} px={4} alignItems="center" borderBottom="1px" borderBottomColor="borderStroke">
          <Heading mr="auto">Card Transaction</Heading>
          <IconButton aria-label="Close Details" size="sm" variant="ghost" onClick={modal.hide} color="contentTertiary">
            <Icon name="close" size={24} />
          </IconButton>
        </Flex>
        {transaction.status === CardTransactionStatus.PENDING && (
          <Box p={4}>
            <Box p={3} bg="bg.100" rounded={4} border="1px" borderColor="borderStroke">
              <FormControl mb={3}>
                <FormLabel>Status</FormLabel>
                <Select
                  options={[{ label: 'Captured', value: CardTransactionStatuses.captured }]}
                  value={status}
                  onChange={setStatus}
                />
              </FormControl>
              <Button
                w="full"
                isDisabled={!status}
                onClick={simulate}
                isLoading={isSubmitting}
                loadingText="Simulating..."
              >
                Simulate Status Transition
              </Button>
            </Box>
          </Box>
        )}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacingY={{ base: 4, md: 6 }} spacingX={2} p={4} overflow="auto">
          <Box>
            <Text size="xs" mb={2}>
              Type
            </Text>
            <Text variant="primary" fontWeight={500}>
              {cardTransactionTypeLabels[transaction.transactionType]}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={1}>
              Status
            </Text>
            <Badge variant="status" colorScheme={cardTransactionStatusBadgeMapping[transaction.status].color}>
              <Icon name={cardTransactionStatusBadgeMapping[transaction.status].icon} size={18} />
              {cardTransactionStatusLabels[transaction.status]}
            </Badge>
          </Box>
          <Box gridColumn="1/-1">
            <Text size="xs" mb={2}>
              Transaction Amount
            </Text>
            <Text variant="number" fontWeight={500}>{`${formatPrice(transaction.transactionAmount)} ${
              transaction.transactionCurrency
            }`}</Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Billed Amount
            </Text>
            <Text variant="number" fontWeight={500}>{`${formatPrice(transaction.billingAmount)} ${
              transaction.billingCurrency
            }`}</Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Billed Account
            </Text>
            <Flex alignItems="center" gap={2} mr="auto">
              <Flag currency={transaction.billingCurrency} width={28} />
              <Text variant="primary" fontWeight={500}>
                {transaction.billingCurrency} Account
              </Text>
            </Flex>
          </Box>
          <Box gridColumn="1/-1">
            <Text size="xs" mb={2}>
              Date
            </Text>
            <Text variant="primary" fontWeight={500}>
              {formatTransactionDate(transaction.transactionDate)}
            </Text>
          </Box>
          <Flex gridColumn="1/-1" alignItems="center" justifyContent="space-between">
            <Box>
              <Text size="xs" mb={2}>
                Card
              </Text>

              <Flex gap={1} alignItems="center">
                <Text variant="primary" fontWeight={500}>
                  {`${transaction.card.nickname} (•••• ${transaction.card.lastFourDigits})`}
                </Text>
                <Text>{}</Text>
              </Flex>
            </Box>
          </Flex>
          <Box gridColumn="1/-1">
            <Text size="xs" mb={2}>
              Cardholder
            </Text>
            <Text variant="primary" fontWeight={500}>
              {`${transaction.cardholder.firstName} ${transaction.cardholder.lastName}`}
            </Text>
          </Box>
          <Box gridColumn="1/-1">
            <Text size="xs" mb={2}>
              Merchant
            </Text>
            <Text variant="primary" fontWeight={500}>
              {transaction.merchant?.name}
            </Text>
          </Box>
        </SimpleGrid>
      </DrawerContent>
    </Drawer>
  );
});
