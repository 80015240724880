import React, { useImperativeHandle } from 'react';

import {
  type AlertStatus,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Text,
  type ToastId,
  useToast,
  type UseToastOptions,
} from '@chakra-ui/react';

import { Icon } from '@fin/icons';
import { type NotificationProps, NotificationsRef } from '@fin/utils';

const colorStatusMapping: Record<AlertStatus, string> = {
  info: 'brand.500',
  error: 'brandRed.500',
  success: 'brandGreen.500',
  warning: 'brandYellow.500',
  loading: 'brand.500',
};

const NotificationComponent = (props: NotificationProps & { close: (id: ToastId) => void }) => {
  return (
    <Flex alignItems="center" bg="white" shadow="xl">
      <Box w={1.5} bg={colorStatusMapping[props.status ?? 'info']} alignSelf="stretch" mr={2} />
      <Box p={3}>
        <Heading size="md" mb={2}>
          {props.title}
        </Heading>
        <Text>{props.description}</Text>
      </Box>
      {props.actionTitle && (
        <Button
          flex="none"
          variant="link"
          mx={6}
          fontSize="16px"
          _hover={{ textDecoration: 'none' }}
          onClick={() => {
            props.id && props.close(props.id);
            props.action?.();
          }}
        >
          {props.actionTitle}
        </Button>
      )}
      {props.isClosable && (
        <IconButton
          aria-label="Close Notification"
          size="sm"
          variant="ghost"
          onClick={() => props.id && props.close(props.id)}
          ml="auto"
          mr={2}
          color="contentTertiary"
        >
          <Icon name="close" size={24} />
        </IconButton>
      )}
    </Flex>
  );
};

export const Notifications = () => {
  const toast = useToast();

  useImperativeHandle(
    NotificationsRef,
    () => ({
      show: (notification) => {
        const prepare = (notification: NotificationProps): UseToastOptions => ({
          isClosable: true,
          position: 'bottom-right',
          duration: notification.status === 'loading' ? 10000 : 2000,
          ...notification,
          render: (props) => (
            <NotificationComponent
              {...props}
              action={notification.action}
              actionTitle={notification.actionTitle}
              close={toast.close}
            />
          ),
        });

        const toastId = toast(prepare(notification));

        return {
          close: () => {
            toast.close(toastId);
          },
          update: (notification) => {
            if (toast.isActive(toastId)) {
              toast.update(toastId, prepare(notification));
            } else {
              toast(prepare(notification));
            }
          },
        };
      },
    }),
    [toast],
  );

  return null;
};
