import type { Industry, IndustryGroupOption } from '@fin/types';

export const industriesQuestionPrefix = 'Q__';

export const prepareIndustries = (categories: Industry[] | undefined) => {
  return categories?.map((industry) => ({
    label: industry.name,
    options: industry.values.map((i) =>
      i.type === 'CODE'
        ? { ...i, value: i.value, label: i.name, sector: industry.name }
        : {
            ...i,
            value: `${industriesQuestionPrefix}${i.values.map((q) => q.value).join('/')}`,
            label: i.name,
            sector: industry.name,
          },
    ),
  })) as IndustryGroupOption[] | undefined;
};

export const getIndustryInfo = (industries: IndustryGroupOption[] | undefined, value: string | null | undefined) => {
  if (!industries) return null;

  const options = industries.flatMap((i) => i.options);
  const selectedOption = options.find((i) =>
    i.type === 'CODE' ? i.value === value : i.values.some((j) => j.value === value),
  );
  if (!selectedOption) return null;

  return {
    sector: selectedOption.sector,
    industry: selectedOption.label,
    question: selectedOption.type === 'QUESTION' ? selectedOption.question : null,
    answer: selectedOption.type === 'QUESTION' ? selectedOption.values.find((i) => i.value === value)!.answer : null,
  };
};
