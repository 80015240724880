import React, { useMemo } from 'react';

import { Box, Flex, Skeleton, Spinner, Text } from '@chakra-ui/react';

import { Icon } from '@fin/icons';
import { getRandomPercentage } from '@fin/utils';

import { type LoadingAndEmptyStateProps, type LoadingContentProps, type SkeletonRowProps } from './types';

const DEFAULT_ROWS = 11;

const SkeletonRow = <T,>({ columns, rowIndex, percentages }: SkeletonRowProps<T>) => (
  <Box display="grid" gridTemplateColumns="subgrid" gridColumn="1/-1" className="row">
    {columns.map((column, colIndex) => (
      <Flex
        key={`${column.title || ''}_${rowIndex}_${colIndex}`}
        gap={2}
        whiteSpace="nowrap"
        alignItems="center"
        p={2}
        className="cell"
      >
        {column.icon && (
          <Skeleton fadeDuration={0}>
            <Icon name={column.icon} size={16} />
          </Skeleton>
        )}
        <Skeleton minW={`${percentages[colIndex]}%`} fadeDuration={0}>
          &nbsp;
        </Skeleton>
      </Flex>
    ))}
  </Box>
);

const LoadingContent = <T,>({ columns, numberOfSkeletonRows = DEFAULT_ROWS }: LoadingContentProps<T>) => {
  const randomPercentages = useMemo(() => {
    return Array.from({ length: columns.length }, getRandomPercentage);
  }, [columns.length]);

  return (
    <>
      {Array.from({ length: numberOfSkeletonRows }, (_, index) => (
        <SkeletonRow key={`skeleton-row_${index}`} columns={columns} rowIndex={index} percentages={randomPercentages} />
      ))}
      <Box w="100%" h="100%" pos="absolute" bgGradient="linear(to-b, transparent, white)" />
    </>
  );
};

export const LoadingAndEmptyState = <T,>({
  isEmpty,
  loading,
  emptyText,
  emptyElement,
  withSkeleton = false,
  numberOfSkeletonRows,
  columns,
}: LoadingAndEmptyStateProps<T>) => {
  if (!isEmpty) return null;

  if (loading && withSkeleton) {
    return <LoadingContent columns={columns} numberOfSkeletonRows={numberOfSkeletonRows} />;
  }

  const content = loading ? <Spinner color="brand.500" /> : emptyElement || <Text textAlign="center">{emptyText}</Text>;

  return (
    <Flex gridColumn="1/-1" alignItems="center" justifyContent="center" py={5} px={2} minH={16}>
      {content}
    </Flex>
  );
};
