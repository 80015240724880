import useSWR from 'swr';

import { type KYCFile } from '@fin/types';

export const useKYCDeclaration = (id: string | undefined) => {
  const { data, isLoading } = useSWR<{ kycFile: KYCFile }>(id ? `/admin/kyc/${id}/files/declaration` : null);

  return {
    kycDeclaration: data?.kycFile,
    kycDeclarationIsLoading: isLoading,
  };
};
