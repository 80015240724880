import type { FunctionComponent } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { formatPrice } from '@fin/utils';

import type { FeeSimulationTransaction } from '@app/types';

export const FeeTransactionItem = ({
  transaction,
  as,
}: {
  transaction: FeeSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const { amount, currency } = transaction;

  const TransactionItemComponent = as || TransactionItem;

  return (
    <TransactionItemComponent
      icon="transactionFXDelta"
      title={<Text variant="primary">Fee for Failed Payment</Text>}
      amount={
        <Flex alignItems="center" gap={1}>
          <Text size="inherit" variant="number" color="inherit">{`-${formatPrice(Math.abs(amount))}`}</Text>
          <Badge variant="currency">{currency}</Badge>
        </Flex>
      }
    />
  );
};
