import useSWR from 'swr';

import type { CompanyInfoType } from '@fin/types';

export const useBusinesses = () => {
  const { data, isLoading } = useSWR<{ businesses: CompanyInfoType[] }>('/admin/businesses');

  return {
    businesses: data?.businesses,
    businessesIsLoading: isLoading,
  };
};
