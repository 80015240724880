import React, { useState } from 'react';

import { Box, Flex, IconButton, SimpleGrid, Text } from '@chakra-ui/react';

import { Flag, Icon } from '@fin/icons';
import { formatPrice, formatTransactionDate } from '@fin/utils';

import type { ConversionSimulationTransaction } from '@app/types';

export const ConversionTransactionDetailsContent = ({
  transaction,
}: {
  transaction: ConversionSimulationTransaction;
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const conversion = transaction.relatedEntity;

  return (
    <SimpleGrid p={4} columns={{ base: 1, md: 2 }} spacingY={{ base: 4, md: 6 }} spacingX={2}>
      <Box>
        <Text size="xs" mb={2}>
          Source Account
        </Text>
        <Flex alignItems="center" gap={1}>
          <Flag currency={conversion.sellCurrency} width={28} />
          <Text variant="primary" fontWeight={500}>
            {conversion.sellCurrency} Account
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Source Amount
        </Text>
        <Text variant="primary" fontWeight={500}>
          {`${formatPrice(Math.abs(conversion.clientSellAmount))} ${conversion.sellCurrency}`}
        </Text>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Destination Account
        </Text>
        <Flex alignItems="center" gap={1}>
          <Flag currency={conversion.buyCurrency} width={28} />
          <Text variant="primary" fontWeight={500}>
            {conversion.buyCurrency} Account
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Destination Amount
        </Text>
        <Text variant="primary" fontWeight={500}>
          {`${formatPrice(Math.abs(conversion.clientBuyAmount))} ${conversion.buyCurrency}`}
        </Text>
      </Box>
      <Box gridColumn="1/-1">
        <Text size="xs" mb={2}>
          Exchange Rate
        </Text>
        <Flex alignItems="center" gap={1}>
          <Text variant="primary" fontWeight={500}>
            1 {isFlipped ? conversion?.buyCurrency : conversion?.sellCurrency} ={' '}
            {`${formatPrice(
              conversion
                ? Math.pow(
                    Math.pow(conversion.clientRate, conversion.baseCurrency === conversion.sellCurrency ? 1 : -1),
                    isFlipped ? -1 : 1,
                  )
                : 0,
              {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6,
              },
            )} ${(isFlipped ? conversion?.sellCurrency : conversion?.buyCurrency) ?? 'CAD'}`}
          </Text>
          <IconButton
            aria-label="Flip"
            onClick={() => setIsFlipped((v) => !v)}
            variant="ghost"
            color="brand.500"
            size="xs"
          >
            <Icon name="flip" size={16} />
          </IconButton>
        </Flex>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Date
        </Text>
        <Text variant="primary" fontWeight={500}>
          {formatTransactionDate(transaction.createdAt)}
        </Text>
      </Box>
    </SimpleGrid>
  );
};
