import { type ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '../colors';

export const Drawer: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      background: `${colors.brand['500']}28`,
    },
    footer: {
      px: 4,
    },
  },
};
