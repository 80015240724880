import useSWR from 'swr';

import type { BusinessMembership } from '@fin/types';

export const useMemberships = () => {
  const { data, isLoading } = useSWR<{ memberships: BusinessMembership[] }>('/admin/memberships');

  return {
    memberships: data?.memberships,
    membershipsIsLoading: isLoading,
  };
};
