import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 500,
    color: 'contentPrimary',
  },
  sizes: {
    xl: {
      fontSize: 24,
      lineHeight: 1.3,
    },
    lg: {
      fontSize: 21,
      lineHeight: 1.3,
    },
    md: {
      fontSize: 18,
      lineHeight: 1.2,
    },
    sm: {
      fontSize: 16,
      lineHeight: 1.3,
    },
    xs: {
      fontSize: 14,
      lineHeight: 1.2,
    },
  },
  defaultProps: {
    size: 'lg',
  },
};
