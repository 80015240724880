import React from 'react';

import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';

import { Flag } from '@fin/icons';
import { formatPrice, formatTransactionDate, getBankAccountTitle } from '@fin/utils';

import type { DDDSimulationTransaction } from '@app/types';

export const DDDTransactionDetailsContent = ({ transaction }: { transaction: DDDSimulationTransaction }) => {
  const isReversal = transaction.transactionType === 'DEPOSIT_REVERSAL';

  const renderDepositFields = () => (
    <>
      <Box>
        <Text size="xs" mb={2}>
          From Bank Account
        </Text>
        <Flex alignItems="center" gap={2} mb={2}>
          <Flag currency={transaction.currency} width={28} />
          <Text variant="primary" fontWeight={500}>
            {getBankAccountTitle(transaction.relatedEntity.directDebitBankAccount)}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          To Account
        </Text>
        <Flex alignItems="center" gap={2} mb={2}>
          <Flag currency={transaction.currency} width={28} />
          <Text variant="primary" fontWeight={500}>
            {`${transaction.currency} Account`}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Deposit Amount
        </Text>
        <Text variant="number" fontWeight={500}>
          {`${formatPrice(transaction.amount)} ${transaction.currency}`}
        </Text>
      </Box>
    </>
  );

  const renderReverseDepositFields = () => (
    <>
      <Box>
        <Text size="xs" mb={2}>
          From Account
        </Text>
        <Flex alignItems="center" gap={2} mb={2}>
          <Flag currency={transaction.currency} width={28} />
          <Text variant="primary" fontWeight={500}>
            {`${transaction.currency} Account`}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          To Bank Account
        </Text>
        <Flex alignItems="center" gap={2} mb={2}>
          <Flag currency={transaction.currency} width={28} />
          <Text variant="primary" fontWeight={500}>
            {getBankAccountTitle(transaction.relatedEntity.directDebitBankAccount)}
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Amount
        </Text>
        <Text variant="number" fontWeight={500}>
          {`${formatPrice(transaction.amount)} ${transaction.currency}`}
        </Text>
      </Box>
    </>
  );

  return (
    <SimpleGrid p={4} columns={{ base: 1, md: 2 }} spacingY={{ base: 4, md: 6 }} spacingX={2}>
      {isReversal ? renderReverseDepositFields() : renderDepositFields()}
      <Box gridColumn="1/-1">
        <Text size="xs" mb={2}>
          Date
        </Text>
        <Text variant="primary" fontWeight={500}>
          {formatTransactionDate(transaction.createdAt)}
        </Text>
      </Box>
    </SimpleGrid>
  );
};
