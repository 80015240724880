import useSWR from 'swr';

import { type KYCBusiness } from '@fin/types';

export const useKYCDetails = (id: string | undefined) => {
  const { data, isLoading, mutate } = useSWR<{ businessKyc: KYCBusiness }>(id ? `/admin/kyc/${id}` : null);

  return {
    kycDetails: data?.businessKyc,
    kycDetailsIsLoading: isLoading,
    mutateKYCDetails: mutate,
  };
};
