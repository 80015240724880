import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { type NumberFormatValues, PatternFormat } from 'react-number-format';
import { useNavigate, useParams } from 'react-router-dom';

import { PlaceAutocomplete, Select } from '@fin/components';
import {
  type AddressUnionFields,
  CompanyRegistrationType,
  CompanyStatus,
  type PostalCodeFieldSchema,
  type StateFieldSchema,
} from '@fin/types';
import { emailValidation, handleApiError, prettifyPostalCode, showNotification } from '@fin/utils';

import { useBusinessDetails, useKYCCountrySchema, useKYCDetails } from '@app/hooks';
import { type BusinessAddress, SimulationService, type SkipKYCForm } from '@app/services/simulation';

const emptyAddress: BusinessAddress = {
  addressLine_1: '',
  addressLine_2: '',
  administrativeArea: '',
  city: '',
  postalCode: '',
};

const DemoToolSkipKYCContent = () => {
  const params = useParams<{ kycId: string; businessId: string }>();
  const navigate = useNavigate();
  const { businessDetails, mutateBusinessDetails } = useBusinessDetails(params?.businessId);
  const { kycDetails, mutateKYCDetails } = useKYCDetails(params?.kycId);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useForm<SkipKYCForm>({
    defaultValues: {
      companyName: businessDetails?.companyName || kycDetails?.companyName,
      email: businessDetails?.email,
      country: businessDetails?.country || kycDetails?.companyCountry,
      addressLine_1: businessDetails?.addressLine_1 || kycDetails?.companyAddressLine_1,
      addressLine_2: businessDetails?.addressLine_2 || kycDetails?.companyAddressLine_2,
      city: businessDetails?.city || kycDetails?.companyCity,
      administrativeArea: businessDetails?.administrativeArea || kycDetails?.companyProvince || '',
      postalCode: businessDetails?.postalCode || kycDetails?.companyPostalCode || '',
      companyNumber:
        businessDetails?.companyNumber || kycDetails?.companyRegistrationNumber || kycDetails?.companyEin || '',
    },
  });

  const country = watch('country');

  const { KYCCountrySchema } = useKYCCountrySchema(country);

  const getFieldSchema = useCallback(
    (field: AddressUnionFields['key']) => {
      if (!KYCCountrySchema?.address) return undefined;

      return KYCCountrySchema.address.fields.find((i) => i.key === field && i.required);
    },
    [KYCCountrySchema],
  );

  const postalCodeSchema = getFieldSchema('postal_code') as PostalCodeFieldSchema;
  const stateSchema = getFieldSchema('administrative_area') as StateFieldSchema;

  useEffect(() => {
    if (KYCCountrySchema && !postalCodeSchema) setValue('postalCode', '');
  }, [KYCCountrySchema, postalCodeSchema, setValue]);

  useEffect(() => {
    if (KYCCountrySchema && !stateSchema) setValue('administrativeArea', '');
  }, [KYCCountrySchema, setValue, stateSchema]);

  const onSelectAddress = (address: BusinessAddress) => {
    Object.entries(address).forEach(([key, value]) => {
      setValue(key as keyof BusinessAddress, value, { shouldDirty: true, shouldValidate: true });
    });
  };

  const onSubmit: SubmitHandler<SkipKYCForm> = async (form) => {
    if (!params?.businessId || !businessDetails || !kycDetails) return;

    try {
      await SimulationService.updateBusiness(params.businessId, {
        ...form,
        registrationType: CompanyRegistrationType.PROVINCIAL,
        status: businessDetails.status,
        approvalStatus: businessDetails.approvalStatus,
        administrativeAreaOfRegistration: null,
        businessDescription: '',
      });

      await SimulationService.connectToAW(params.businessId);

      await mutateKYCDetails();
      await mutateBusinessDetails();

      showNotification({
        title: 'Success',
        description: 'Business updated and Account connected',
        status: 'success',
      });
      navigate(-1);
    } catch (e) {
      handleApiError(e);
    }
  };

  const renderAddressFields = () => (
    <>
      <PlaceAutocomplete
        key={country}
        label="Find Business Address"
        placeholder="Enter your business address to auto-fill"
        country={country}
        onSelectAddress={onSelectAddress}
        addressMapping={{
          addressLine_1: 'addressLine_1',
          addressLine_2: 'addressLine_2',
          city: 'city',
          state: 'administrativeArea',
          postalCode: 'postalCode',
        }}
        getFieldSchema={getFieldSchema}
      />
      <FormControl id="addressLine_1" isInvalid={!!errors?.addressLine_1?.message}>
        <FormLabel>Street Address</FormLabel>
        <Input
          type="text"
          placeholder="Enter street address"
          {...register('addressLine_1', {
            required: 'This is required',
          })}
        />
        <FormErrorMessage>{errors?.addressLine_1?.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="addressLine_2" isInvalid={!!errors?.addressLine_2?.message}>
        <FormLabel gap={1}>
          Suite Number <Text size="xs">(Optional)</Text>
        </FormLabel>
        <Input type="text" placeholder="Enter suite number" {...register('addressLine_2')} />
        <FormErrorMessage>{errors?.addressLine_2?.message}</FormErrorMessage>
      </FormControl>
      <FormControl id="city" isInvalid={!!errors?.city?.message}>
        <FormLabel>City</FormLabel>
        <Input
          type="text"
          placeholder="Enter city"
          {...register('city', {
            required: 'This is required',
          })}
        />
        <FormErrorMessage>{errors?.city?.message}</FormErrorMessage>
      </FormControl>
      <Flex gap={2}>
        {stateSchema && (
          <FormControl id="province" isInvalid={!!errors?.administrativeArea?.message}>
            <FormLabel>{stateSchema.label}</FormLabel>
            <Controller
              rules={{
                required: 'This is required',
                pattern: {
                  value: new RegExp(stateSchema.validation),
                  message: `Invalid ${stateSchema.label.toLowerCase()}`,
                },
              }}
              control={control}
              name="administrativeArea"
              render={({ field }) => (
                <Select<{ label: string; value: string }> options={stateSchema.options} {...field} />
              )}
            />
            <FormErrorMessage>{errors?.administrativeArea?.message}</FormErrorMessage>
          </FormControl>
        )}
        {postalCodeSchema && (
          <FormControl id="postalCode" isInvalid={!!errors?.postalCode?.message}>
            <FormLabel>{postalCodeSchema.label}</FormLabel>
            <Input
              type="text"
              placeholder={`Enter ${postalCodeSchema.label.toLowerCase()}`}
              {...register('postalCode', {
                required: 'This is required',
                pattern: {
                  value: new RegExp(postalCodeSchema.validation),
                  message: `Invalid ${postalCodeSchema.label.toLowerCase()}`,
                },
                onChange: () => {
                  if (errors?.postalCode?.message) trigger('postalCode');
                },
                onBlur: (e) => {
                  if (e.target.value.length) {
                    setValue('postalCode', prettifyPostalCode(e.target.value), { shouldValidate: true });
                  }
                },
              })}
            />
            <FormErrorMessage>{errors?.postalCode?.message}</FormErrorMessage>
          </FormControl>
        )}
      </Flex>
    </>
  );

  return (
    <Flex flexDirection="column" alignItems="center">
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        maxW="md"
        w="full"
        bg="white"
        rounded={4}
        p={{ base: 4, lg: 6 }}
        border="1px"
        borderColor="borderStroke"
      >
        <Text variant="primary" fontWeight={500} mb={2}>
          Skip KYC + Activate Business (Dev/Demo Only)
        </Text>
        <Text size="sm">
          Fill out the below form to skip kyc and activate business for dev & demo purposes. If you want to verify a
          real customer, go to KYC page instead.
        </Text>
        <Divider my={6} />
        <Flex flexDirection="column" gap={4}>
          <FormControl id="companyName" isInvalid={!!errors?.companyName?.message}>
            <FormLabel>Business Legal Name</FormLabel>
            <Input
              type="text"
              placeholder="Enter your business’ legal name"
              {...register('companyName', {
                required: 'This is required',
              })}
            />
            <FormErrorMessage>{errors?.companyName?.message}</FormErrorMessage>
          </FormControl>
          <Controller
            rules={{ required: 'This is required' }}
            control={control}
            name="country"
            render={({ field, fieldState }) => (
              <FormControl id="country" isInvalid={!!fieldState.error?.message}>
                <FormLabel>Country of Business Registration</FormLabel>
                <Select
                  placeholder="Select"
                  options={[
                    { label: 'Canada', value: 'CA' },
                    { label: 'USA', value: 'US' },
                  ]}
                  {...field}
                  onChange={(v) => {
                    field.onChange(v);
                    Object.entries(emptyAddress).forEach(([key, value]) => {
                      setValue(key as keyof BusinessAddress, value, { shouldDirty: true });
                    });
                    clearErrors(Object.keys(emptyAddress) as Array<keyof BusinessAddress>);
                  }}
                  isSearchable
                />
                <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />

          {country && renderAddressFields()}

          {country === 'CA' && (
            <FormControl id="companyRegistrationNumber" isInvalid={!!errors?.companyNumber?.message}>
              <FormLabel>Corporation Number or Registry ID</FormLabel>

              <Input
                type="text"
                placeholder="Enter your corporation number or registry ID"
                {...register('companyNumber', {
                  required: 'This is required',
                })}
              />
              <FormErrorMessage>{errors?.companyNumber?.message}</FormErrorMessage>
            </FormControl>
          )}
          {country === 'US' && (
            <Controller
              rules={{
                required: 'This is required',
                validate: (value) => {
                  if (value.length < 9) return 'Wrong value format';
                },
              }}
              control={control}
              name="companyNumber"
              render={({ field: { onChange, value, ref }, fieldState }) => (
                <FormControl id="companyNumber" isInvalid={fieldState.invalid}>
                  <FormLabel>Business EIN</FormLabel>

                  <Input
                    type="text"
                    placeholder="9 digits"
                    as={PatternFormat}
                    format="#########"
                    getInputRef={ref}
                    value={value}
                    onValueChange={(v: NumberFormatValues) => {
                      onChange(v.value);
                    }}
                  />
                  <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
          )}
          <FormControl id="email" isInvalid={!!errors?.email?.message}>
            <FormLabel>Email</FormLabel>

            <Input
              type="text"
              placeholder="Enter email"
              {...register('email', {
                required: 'This is required',
                pattern: emailValidation,
              })}
            />
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            mt={4}
            isLoading={isSubmitting}
            isDisabled={!country || businessDetails?.status === CompanyStatus.ACTIVE}
            loadingText="Loading..."
          >
            Activate Business for Dev&Demo
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export const DemoToolSkipKYC = () => {
  const params = useParams<{ kycId: string; businessId: string }>();
  const { businessDetails } = useBusinessDetails(params?.businessId);
  const { kycDetails } = useKYCDetails(params?.kycId);

  if (!businessDetails || !kycDetails) {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" h="100vh">
        <Spinner color="brand.500" size="xl" />
      </Flex>
    );
  }

  return <DemoToolSkipKYCContent />;
};
