import { type ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '../colors';

export const Input: ComponentStyleConfig = {
  variants: {
    whiteInput: {
      field: {
        fontSize: 14,
        borderRadius: '4px',
        bg: colors.white,
        border: '1px solid',
        borderColor: 'borderStroke',
        _placeholder: {
          color: 'contentSecondary',
        },
        _invalid: {
          borderColor: 'brandRed.500',
        },
        _hover: {
          borderColor: 'contentTertiary',
          _disabled: {
            bg: 'bg.200',
            borderColor: 'bg.200',
          },
        },
        _focus: {
          borderColor: 'brand.500',
        },
        _disabled: {
          bg: 'bg.200',
          borderColor: 'bg.200',
          color: 'contentSecondary',
          opacity: 1,
        },
      },
      addon: {
        borderRadius: '4px',
      },
    },
  },
  sizes: {
    lg: {
      group: {
        '--input-height': '50px',
      },
      field: {
        px: 4,
        minH: '50px',
        '--input-height': '50px',
      },
      addon: {
        minH: '50px',
      },
    },
    md: {
      group: {
        '--input-height': '44px',
      },
      field: {
        px: 4,
        minH: '44px',
        '--input-height': '44px',
      },
    },
    sm: {
      group: {
        '--input-height': '36px',
      },
      field: {
        px: 2,
        h: '36px',
        '--input-height': '36px',
        minH: '36px',
      },
    },
    xs: {
      group: {
        '--input-height': '28px',
      },
      field: {
        px: 2,
        h: '28px',
        '--input-height': '28px',
        minH: '28px',
      },
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'whiteInput',
  },
};
