import { useMemo } from 'react';

import { useCursorInfiniteFetcher } from '@fin/components';
import { Logger } from '@fin/shared';
import { type CompanyInfoType } from '@fin/types';
import { type Currency, formatDate } from '@fin/utils';

import { SimulationTransactionSourceType, type SimulationTransactionUnionType } from '@app/types';

type useBusinessTransactionsHistoryParams = {
  transactionDate?: { gte: string | null; lte: string | null };
  transactionCurrency?: Currency | null;
};

const removeCreditConversions = (list: SimulationTransactionUnionType[]) => {
  return list.filter(
    (transaction) =>
      !(
        transaction.sourceType === SimulationTransactionSourceType.CONVERSION &&
        transaction.amount < 0 &&
        Object.keys(transaction.relatedEntity).length > 0
      ),
  );
};

const groupByDate = (list: SimulationTransactionUnionType[]) =>
  list.reduce<Record<string, SimulationTransactionUnionType[]>>((acc, transaction) => {
    const date = formatDate(transaction.createdAt);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(transaction);
    return acc;
  }, {});

export const useBusinessTransactionsHistory = (
  businessId: CompanyInfoType['id'] | undefined,
  params: useBusinessTransactionsHistoryParams,
) => {
  const { data, isValidating, loaderRef, isEmpty, isLoading, hasMore, mutate } = useCursorInfiniteFetcher<
    SimulationTransactionUnionType,
    'transactions'
  >({
    url: businessId ? `/simulation/businesses/${businessId}/transactions/history` : null,
    perPage: 25,
    dataKey: 'transactions',
    reqParams: {
      ...params,
    },
  });

  const transactionsByDate = useMemo(() => {
    if (!data?.length) return {};

    const supportedTransactions = data.filter((t) => {
      const isSupported = t.sourceType in SimulationTransactionSourceType;
      if (!isSupported) {
        Logger.log(`Unsupported transaction sourceType found: ${t.sourceType}`, t);
      }
      return isSupported;
    });

    const transactionsWithoutConversions = removeCreditConversions(supportedTransactions);

    return groupByDate(transactionsWithoutConversions);
  }, [data]);

  return {
    transactionsByDate,
    mutateBusinessTransactionsHistory: mutate,
    isValidating,
    loaderRef,
    isEmpty,
    isLoading,
    hasMore,
  };
};
