import useSWR from 'swr';

import type { AddressFieldsSchema } from '@fin/types';

export const useKYCCountrySchema = (country: string | undefined) => {
  const { data, isLoading } = useSWR<{ schema: AddressFieldsSchema }>(
    country ? `/client/kyc/people/schema/${country}` : null,
    {
      revalidateIfStale: false,
    },
  );

  return { KYCCountrySchema: data?.schema, KYCCountrySchemaIsLoading: isLoading };
};
