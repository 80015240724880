import type { IconNames } from '@fin/icons';
import type { Currency } from '@fin/shared';

import type { User } from './user';

export const enum CardIssuingCardType {
  PERSONAL = 'PERSONAL',
  COMPANY = 'COMPANY',
}

export const cardIssuingCardTypeLabels: Record<CardIssuingCardType, string> = {
  [CardIssuingCardType.PERSONAL]: 'Personal',
  [CardIssuingCardType.COMPANY]: 'Company',
};

export const enum CardIssuingFormFactor {
  VIRTUAL = 'VIRTUAL',
  PHYSICAL = 'PHYSICAL',
}

export const cardIssuingFormFactorLabels: Record<CardIssuingFormFactor, string> = {
  [CardIssuingFormFactor.VIRTUAL]: 'Virtual',
  [CardIssuingFormFactor.PHYSICAL]: 'Physical',
};

export const enum CardIssuingLimitInterval {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ALL_TIME = 'ALL_TIME',
}

export const cardIssuingLimitIntervalLabels: Record<CardIssuingLimitInterval, string> = {
  [CardIssuingLimitInterval.DAILY]: 'Daily',
  [CardIssuingLimitInterval.WEEKLY]: 'Weekly',
  [CardIssuingLimitInterval.MONTHLY]: 'Monthly',
  [CardIssuingLimitInterval.ALL_TIME]: 'All Time',
};

export type Cardholder = Pick<User, 'email' | 'firstName' | 'lastName' | 'businessId'> & {
  id: string;
  mobilePhone: NonNullable<User['phone']>;
  userId: User['id'];
  type: CardIssuingCardType;
};

export const enum CardIssuingCardStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
  LOST = 'LOST',
  STOLEN = 'STOLEN',
  CLOSED = 'CLOSED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
}

export const cardIssuingCardStatusLabels: Record<CardIssuingCardStatus, string> = {
  [CardIssuingCardStatus.PENDING]: 'Pending',
  [CardIssuingCardStatus.ACTIVE]: 'Active',
  [CardIssuingCardStatus.INACTIVE]: 'Frozen',
  [CardIssuingCardStatus.BLOCKED]: 'Blocked',
  [CardIssuingCardStatus.LOST]: 'Lost',
  [CardIssuingCardStatus.STOLEN]: 'Stolen',
  [CardIssuingCardStatus.CLOSED]: 'Canceled',
  [CardIssuingCardStatus.FAILED]: 'Failed',
  [CardIssuingCardStatus.EXPIRED]: 'Expired',
};

export const cardIssuingCardStatusBadgeMapping: Record<CardIssuingCardStatus, { color: string; icon: IconNames }> = {
  [CardIssuingCardStatus.PENDING]: { color: 'blue', icon: 'pending' },
  [CardIssuingCardStatus.ACTIVE]: { color: 'gray', icon: 'check' },
  [CardIssuingCardStatus.INACTIVE]: { color: 'blue', icon: 'freeze' },
  [CardIssuingCardStatus.BLOCKED]: { color: 'red', icon: 'close' },
  [CardIssuingCardStatus.LOST]: { color: 'red', icon: 'close' },
  [CardIssuingCardStatus.STOLEN]: { color: 'red', icon: 'close' },
  [CardIssuingCardStatus.CLOSED]: { color: 'red', icon: 'close' },
  [CardIssuingCardStatus.FAILED]: { color: 'red', icon: 'close' },
  [CardIssuingCardStatus.EXPIRED]: { color: 'red', icon: 'close' },
};

export type CreditCard = {
  id: string;
  billingAddress: {
    addressLine_1: string;
    addressLine_2: string;
    administrativeArea: string;
    city: string;
    country: string;
    postalCode: string;
  };
  cardholder: Cardholder;
  cardholderId: Cardholder['id'];
  businessId: NonNullable<User['businessId']>;
  formFactor: CardIssuingFormFactor;
  type: CardIssuingCardType;
  description: string | null;
  lastFourDigits: string | null;
  singleTransactionLimitAmount: number;
  transactionLimitIntervalType?: CardIssuingLimitInterval;
  transactionLimitIntervalAmount?: number | null;
  nickname: string | null;
  status: CardIssuingCardStatus;
};

export type CardConfigFormType = {
  nickname: string | undefined;
  singleTransactionLimitAmount: number;
  transactionLimitIntervalEnabled: boolean;
  transactionLimitIntervalAmount: number | null;
  transactionLimitIntervalType: CardIssuingLimitInterval;
};

export const enum CardTransactionStatus {
  APPROVED = 'APPROVED',
  CLEARED = 'CLEARED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REVERSED = 'REVERSED',
}

export const cardTransactionStatusLabels: Record<CardTransactionStatus, string> = {
  [CardTransactionStatus.APPROVED]: 'Approved',
  [CardTransactionStatus.CLEARED]: 'Succeeded',
  [CardTransactionStatus.EXPIRED]: 'Expired',
  [CardTransactionStatus.FAILED]: 'Failed',
  [CardTransactionStatus.PENDING]: 'Pending',
  [CardTransactionStatus.REVERSED]: 'Reversed',
};

export const cardTransactionStatusBadgeMapping: Record<CardTransactionStatus, { color: string; icon: IconNames }> = {
  [CardTransactionStatus.APPROVED]: { color: 'gray', icon: 'check' },
  [CardTransactionStatus.CLEARED]: { color: 'gray', icon: 'check' },
  [CardTransactionStatus.EXPIRED]: { color: 'red', icon: 'time' },
  [CardTransactionStatus.FAILED]: { color: 'red', icon: 'close' },
  [CardTransactionStatus.PENDING]: { color: 'blue', icon: 'time' },
  [CardTransactionStatus.REVERSED]: { color: 'yellow', icon: 'error' },
};

export const enum CardTransactionType {
  AUTHORIZATION = 'AUTHORIZATION',
  CLEARING = 'CLEARING',
  REFUND = 'REFUND',
  REVERSAL = 'REVERSAL',
  ORIGINAL_CREDIT = 'ORIGINAL_CREDIT',
}

export const cardTransactionTypeLabels: Record<CardTransactionType, string> = {
  [CardTransactionType.AUTHORIZATION]: 'Authorization',
  [CardTransactionType.CLEARING]: 'Charge',
  [CardTransactionType.REFUND]: 'Refund',
  [CardTransactionType.REVERSAL]: 'Reversal',
  [CardTransactionType.ORIGINAL_CREDIT]: 'Merchant Credit',
};

export type CardTransaction = {
  id: string;
  cardholderId: Cardholder['id'];
  cardholder: Cardholder;
  cardId: CreditCard['id'];
  card: CreditCard;
  businessId: CreditCard['businessId'];
  billingAmount: number;
  billingCurrency: Currency;
  transactionType: CardTransactionType;
  transactionDirection: 'DEBIT' | 'CREDIT';
  transactionCurrency: Currency;
  transactionDate: string;
  transactionAmount: number;
  postedDate: string;
  merchant?: {
    city: string;
    name: string;
    country: string | null;
    categoryCode: string;
    administrativeArea: string | null;
  };
  merchantCategoryCode: string;
  networkTransactionId: string;
  retrievalReferenceNumber: string;
  failureReason: string | null;
  status: CardTransactionStatus;
};
