import type { SignInTheme } from '@clerk/types';

export const clerkSignInAppearance: SignInTheme = {
  elements: {
    logoBox: {
      height: 20,
      alignItems: 'flex-start',
      marginBottom: 8,
    },
    logoImage: {
      height: 20,
    },
    header: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    rootBox: {
      width: '100%',
    },
    cardBox: {
      width: '100%',
    },
    card: {
      padding: 8,
      boxShadow: 'none',
      '&[class*="signIn-havingTrouble"] button[data-color="primary"]': {
        minHeight: 48,
      },
    },
    formButtonPrimary: {
      minHeight: '48px !important',
    },
    footer: {
      background: 'none',
      marginTop: 0,
    },
    footerActionText: {
      fontSize: 14,
    },
    footerActionLink: {
      fontSize: 14,
    },
    otpCodeFieldInputs: {
      width: '100%',
      marginLeft: 0,
      padding: '4px 0',
    },
    otpCodeFieldInput: {
      width: 'inherit',
      height: 50,
      maxHeight: 50,
    },
    formResendCodeLink: {
      alignSelf: 'flex-start',
    },
    otpCodeFieldErrorText: {
      left: 0,
    },
    identityPreview: {
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
    alternativeMethodsBlockButton: {
      minHeight: '48px !important',
    },
  },
};
