import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
  useClipboard,
} from '@chakra-ui/react';

import { useAuth, useSignIn } from '@clerk/clerk-react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Icon } from '@fin/icons';
import { Logger } from '@fin/shared';
import {
  type BusinessMembership,
  type CompanyInfoType,
  CompanyRegistrationType,
  companyRegistrationTypeLabels,
  companyStatusBadgeMapping,
  companyStatusLabels,
  RoleLabels,
} from '@fin/types';
import { countries, formatPhoneNumber, modalProps, showNotification } from '@fin/utils';

import { useBusinessMemberships } from '@app/hooks';
import { UserService } from '@app/services';

import { ConfirmImpersonation } from './ConfirmImpersonation';

type BusinessDetailsProps = {
  business: CompanyInfoType;
};

export const BusinessDetails = NiceModal.create<BusinessDetailsProps>(({ business }) => {
  const modal = useModal();
  const { onCopy, hasCopied } = useClipboard(business.id);
  const { memberships } = useBusinessMemberships(business.id);
  const { isLoaded, signIn, setActive } = useSignIn();
  const { signOut } = useAuth();

  const handleImpersonate = async (membership: BusinessMembership) => {
    if (!isLoaded) return;

    try {
      const res = await UserService.impersonate(membership.user.id, membership.business.id);

      await signOut(); // Need to sign out as only one active session is allowed

      const { createdSessionId } = await signIn.create({ strategy: 'ticket', ticket: res.data.token });

      await setActive({ session: createdSessionId });

      window.open(import.meta.env.VITE_MAIN_APP_URL, '_blank')?.focus();
    } catch (e) {
      Logger.error(e);
      showNotification({
        title: 'Error',
        description: 'Something went wrong, please try again later',
        status: 'error',
      });
    }
  };

  const handleUserClick = (membership: BusinessMembership) => {
    modal.hide();
    NiceModal.show(ConfirmImpersonation, {
      membership,
      onAccept: () => handleImpersonate(membership),
      onCancel: () => modal.show({ business }),
    });
  };

  return (
    <Drawer {...modalProps(modal)} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader as={Flex} px={4} py={3} alignItems="center" borderBottom="1px" borderBottomColor="borderStroke">
          <Heading mr="auto">{business.companyName}</Heading>
          <IconButton aria-label="Close Details" size="sm" variant="ghost" onClick={modal.hide} color="contentTertiary">
            <Icon name="close" size={24} />
          </IconButton>
        </DrawerHeader>
        <DrawerBody p={4} display="flex" flexDirection="column">
          <Flex mb={4} alignItems="center" gap={4}>
            <Heading size="md">Company Details</Heading>
            <Badge variant="numericGray" onClick={onCopy} display="flex" alignItems="center" gap={2} cursor="pointer">
              {business.id}
              <Icon name={hasCopied ? 'check' : 'copy'} size={16} />
            </Badge>
          </Flex>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacingY={{ base: 4, md: 6 }} spacingX={2}>
            <Box>
              <Text size="xs" mb={2}>
                Legal Business Name
              </Text>
              <Text variant="primary" fontWeight={500}>
                {business.companyName}
              </Text>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Status
              </Text>
              <Badge variant="status" colorScheme={companyStatusBadgeMapping[business.status].color}>
                <Icon name={companyStatusBadgeMapping[business.status].icon} size={18} />
                {companyStatusLabels[business.status]}
              </Badge>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Corporation Number or Registry ID
              </Text>
              <Text variant="primary" fontWeight={500}>
                {business.companyNumber}
              </Text>
            </Box>
            <Box />
            <Box>
              <Text size="xs" mb={2}>
                Type of Registration
              </Text>
              <Text variant="primary" fontWeight={500}>
                {companyRegistrationTypeLabels[business.registrationType]}
              </Text>
            </Box>
            {business.registrationType === CompanyRegistrationType.PROVINCIAL && (
              <Box>
                <Text size="xs" mb={2}>
                  Province of Registration
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {business.administrativeAreaOfRegistration}
                </Text>
              </Box>
            )}
            <Box>
              <Text size="xs" mb={2}>
                Phone
              </Text>
              <Text variant="primary" fontWeight={500}>
                {formatPhoneNumber(business.phone)}
              </Text>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Email
              </Text>
              <Text variant="primary" fontWeight={500}>
                {business.email}
              </Text>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Address
              </Text>
              <Text variant="primary" fontWeight={500} whiteSpace="pre-wrap">
                {business.addressLine_1}, {business.addressLine_2 ? `${business.addressLine_2}, ` : ''}
                {`\n`}
                {business.city}, {business.administrativeArea}, {business.postalCode}
                {`\n`}
                {countries[business.country]}
              </Text>
            </Box>
          </SimpleGrid>
          <Divider my={5} />
          <Heading size="md" mb={4}>
            Select Person to Impersonate
          </Heading>

          {memberships?.map((membership, i, array) => {
            const userName = `${membership.user.firstName} ${membership.user.lastName}`;
            return (
              <>
                <Flex
                  key={membership.user.id}
                  mb={2}
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => handleUserClick(membership)}
                >
                  <Avatar name={userName} size="md" mr={2} />
                  <Box>
                    <Text variant="primary" fontWeight={500}>
                      {userName}
                    </Text>
                    <Text size="xs">{RoleLabels[membership.roles[0]]}</Text>
                  </Box>
                  <Icon name="chevron-right" size={20} color="contentSecondary" ml="auto" />
                </Flex>
                {i !== array.length && <Divider mb={2} />}
              </>
            );
          })}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
});
