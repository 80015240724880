import { useMemo, useState } from 'react';

import { dayjs, getByPath, isDateString } from '@fin/utils';

export const useEntitiesSort = <
  T extends Record<string, unknown>,
  S extends string,
  G extends Record<string, (v: T) => string | number>,
>(
  entities: T[] | undefined,
  defaultSortField: S | `!${S}`,
  getValueOverrides: G,
) => {
  const [sortBy, setSortBy] = useState<S | `!${S}`>(defaultSortField);

  const changeSortBy = (field: S, isSet?: boolean) => {
    setSortBy((v) => {
      if (isSet || !v.includes(field) || v.startsWith('!')) return field;

      return `!${field}`;
    });
  };

  const sortedEntities = useMemo(() => {
    if (!entities) return entities;

    const order = sortBy.startsWith('!') ? 'DESC' : 'ASC';
    const sortField = sortBy.replace('!', '') as keyof T as string;

    return entities.sort((a, b) => {
      const aValue = getValueOverrides[sortField]
        ? getValueOverrides[sortField](order === 'ASC' ? a : b)
        : getByPath(order === 'ASC' ? a : b, sortField);
      const bValue = getValueOverrides[sortField]
        ? getValueOverrides[sortField](order === 'ASC' ? b : a)
        : getByPath(order === 'ASC' ? b : a, sortField);

      if (isDateString(aValue) || isDateString(bValue)) {
        if (!aValue && !bValue) {
          return 0;
        } else if (!aValue) {
          return 1;
        } else if (!bValue) {
          return -1;
        }

        return dayjs(aValue).isAfter(bValue) ? -1 : 1;
      }

      switch (typeof aValue) {
        case 'string':
          return aValue.localeCompare(bValue);
        case 'number':
          return aValue - bValue;
      }
      return 0;
    }) as Array<T & G>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, entities]);

  return {
    sortedEntities,
    sortBy,
    changeSortBy,
  };
};
