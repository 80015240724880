import useSWR from 'swr';

import type { CompanyInfoType, CreditCard } from '@fin/types';

export const useBusinessCards = (businessId: CompanyInfoType['id'] | undefined) => {
  const { data, isLoading } = useSWR<{ cards: CreditCard[] }>(
    businessId ? `/admin/businesses/${businessId}/card_issuing/cards` : null,
  );

  return {
    cards: data?.cards,
    cardsIsLoading: isLoading,
  };
};
