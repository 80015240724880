import { useCallback, useContext, useEffect, useRef } from 'react';

import NiceModal from '@ebay/nice-modal-react';

type CloseAllModalsProps = {
  pathname: string;
  isLoggedIn: boolean;
  keepOpened?: string[];
};

export const CloseAllModals = ({ pathname, isLoggedIn, keepOpened }: CloseAllModalsProps) => {
  const niceModalContext = useContext(NiceModal.NiceModalContext);
  const prevPathname = useRef(pathname);
  const contextRef = useRef(niceModalContext);

  useEffect(() => {
    contextRef.current = niceModalContext;
  }, [niceModalContext]);

  const closeAllModals = useCallback(() => {
    Object.keys(contextRef.current).forEach(async (key) => {
      if (keepOpened?.includes(key)) return;
      NiceModal.hide(key);

      // in some cases hide doesn't resolve promise and modal key stays in context
      // need to remove it after some timeout
      setTimeout(() => {
        if (Object.keys(contextRef.current).length) {
          NiceModal.remove(key);
        }
      }, 500);
    });
  }, [keepOpened]);

  useEffect(() => {
    if (prevPathname.current !== pathname) {
      prevPathname.current = pathname;
      closeAllModals();
    }
  }, [closeAllModals, pathname]);

  useEffect(() => {
    if (!isLoggedIn) closeAllModals();
  }, [closeAllModals, isLoggedIn]);

  return null;
};
