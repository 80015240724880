import type { FunctionComponent } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { cardTransactionTypeLabels } from '@fin/types';
import { formatPrice } from '@fin/utils';

import { type CreditCardSimulationTransaction } from '@app/types';

import { CardTransactionDetails } from '../TransactionDetails/CardTransactionDetails';

export const CreditCardTransactionItem = ({
  transaction,
  as,
}: {
  transaction: CreditCardSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const { amount, currency, relatedEntity } = transaction;

  const TransactionItemComponent = as || TransactionItem;

  return (
    <TransactionItemComponent
      icon="transactionCreditCard"
      title={
        <Text variant="primary">
          {relatedEntity.transactionType
            ? `${cardTransactionTypeLabels[relatedEntity.transactionType]}${
                relatedEntity.merchant?.name.length ? ` - ${relatedEntity.merchant?.name}` : ''
              }`
            : 'Card Transaction'}
        </Text>
      }
      amount={
        <Flex alignItems="center" gap={1}>
          <Text size="inherit" variant="number" color={amount > 0 ? 'brandGreen.500' : 'inherit'}>{`${
            amount < 0 ? '-' : '+'
          }${formatPrice(Math.abs(amount))}`}</Text>
          <Badge variant="textual">{currency}</Badge>
        </Flex>
      }
      onClick={
        relatedEntity.transactionType
          ? () => NiceModal.show(CardTransactionDetails, { transaction: relatedEntity })
          : undefined
      }
    />
  );
};
