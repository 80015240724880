import { Navigate, Outlet } from 'react-router-dom';

import { routes } from '@app/routing/routes';

type RouteGuardProps = {
  hasAccess: boolean;
  redirectTo?: (typeof routes)[keyof typeof routes];
};

export const RouteGuard = ({ hasAccess, redirectTo = routes.home }: RouteGuardProps) => {
  return hasAccess ? <Outlet /> : <Navigate to={redirectTo} />;
};
