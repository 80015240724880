import { Box, Flex } from '@chakra-ui/react';

import { OrganizationSwitcher, UserButton } from '@fin/components';

import { routes } from '@app/routing/routes';

type ProfileActionProps = {
  isExpanded: boolean;
};

export const ProfileAction = ({ isExpanded }: ProfileActionProps) => {
  return (
    <Flex
      px={2}
      py="10px"
      gap={2}
      borderTop="1px solid"
      borderTopColor="borderStroke"
      justifyContent="space-between"
      alignItems="center"
      flexDirection={isExpanded ? 'row' : 'column-reverse'}
    >
      <OrganizationSwitcher
        afterSelectOrganizationUrl={routes.home}
        afterLeaveOrganizationUrl={routes.home}
        appearance={{
          elements: {
            organizationSwitcherPopoverActionButton__createOrganization: {
              display: 'none',
            },
            ...(isExpanded
              ? {
                  organizationSwitcherTriggerIcon: {
                    marginRight: 8,
                  },
                }
              : {
                  organizationPreviewTextContainer__organizationSwitcherTrigger: {
                    display: 'none',
                  },
                  organizationSwitcherTriggerIcon: {
                    display: 'none',
                  },
                }),
          },
        }}
      />
      <Box display={isExpanded ? 'none' : 'block'} w="full" borderTop="1px solid" borderTopColor="borderStroke" />
      <UserButton />
    </Flex>
  );
};
