import useSWR from 'swr';

import { type KYCFile } from '@fin/types';

export const useKYCDocuments = (id: string | undefined) => {
  const { data, isLoading } = useSWR<{ kycFiles: KYCFile[] }>(id ? `/admin/kyc/${id}/files` : null);

  return {
    kycDocuments: data?.kycFiles,
    kycDocumentsIsLoading: isLoading,
  };
};
