import { type FunctionComponent, useMemo } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { type IconNames } from '@fin/icons';
import { Logger } from '@fin/shared';
import { DDDTransactionStatus } from '@fin/types';
import { formatPrice, getBankAccountTitle } from '@fin/utils';

import { type DDDSimulationTransaction } from '@app/types';

import { DDDTransactionDetails } from '../TransactionDetails/DDDTransactionDetails';

export const DDDTransactionItem = ({
  transaction,
  as,
}: {
  transaction: DDDSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const { amount, currency, relatedEntity } = transaction;

  const TransactionItemComponent = as || TransactionItem;

  const status = useMemo((): DDDTransactionStatus => {
    switch (transaction.status) {
      case 'SETTLED': {
        if (transaction.transactionType === 'DEPOSIT_REVERSAL') return DDDTransactionStatus.DEPOSIT_REVERSAL;
        return DDDTransactionStatus.DEPOSIT;
      }
      case 'CANCELLED': {
        return DDDTransactionStatus.CANCELLED;
      }
      case 'PENDING': {
        return DDDTransactionStatus.PENDING;
      }
      default: {
        Logger.error(`DDTransaction unhandled case, ${JSON.stringify(transaction)}`);
        return DDDTransactionStatus.DEPOSIT;
      }
    }
  }, [transaction]);

  const iconDataMapping: Record<DDDTransactionStatus, { icon: IconNames; iconColor: string }> = {
    [DDDTransactionStatus.DEPOSIT]: { icon: 'transactionDD', iconColor: 'brandGreen.500' },
    [DDDTransactionStatus.DEPOSIT_REVERSAL]: { icon: 'transactionFailed', iconColor: 'brandRed.500' },
    [DDDTransactionStatus.PENDING]: { icon: 'transactionPending', iconColor: 'brandYellow.500' },
    [DDDTransactionStatus.CANCELLED]: { icon: 'transactionFailed', iconColor: 'brandRed.500' },
  };

  const iconData = iconDataMapping[status];

  return (
    <TransactionItemComponent
      icon={iconData.icon}
      iconColor={iconData.iconColor}
      title={
        <Text variant="primary">{`${
          status === DDDTransactionStatus.DEPOSIT_REVERSAL ? 'Deposit Reversal to' : 'Deposit from'
        } ${getBankAccountTitle(relatedEntity.directDebitBankAccount)}`}</Text>
      }
      amount={
        <Flex alignItems="center" gap={1}>
          <Text
            size="inherit"
            variant="number"
            color={status === DDDTransactionStatus.DEPOSIT ? 'brandGreen.500' : 'inherit'}
          >{`${amount < 0 ? '-' : '+'}${formatPrice(Math.abs(amount))}`}</Text>
          <Badge variant="textual">{currency}</Badge>
          {status === DDDTransactionStatus.PENDING && (
            <Badge variant="status" colorScheme="blue" ml={1}>
              Pending
            </Badge>
          )}
        </Flex>
      }
      onClick={() => NiceModal.show(DDDTransactionDetails, { transaction })}
    />
  );
};
