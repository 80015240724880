const isObject = (item: unknown): item is Record<string, unknown> =>
  item !== null && typeof item === 'object' && !Array.isArray(item);

type MergeDeepTwo<T, U> = T extends readonly any[]
  ? U extends readonly any[]
    ? [...T, ...U]
    : U
  : T extends object
    ? U extends readonly any[]
      ? U
      : U extends object
        ? MergeDeepTwoObj<T, U>
        : U
    : U;

type MergeDeepTwoObj<T extends object, U extends object> = {
  [K in keyof (T & U)]: K extends keyof T
    ? K extends keyof U
      ? MergeDeepTwo<T[K], U[K]>
      : T[K]
    : K extends keyof U
      ? U[K]
      : never;
};

export function deepMerge<T extends Record<string, unknown>, U extends Record<string, unknown>>(
  target: T,
  source: U,
): MergeDeepTwoObj<T, U> {
  const output = { ...target } as MergeDeepTwoObj<T, U>;

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      const sourceValue = source[key];
      const targetValue = target[key];

      if (isObject(sourceValue) && isObject(targetValue)) {
        output[key as keyof MergeDeepTwoObj<T, U>] = deepMerge(targetValue, sourceValue) as MergeDeepTwoObj<
          T,
          U
        >[keyof MergeDeepTwoObj<T, U>];
      } else {
        output[key as keyof MergeDeepTwoObj<T, U>] = sourceValue as MergeDeepTwoObj<T, U>[keyof MergeDeepTwoObj<T, U>];
      }
    });
  }

  return output;
}
