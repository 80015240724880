import useSWR from 'swr';

import type { CompanyInfoType } from '@fin/types';
import { type Currency, mainCurrencies } from '@fin/utils';

export const useBusinessAccounts = (businessId: CompanyInfoType['id'] | undefined) => {
  const { data } = useSWR<{ enabledCurrencies: Currency[] }>(
    businessId ? `/admin/businesses/${businessId}/enabled_currencies` : null,
  );

  return {
    accounts: data?.enabledCurrencies.slice().sort((a, b) => {
      const aIndex = mainCurrencies.indexOf(a);
      const bIndex = mainCurrencies.indexOf(b);
      if (aIndex === -1 && bIndex === -1) {
        return a.localeCompare(b);
      } else if (aIndex === -1) {
        return 1;
      } else if (bIndex === -1) {
        return -1;
      } else {
        return aIndex - bIndex;
      }
    }),
  };
};
