import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Switch: ComponentStyleConfig = {
  baseStyle: {
    track: {
      bg: 'contentTertiary',
      _checked: {
        bg: 'brand.500',
      },
    },
  },
  defaultProps: {
    colorScheme: 'brand',
  },
};
