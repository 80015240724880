import { type ForwardedRef, forwardRef, type ReactElement, type Ref } from 'react';

import { Box, IconButton, Text } from '@chakra-ui/react';

import { chakraComponents, Select as CRSelect, type GroupBase, type Props } from 'chakra-react-select';

import { Icon } from '@fin/icons';

export type Option<T = string | number | boolean | null> = { label?: string; value: T };

type BaseProps<T extends Option> = Omit<Props<T, false>, 'value' | 'onChange' | 'options'> & {
  value: T['value'] | undefined | null;
  onChange: (v: T['value']) => void;
  withGroup?: boolean;
  labelInside?: string;
  isEmptyAllowed?: boolean;
  autoComplete?: string;
};

type SingleModeProps<T extends Option> = BaseProps<T> & { options: T[] };
type GroupModeProps<T extends Option> = BaseProps<T> & { options: Array<GroupBase<T>> };

export type SelectProps<T extends Option> = SingleModeProps<T> | GroupModeProps<T>;

function isGroupMode<T extends Option>(props: SelectProps<T>): props is GroupModeProps<T> {
  return !!props.withGroup;
}

const ClearIndicator = (props: any) => (
  <IconButton aria-label="Clear Selected Value" size="xs" variant="ghost" color="contentTertiary" {...props.innerProps}>
    <Icon name="close" size={16} />
  </IconButton>
);

const DropdownIndicator = (props: any) => {
  return (
    <Box
      aria-label="Toggle Select"
      cursor="pointer"
      color={props.isDisabled ? 'contentDisabled' : 'contentSecondary'}
      mr={4}
      {...props.innerProps}
    >
      <Icon name="chevron-up" size={18} style={{ transform: `rotate(${props.selectProps.menuIsOpen ? 0 : 180}deg)` }} />
    </Box>
  );
};

const Control = ({ children, ...props }: any) => {
  return (
    <chakraComponents.Control {...props}>
      {props.selectProps.labelInside && (
        <Text size="sm" pl={2} mr={-1.5}>
          {props.selectProps.labelInside}
        </Text>
      )}
      {children}
    </chakraComponents.Control>
  );
};

const Input = (props: any) => <chakraComponents.Input {...props} autoComplete={props.selectProps.autoComplete} />;

const SelectComponent = <T extends Option>(props: SelectProps<T>, ref: ForwardedRef<any>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, onChange, components, placeholder, chakraStyles, withGroup, name, isEmptyAllowed, ...rest } = props;

  return (
    <CRSelect<T>
      ref={ref}
      onChange={(v) => {
        if (props.isMulti) {
          // @ts-expect-error bad types for multi mode
          return onChange(v?.map((i) => i.value) ?? null);
        }
        onChange(v?.value ?? null);
      }}
      value={
        (value !== undefined && value !== null) || isEmptyAllowed
          ? isGroupMode(props)
            ? props.options.flatMap((v) => v.options).find((v) => v.value === value)
            : props.isMulti
              ? // @ts-expect-error bad types for multi mode
                props.options.filter((o) => value.includes(o.value))
              : props.options.find((o) => o.value === value)
          : null
      }
      placeholder={placeholder || 'Select'}
      blurInputOnSelect
      menuShouldScrollIntoView={false}
      menuPlacement="auto"
      isSearchable={false}
      name={name}
      formatOptionLabel={(data) => {
        // Inject a data-cy attribute so we can find the Option in testing.
        return <span data-cy={`select-${name}-option-${data.value}`}>{data.label}</span>;
      }}
      {...rest}
      selectedOptionColorScheme="bg"
      chakraStyles={{
        menu: (prev) => ({
          ...prev,
          zIndex: 10,
        }),
        menuList: (prev) => ({
          ...prev,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        option: (prev, state) => ({
          ...prev,
          minHeight: '46px',
          color: 'contentPrimary',
          bg: state.isSelected || state.isFocused ? 'bg.100' : 'bg.0',
          fontSize: 14,
          ...chakraStyles?.option?.(prev, state),
          _selected: {
            color: 'contentPrimary',
          },
        }),
        placeholder: (prev) => ({
          ...prev,
          color: 'contentSecondary',
        }),
        noOptionsMessage: (prev) => ({
          ...prev,
          fontSize: 14,
          color: 'contentSecondary',
        }),
        groupHeading: (prev, state) => ({
          paddingX: 3,
          paddingY: 2,
          fontSize: 'sm',
          fontWeight: '500',
          color: 'contentSecondary',
          ...chakraStyles?.groupHeading?.(prev, state),
        }),
        group: (prev, state) => ({
          ...chakraStyles?.group?.(prev, state),
        }),
      }}
      components={{
        ClearIndicator,
        DropdownIndicator,
        Control,
        Input,
        ...components,
      }}
    />
  );
};

export const Select = forwardRef(SelectComponent) as <T extends Option>(
  props: SelectProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
