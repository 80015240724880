import useSWR from 'swr';

import type { BusinessConfigPaymentMethod } from '@fin/types';

export const useDDPaymentMethods = () => {
  const { data, isLoading, mutate } = useSWR<{ paymentMethods: BusinessConfigPaymentMethod[] }>(
    `/admin/direct_debit/payment_methods`,
  );

  return {
    DDPaymentMethods: data?.paymentMethods,
    DDPaymentMethodsIsLoading: isLoading,
    mutateDDPaymentMethods: mutate,
  };
};
