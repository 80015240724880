import React, { Fragment, useState } from 'react';

import { Badge, Box, Divider, Flex, Heading, IconButton, Spinner, Tab, Text } from '@chakra-ui/react';

import { Link as RRLink, useParams } from 'react-router-dom';

import { TabBar } from '@fin/components';
import { Icon } from '@fin/icons';
import { Logger } from '@fin/shared';
import {
  CompanyRegistrationType,
  companyRegistrationTypeLabels,
  type KYCFile,
  KYCStatusBadgeMapping,
  KYCUserIDVStatus,
} from '@fin/types';
import { countries, formatDate, formatFileSize, formatPhoneNumber, getStates, KYC, showNotification } from '@fin/utils';

import { useKYCDeclaration, useKYCDetails, useKYCDocuments, useKYCIndustries, useKYCUsers } from '@app/hooks';
import { routes } from '@app/routing/routes';
import { KYCService } from '@app/services';

export const KYCDetails = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const params = useParams<{ id: string }>();

  const { kycDetails, kycDetailsIsLoading } = useKYCDetails(params?.id);
  const { kycUsers } = useKYCUsers(params?.id);
  const { kycDocuments } = useKYCDocuments(params?.id);
  const { kycDeclaration } = useKYCDeclaration(params?.id);
  const { getIndustryInfo } = useKYCIndustries();

  const isUSCompany = kycDetails?.companyCountry === 'US';
  const leadershipField = isUSCompany ? 'isControlPerson' : 'isADirector';

  const handleDownloadFile = async (file: KYCFile) => {
    if (!params?.id) return;

    try {
      const link = await KYCService.getFileLink(params?.id, file.id);
      window.open(link, '_blank');
    } catch (e) {
      Logger.error(e);
      showNotification({
        title: 'Error',
        description: 'Something went wrong, please try again later',
        status: 'error',
      });
    }
  };

  if (!kycDetails || kycDetailsIsLoading) {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" h="100vh">
        <Spinner color="brand.500" size="xl" />
      </Flex>
    );
  }

  const renderContactDetails = () => {
    const applicant = kycDetails.applicant;

    if (!applicant) return <Text>There is no applicant</Text>;

    return (
      <>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          mb={2}
          gap={6}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiary-individual" size={20} />
            <Heading size="sm">Contact Information</Heading>
          </Flex>
          <Box>
            <Text size="xs" mb={2}>
              Legal First Name
            </Text>
            <Text variant="primary" fontWeight={500}>
              {applicant.firstName}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Legal Last Name
            </Text>
            <Text variant="primary" fontWeight={500}>
              {applicant.lastName}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Date of Birth
            </Text>
            <Text variant="primary" fontWeight={500}>
              {formatDate(applicant.dateOfBirth)}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Title / Position
            </Text>
            <Text variant="primary" fontWeight={500}>
              {KYC.userPositionOptions.find((i) => i.value === applicant.title)?.label || applicant.title}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Email Address
            </Text>
            <Text variant="primary" fontWeight={500}>
              {applicant.emailAddress}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Phone Number
            </Text>
            <Text variant="primary" fontWeight={500}>
              {formatPhoneNumber(applicant.primaryPhone)}
            </Text>
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          gap={6}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiary-individual" size={20} />
            <Heading size="sm">Personal Address</Heading>
          </Flex>
          <Box>
            <Text size="xs" mb={2}>
              Address
            </Text>
            <Text variant="primary" fontWeight={500} whiteSpace="pre-wrap">
              {applicant.addressLine_1}, {applicant.addressLine_2 ? `${applicant.addressLine_2}, ` : ''}
              {`\n`}
              {applicant.city}, {applicant.province}, {applicant.postalCode}
              {`\n`}
              {countries[applicant.country]}
            </Text>
          </Box>
        </Flex>
      </>
    );
  };

  const renderCABusinessDetails = () => (
    <>
      <Box>
        <Text size="xs" mb={2}>
          Type of Registration
        </Text>
        <Text variant="primary" fontWeight={500}>
          {kycDetails && companyRegistrationTypeLabels[kycDetails.companyTypeOfRegistration]}
        </Text>
      </Box>
      {kycDetails?.companyTypeOfRegistration === CompanyRegistrationType.PROVINCIAL && (
        <Box>
          <Text size="xs" mb={2}>
            Province of Registration
          </Text>
          <Text variant="primary" fontWeight={500}>
            {getStates('CA').find((i) => i.value === kycDetails?.companyProvinceOfRegistration)?.label ||
              kycDetails?.companyProvinceOfRegistration}
          </Text>
        </Box>
      )}
      <Box>
        <Text size="xs" mb={2}>
          Corporation Number or Registry ID
        </Text>
        <Text variant="primary" fontWeight={500}>
          {kycDetails?.companyRegistrationNumber}
        </Text>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          CRA Business Number
        </Text>
        <Text variant="primary" fontWeight={500}>
          {kycDetails?.companyCraBusinessNumber}
        </Text>
      </Box>
    </>
  );

  const renderUSBusinessDetails = () => (
    <>
      <Box>
        <Text size="xs" mb={2}>
          State of Incorporation
        </Text>
        <Text variant="primary" fontWeight={500}>
          {getStates('US').find((i) => i.value === kycDetails?.companyStateOfIncorporation)?.label ||
            kycDetails?.companyStateOfIncorporation}
        </Text>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Type of Corporation
        </Text>
        <Text variant="primary" fontWeight={500}>
          {KYC.typeOfCorporationOptions.find((i) => i.value === kycDetails?.companyTypeOfCorporation)?.label}
        </Text>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Business EIN
        </Text>
        <Text variant="primary" fontWeight={500}>
          {kycDetails?.companyEin}
        </Text>
      </Box>
    </>
  );

  const renderIndustry = () => {
    const industryInfo = getIndustryInfo(kycDetails.industryCode);

    if (!industryInfo) return null;

    return (
      <>
        <Box>
          <Text size="xs" mb={2}>
            What Industry is your business in?
          </Text>
          <Text variant="primary" fontWeight={500}>
            {industryInfo.sector} / {industryInfo.industry}
          </Text>
        </Box>
        {industryInfo.question && (
          <Box>
            <Text size="xs" mb={2}>
              {industryInfo.question}
            </Text>
            <Text variant="primary" fontWeight={500}>
              {industryInfo.answer}
            </Text>
          </Box>
        )}
      </>
    );
  };

  const renderDocument = (file: KYCFile) => (
    <Flex alignItems="center" p={1}>
      <Box color="contentSecondary" bg="bg.100" rounded={4} mr={2}>
        <Icon name="file" size={20} />
      </Box>
      <Box flex={1}>
        <Text variant="primary" fontWeight={500}>
          {file?.filename}
        </Text>
        {file?.fileSize && <Text size="xs">{formatFileSize(file.fileSize)}</Text>}
      </Box>
      <IconButton aria-label="Download file" variant="ghost" size="sm" onClick={() => handleDownloadFile(file)}>
        <Icon name="download" size={20} color="contentSecondary" />
      </IconButton>
    </Flex>
  );

  const renderBusinessDetails = () => {
    return (
      <>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          mb={2}
          gap={6}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiary-company" size={20} />
            <Heading size="sm">Tell us more about your company</Heading>
          </Flex>
          <Box>
            <Text size="xs" mb={2}>
              What is your business website?
            </Text>
            <Text variant="primary" fontWeight={500}>
              {kycDetails.companyHasNoWebsite ? 'I don’t have a business website' : kycDetails.companyWebsite}
            </Text>
          </Box>
          {renderIndustry()}
          <Box>
            <Text size="xs" mb={2}>
              What is your intended use for this account?
            </Text>
            <Text variant="primary" fontWeight={500}>
              {KYC.usageOptions.find((i) => i.value === kycDetails.companyPrimaryPurpose)?.label}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              What is your primary source of funds?
            </Text>
            <Text variant="primary" fontWeight={500}>
              {KYC.sourceOfFundsOptions.find((i) => i.value === kycDetails.companyPrimarySourceOfFunds)?.label ||
                kycDetails.companyPrimarySourceOfFunds}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              How did you hear about us?
            </Text>
            <Text variant="primary" fontWeight={500}>
              {KYC.hearAboutFinofoOptions.find((i) => i.value === kycDetails.companyHowTheyFoundFinofo)?.label}
            </Text>
          </Box>
          {kycDetails.companyHowTheyFoundFinofo === 'referral' && (
            <Box>
              <Text size="xs" mb={2}>
                Who referred you?
              </Text>
              <Text variant="primary" fontWeight={500}>
                {kycDetails.referrer}
              </Text>
            </Box>
          )}
        </Flex>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          gap={6}
          mb={2}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiary-company" size={20} />
            <Heading size="sm">Tell us more about your company</Heading>
          </Flex>
          <Box>
            <Text size="xs" mb={2}>
              Please describe your customer demographics
            </Text>
            <Text variant="primary" fontWeight={500}>
              {kycDetails.customerDemographics}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Please describe what you sell as a company
            </Text>
            <Text variant="primary" fontWeight={500}>
              {kycDetails.productOrServicesSold}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Please describe your corporate expenses
            </Text>
            <Text variant="primary" fontWeight={500}>
              {kycDetails.corporateExpenses}
            </Text>
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          gap={6}
          mb={2}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiary-company" size={20} />
            <Heading size="sm">Company Information</Heading>
          </Flex>
          <Box>
            <Text size="xs" mb={2}>
              What is your legal structure?
            </Text>
            <Text variant="primary" fontWeight={500}>
              {KYC.legalStructureOptions.find((i) => i.value === kycDetails.companyLegalEntityType)?.label}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Business Legal Name
            </Text>
            <Text variant="primary" fontWeight={500}>
              {kycDetails.companyName}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Operating Name
            </Text>
            <Text variant="primary" fontWeight={500}>
              {kycDetails.companyTradingName}
            </Text>
          </Box>
          {kycDetails?.companyCountry === 'CA' && renderCABusinessDetails()}
          {kycDetails?.companyCountry === 'US' && renderUSBusinessDetails()}
          <Box>
            <Text size="xs" mb={2}>
              Date of Incorporation / Registration
            </Text>
            <Text variant="primary" fontWeight={500}>
              {formatDate(kycDetails.companyDateOfRegistration)}
            </Text>
          </Box>
          <Box>
            <Text size="xs" mb={2}>
              Business Address
            </Text>
            <Text variant="primary" fontWeight={500} whiteSpace="pre-wrap">
              {kycDetails.companyAddressLine_1},{' '}
              {kycDetails.companyAddressLine_2 ? `${kycDetails.companyAddressLine_2}, ` : ''}
              {`\n`}
              {kycDetails.companyCity}, {kycDetails.companyProvince}, {kycDetails.companyPostalCode}
              {`\n`}
              {countries[kycDetails.companyCountry]}
            </Text>
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          gap={6}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiary-company" size={20} />
            <Heading size="sm">Company documents</Heading>
          </Flex>
          <Box>
            <Text size="xs" mb={2}>
              Articles of Incorporation
            </Text>
            {kycDocuments
              ?.filter((file) => file.type === 'articles_of_incorporation')
              .map((file) => renderDocument(file))}
          </Box>

          {kycDetails.companyHasNoWebsite && (
            <>
              <Box>
                <Text size="xs" mb={2}>
                  Business Activity
                </Text>
                {kycDocuments
                  ?.filter((file) => file.type === 'proof_of_business_activity')
                  .map((file) => renderDocument(file))}
              </Box>
              <Box>
                <Text size="xs" mb={2}>
                  Source of Funds
                </Text>
                {kycDocuments?.filter((file) => file.type === 'proof_of_funds').map((file) => renderDocument(file))}
              </Box>
            </>
          )}
        </Flex>
      </>
    );
  };

  const renderLeadershipDetails = () => {
    const directors = kycUsers?.filter((user) => user[leadershipField]) || [];

    return (
      <>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          mb={2}
          gap={6}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiary-company" size={20} />
            <Heading size="sm">{isUSCompany ? 'Control Persons' : 'Company Directors'}</Heading>
          </Flex>
          {!directors.length && <Text>There are no {isUSCompany ? 'control persons' : 'directors'}</Text>}
          {directors.map((director, index, array) => (
            <Fragment key={director.id}>
              <Text variant="primary" fontWeight={500}>
                {isUSCompany ? 'Person' : 'Director'} {index + 1}
              </Text>
              <Box mt={-2}>
                <Text size="xs" mb={2}>
                  Legal First Name
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {director.firstName}
                </Text>
              </Box>
              <Box>
                <Text size="xs" mb={2}>
                  Legal Last Name
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {director.lastName}
                </Text>
              </Box>
              <Box>
                <Text size="xs" mb={2}>
                  Email Address
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {director.emailAddress}
                </Text>
              </Box>
              {director.isControlPerson && (
                <Box>
                  <Text size="xs" mb={2}>
                    Title
                  </Text>
                  <Text variant="primary" fontWeight={500}>
                    {KYC.userPositionOptions.find((i) => i.value === director.title)?.label || director.title}
                  </Text>
                </Box>
              )}
              {index < array.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Flex>
      </>
    );
  };

  const renderOwnershipDetails = () => {
    const owners = kycUsers?.filter((user) => user.ownershipPercentage > 0) || [];

    const authorizedSignatory = kycUsers?.find((user) => user.isAnAuthorizedSignatory);
    return (
      <>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          mb={2}
          gap={6}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiary-company" size={20} />
            <Heading size="sm">Company Ownership</Heading>
          </Flex>
          <Box>
            <Text size="xs" mb={2}>
              Are there any individuals with 25% or more of ownership share in the company?
            </Text>
            <Text variant="primary" fontWeight={500}>
              {owners.length > 0 ? 'Yes' : 'No'}
            </Text>
          </Box>
        </Flex>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          gap={6}
          mb={2}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiary-company" size={20} />
            <Heading size="sm">Company Ownership</Heading>
          </Flex>
          {!owners.length && <Text>There are no owners</Text>}
          {owners.map((owner, index, array) => (
            <Fragment key={owner.id}>
              <Text variant="primary" fontWeight={500}>
                Owner {index + 1}
              </Text>
              <Box mt={-2}>
                <Text size="xs" mb={2}>
                  Legal First Name
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {owner.firstName}
                </Text>
              </Box>
              <Box>
                <Text size="xs" mb={2}>
                  Legal Last Name
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {owner.lastName}
                </Text>
              </Box>
              <Box>
                <Text size="xs" mb={2}>
                  Ownership Percentage
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {owner.ownershipPercentage}%
                </Text>
              </Box>
              <Box>
                <Text size="xs" mb={2}>
                  Email Address
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {owner.emailAddress}
                </Text>
              </Box>
              {index < array.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Flex>
        <Flex
          flexDirection="column"
          maxW="md"
          w="full"
          bg="white"
          rounded={4}
          border="1px"
          borderColor="borderStroke"
          p={{ base: 4, lg: 6 }}
          gap={6}
        >
          <Flex gap={2} alignItems="center">
            <Icon name="beneficiaries" size={20} />
            <Heading size="sm">Authorized Signatory</Heading>
          </Flex>
          <Box>
            <Text size="xs" mb={2}>
              Person who is authorized to sign on behalf of the company
            </Text>
            {!authorizedSignatory ? (
              <Text>There is no authorized signatory</Text>
            ) : (
              <Text variant="primary" fontWeight={500}>
                {`${authorizedSignatory.firstName} ${authorizedSignatory.lastName}`}
              </Text>
            )}
          </Box>
          {authorizedSignatory && (
            <Box>
              <Text size="xs" mb={2}>
                Title / Position
              </Text>
              <Text variant="primary" fontWeight={500}>
                {KYC.userPositionOptions.find((i) => i.value === authorizedSignatory.title)?.label ||
                  authorizedSignatory.title}
              </Text>
            </Box>
          )}
        </Flex>
      </>
    );
  };

  const renderVerificationDetails = () => {
    const users =
      kycUsers?.filter((user) =>
        [
          KYCUserIDVStatus.ACTIVE,
          KYCUserIDVStatus.NOT_STARTED,
          KYCUserIDVStatus.SUCCESS,
          KYCUserIDVStatus.FAILED,
          KYCUserIDVStatus.PENDING_REVIEW,
        ].includes(user.idvStatus),
      ) || [];

    const usersCompletedIDV = users.filter((user) => user.idvStatus === KYCUserIDVStatus.SUCCESS);
    const usersWithIncompleteIDV = users.filter((user) =>
      [KYCUserIDVStatus.NOT_STARTED, KYCUserIDVStatus.ACTIVE].includes(user.idvStatus),
    );
    const usersWithRejectedIDV = users.filter((user) => user.idvStatus === KYCUserIDVStatus.FAILED);
    const usersWithPendingIDV = users.filter((user) => user.idvStatus === KYCUserIDVStatus.PENDING_REVIEW);

    return (
      <Flex
        flexDirection="column"
        maxW="md"
        w="full"
        bg="white"
        rounded={4}
        border="1px"
        borderColor="borderStroke"
        p={{ base: 4, lg: 6 }}
        mb={2}
        gap={6}
      >
        <Flex alignItems="center" gap={2}>
          <Icon name="plaid" size={20} />
          <Text variant="primary" fontWeight={500}>
            Plaid Verification
          </Text>
          <Badge variant="numericGray">
            {usersCompletedIDV.length}/{users.length}
          </Badge>
        </Flex>
        {usersWithRejectedIDV?.length > 0 && (
          <Box>
            <Text size="sm" fontWeight={500}>
              Identity Verification Rejected
            </Text>
            {usersWithRejectedIDV.map((user, index, array) => (
              <Fragment key={user.id}>
                <Flex alignItems="center" gap={2} py={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    flex="none"
                    bg="brandRed.50"
                    rounded="36px"
                    border="1px"
                    borderColor="borderOpacity"
                    p={2}
                  >
                    <Icon name="close" size={20} color="brandRed.500" />
                  </Flex>

                  <Box>
                    <Text variant="primary" fontWeight={500}>{`${user.firstName} ${user.lastName}`}</Text>
                    <Text size="sm">{user.emailAddress}</Text>
                  </Box>
                </Flex>
                {index !== array.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Box>
        )}

        {usersCompletedIDV?.length > 0 && (
          <Box>
            <Text size="sm" fontWeight={500}>
              Identity Verification Completed
            </Text>
            {usersCompletedIDV.map((user, index, array) => (
              <Fragment key={user.id}>
                <Flex alignItems="center" gap={2} py={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    flex="none"
                    bg="brandGreen.50"
                    rounded="36px"
                    border="1px"
                    borderColor="borderOpacity"
                    p={2}
                  >
                    <Icon name="check" size={20} color="brandGreen.500" />
                  </Flex>

                  <Box>
                    <Text variant="primary" fontWeight={500}>{`${user.firstName} ${user.lastName}`}</Text>
                    <Text size="sm">{user.emailAddress}</Text>
                  </Box>
                </Flex>
                {index !== array.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Box>
        )}
        {usersWithIncompleteIDV?.length > 0 && (
          <Box>
            <Text size="sm" fontWeight={500}>
              Identity Verification Pending
            </Text>
            {usersWithIncompleteIDV.map((user, index, array) => (
              <Fragment key={user.id}>
                <Flex alignItems="center" gap={2} py={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    flex="none"
                    bg="bg.200"
                    rounded="36px"
                    border="1px"
                    borderColor="borderOpacity"
                    p={2}
                  >
                    <Icon name="pending" size={20} color="contentSecondary" />
                  </Flex>

                  <Box>
                    <Text variant="primary" fontWeight={500}>{`${user.firstName} ${user.lastName}`}</Text>
                    <Text size="sm">{user.emailAddress}</Text>
                  </Box>
                </Flex>
                {index !== array.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Box>
        )}
        {usersWithPendingIDV?.length > 0 && (
          <Box>
            <Text size="sm" fontWeight={500}>
              Identity Verification Needs Review
            </Text>
            {usersWithPendingIDV.map((user, index, array) => (
              <Fragment key={user.id}>
                <Flex alignItems="center" gap={2} py={4}>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    flex="none"
                    bg="brandYellow.50"
                    rounded="36px"
                    border="1px"
                    borderColor="borderOpacity"
                    p={2}
                  >
                    <Icon name="info" size={20} color="brandYellow.500" />
                  </Flex>

                  <Box>
                    <Text variant="primary" fontWeight={500}>{`${user.firstName} ${user.lastName}`}</Text>
                    <Text size="sm">{user.emailAddress}</Text>
                  </Box>
                </Flex>
                {index !== array.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Box>
        )}
      </Flex>
    );
  };

  const renderDeclarationDetails = () => {
    return (
      <Flex
        flexDirection="column"
        maxW="md"
        w="full"
        bg="white"
        rounded={4}
        border="1px"
        borderColor="borderStroke"
        p={{ base: 4, lg: 6 }}
        mb={2}
        gap={6}
      >
        <Flex alignItems="center" gap={2}>
          <Icon name="verification" size={20} />
          <Text variant="primary" fontWeight={500}>
            Declaration
          </Text>
        </Flex>
        {kycDeclaration && (
          <Flex alignItems="center" p={1}>
            <Box color="contentSecondary" bg="bg.100" rounded={4} mr={2}>
              <Icon name="file" size={20} />
            </Box>
            <Box flex={1}>
              <Text variant="primary" fontWeight={500}>
                {kycDeclaration.filename}
              </Text>
              <Text size="xs">{formatFileSize(kycDeclaration.fileSize)}</Text>
            </Box>
            <IconButton
              aria-label="Download file"
              variant="ghost"
              size="sm"
              onClick={() => handleDownloadFile(kycDeclaration)}
            >
              <Icon name="download" size={20} color="contentSecondary" />
            </IconButton>
          </Flex>
        )}
      </Flex>
    );
  };

  const renderContent = () => {
    switch (selectedTabIndex) {
      case 0:
        return renderContactDetails();
      case 1:
        return renderBusinessDetails();
      case 2:
        return renderLeadershipDetails();
      case 3:
        return renderOwnershipDetails();
      case 4:
        return renderVerificationDetails();
      case 5:
        return renderDeclarationDetails();
    }
  };

  return (
    <>
      <Flex alignItems="center" pt={4} pb={2} gap={4} px={{ base: 4, lg: 6 }} bg="white">
        <IconButton aria-label="Go back" as={RRLink} to={routes.kycList} variant="ghost" size="xs">
          <Icon name="arrow-left" size={24} color="brand.500" />
        </IconButton>
        <Heading>{kycDetails.companyName}</Heading>
        {kycDetails.kycStatus && (
          <Badge variant="status" colorScheme={KYCStatusBadgeMapping[kycDetails.kycStatus].color}>
            <Icon name={KYCStatusBadgeMapping[kycDetails.kycStatus].icon} size={18} />
            {KYCStatusBadgeMapping[kycDetails.kycStatus].label}
          </Badge>
        )}
      </Flex>
      <TabBar index={selectedTabIndex} onChange={setSelectedTabIndex}>
        <Tab>Contact</Tab>
        <Tab>Business</Tab>
        <Tab>Leadership</Tab>
        <Tab>Ownership</Tab>
        <Tab>Verification</Tab>
        <Tab>Declaration</Tab>
      </TabBar>
      <Flex flexDirection="column" alignItems="center" py={{ base: 4, lg: 6 }} px={4}>
        {renderContent()}
      </Flex>
    </>
  );
};
