export const Checkbox = {
  baseStyle: {
    control: {
      borderColor: 'contentSecondary',
      _active: {
        borderColor: 'brand.600',
      },

      _disabled: {
        bg: 'bg.200',
        borderColor: 'bg.200',
      },

      _readOnly: {
        bg: 'bg.200',
        borderColor: 'bg.200',
        color: 'brand.500',
        _hover: {
          bg: 'bg.200',
          borderColor: 'bg.200',
        },
        _active: {
          bg: 'bg.200',
          borderColor: 'bg.200',
        },
      },

      _checked: {
        '@media(hover: hover)': {
          _hover: {
            borderColor: 'brand.400',
            bg: 'brand.400',
          },
        },
        _active: {
          borderColor: 'brand.600',
          bg: 'brand.600',
        },
      },
    },
  },
  defaultProps: {
    colorScheme: 'brand',
  },
};
