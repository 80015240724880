import { type currencies } from './constants';

export const enum ERPPlatform {
  NETSUITE = 'NETSUITE',
  SAGE_INTACCT = 'SAGE_INTACCT',
  DYNAMICS365 = 'DYNAMICS365',
  SAGE_BUSINESS_CLOUD = 'SAGE_BUSINESS_CLOUD',
  XERO = 'XERO',
  QUICKBOOKS = 'QUICKBOOKS',
}

export type Currency = (typeof currencies)[number];

export enum AppEnv {
  local = 'local',
  stg = 'stg',
  prod = 'prod',
  dev = 'dev',
  dev2 = 'dev2',
  dev3 = 'dev3',
  test = 'test',
  demo = 'demo',
}
