import { type FunctionComponent } from 'react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { Logger } from '@fin/shared';

import { SimulationTransactionSourceType, type SimulationTransactionUnionType } from '@app/types';

import {
  ConversionTransactionItem,
  CreditCardTransactionItem,
  DDDTransactionItem,
  DepositTransactionItem,
  FeeTransactionItem,
  FundsReceiptTransactionItem,
  PaymentTransactionItem,
  PayoutTransactionItem,
  TransferTransactionItem,
  WithdrawalTransactionItem,
} from './TransactionItems';

export const Transaction = ({
  transaction,
  as,
  isLoading,
}: {
  transaction: SimulationTransactionUnionType;
  as?: FunctionComponent<TransactionItemProps>;
  isLoading?: boolean;
}) => {
  if (isLoading) return <TransactionItem isLoading />;

  switch (transaction.sourceType) {
    case SimulationTransactionSourceType.CONVERSION:
      return <ConversionTransactionItem transaction={transaction} as={as} />;
    case SimulationTransactionSourceType.PAYMENT:
      return <PaymentTransactionItem transaction={transaction} as={as} />;
    case SimulationTransactionSourceType.PAYOUT:
      return <PayoutTransactionItem transaction={transaction} as={as} />;
    case SimulationTransactionSourceType.WITHDRAWAL:
      return <WithdrawalTransactionItem transaction={transaction} as={as} />;
    case SimulationTransactionSourceType.DEPOSIT:
      return <DepositTransactionItem transaction={transaction} as={as} />;
    case SimulationTransactionSourceType.DIRECT_DEBIT_DEPOSIT:
      return <DDDTransactionItem transaction={transaction} as={as} />;
    case SimulationTransactionSourceType.FUNDS_RECEIPT:
      return <FundsReceiptTransactionItem transaction={transaction} as={as} />;
    case SimulationTransactionSourceType.CARD_PURCHASE:
    case SimulationTransactionSourceType.CARD_REFUND:
      return <CreditCardTransactionItem transaction={transaction} as={as} />;
    case SimulationTransactionSourceType.TRANSFER:
      return <TransferTransactionItem transaction={transaction} as={as} />;
    case SimulationTransactionSourceType.FEE:
      return <FeeTransactionItem transaction={transaction} as={as} />;

    default:
      Logger.error(`missed transaction type: ${JSON.stringify(transaction)}`);
      return null;
  }
};
