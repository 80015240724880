import React, { useMemo, useState } from 'react';

import { Badge, Button, Flex, Heading, Text } from '@chakra-ui/react';

import { parseAsString } from 'nuqs';
import { useParams } from 'react-router-dom';

import { ClipText, SearchBar, Table, useEntitiesSort, useSearchFilters } from '@fin/components';
import { Icon } from '@fin/icons';
import { BeneficiaryApprovalStatusLabels, BeneficiaryEntityType, beneficiaryStatusBadgeMapping } from '@fin/types';
import { countries, getBeneficiaryName, handleApiError, showNotification } from '@fin/utils';

import { useBusinessBeneficiaries } from '@app/hooks';
import { SimulationService } from '@app/services/simulation';

const searchParams = {
  search: parseAsString.withDefault(''),
};

export const DemoToolBeneficiaries = () => {
  const params = useParams<{ kycId: string; businessId: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { beneficiaries, beneficiariesIsLoading, mutateBeneficiaries } = useBusinessBeneficiaries(params?.businessId);

  const { filters, handleFilterChange, isMatchingString } = useSearchFilters(searchParams);

  const hasSomeFilter = !!Object.values(filters).filter(Boolean).length;

  const filteredBeneficiaries = useMemo(() => {
    if (!beneficiaries) return [];

    return beneficiaries.filter((item) => isMatchingString('search', getBeneficiaryName(item)));
  }, [isMatchingString, beneficiaries]);

  const { sortedEntities, changeSortBy, sortBy } = useEntitiesSort(filteredBeneficiaries, '_beneficiaryName', {
    _beneficiaryName: (item) => getBeneficiaryName(item),
    _vendorCountry: (item) => countries[item.vendorCountry],
  });

  const loadBeneficiaries = async () => {
    if (!params?.businessId) return;

    try {
      setIsSubmitting(true);
      await SimulationService.loadBeneficiaries(params.businessId);
      await mutateBeneficiaries();

      showNotification({ title: 'Success', description: 'Beneficiaries loaded', status: 'success' });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Heading mb={4}>Beneficiaries</Heading>
      <Flex mb={3} gap={2}>
        <SearchBar
          width="full"
          value={filters.search || ''}
          onChange={handleFilterChange('search')}
          placeHolder="Search beneficiary by name"
        />
        <Button
          size="sm"
          leftIcon={<Icon name="add" size={18} />}
          onClick={loadBeneficiaries}
          isLoading={isSubmitting}
          loadingText="Loading..."
        >
          Load Beneficiaries
        </Button>
      </Flex>

      <Table
        templateColumns="1fr 200px 200px"
        entities={sortedEntities}
        keyProp="id"
        columns={[
          {
            title: 'Beneficiary',
            icon: 'beneficiary',
            field: '_beneficiaryName',
            renderCell: (item) => (
              <Flex gap={2} alignItems="center">
                <Text display={{ base: 'none', md: 'block' }}>
                  <Icon
                    name={
                      item.beneficiaryEntityType === BeneficiaryEntityType.COMPANY
                        ? 'beneficiary-company'
                        : 'beneficiary-individual'
                    }
                    size={16}
                  />
                </Text>
                <ClipText
                  maxLength={30}
                  variant="primary"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {getBeneficiaryName(item)}
                </ClipText>
              </Flex>
            ),
            cellProps: {
              overflow: { base: 'hidden', sm: 'initial' },
            },
            keepOnMobile: true,
          },
          {
            title: 'Country',
            icon: 'location',
            field: '_vendorCountry',
            renderCell: (item) => countries[item.vendorCountry],
          },
          {
            title: 'Status',
            icon: 'status',
            renderCell: (item) => (
              <Badge variant="status" colorScheme={beneficiaryStatusBadgeMapping[item.approvalStatus].color}>
                <Icon name={beneficiaryStatusBadgeMapping[item.approvalStatus].icon} size={18} />
                {BeneficiaryApprovalStatusLabels[item.approvalStatus]}
              </Badge>
            ),
          },
        ]}
        loading={beneficiariesIsLoading}
        sortBy={sortBy}
        onChangeSort={changeSortBy}
        emptyText={
          hasSomeFilter ? 'There are no beneficiaries matching your search criteria' : 'There are no beneficiaries'
        }
      />
    </>
  );
};
