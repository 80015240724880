import { FormControl, FormLabel, Input } from '@chakra-ui/react';

import { usePlaceAutocomplete, type UsePlaceAutocompleteProps } from './usePlaceAutocomplete';

type PlaceAutocompleteProps<T extends Record<string, string | null | undefined>> = {
  label: string;
  placeholder: string;
} & UsePlaceAutocompleteProps<T>;

export const PlaceAutocomplete = <T extends Record<string, string | null | undefined>>(
  props: PlaceAutocompleteProps<T>,
) => {
  const { ref } = usePlaceAutocomplete<T>({
    country: props.country,
    onSelectAddress: props.onSelectAddress,
    addressMapping: props.addressMapping,
    getFieldSchema: props.getFieldSchema,
  });

  return (
    <FormControl id="address">
      <FormLabel>{props.label}</FormLabel>
      <Input type="text" placeholder={props.placeholder} ref={ref} />
    </FormControl>
  );
};
