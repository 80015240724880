import { Drawer, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Heading, IconButton } from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Icon } from '@fin/icons';
import { modalProps } from '@fin/utils';

import type { FundsReceiptSimulationTransaction } from '@app/types';

import { FundsReceiptTransactionDetailsContent } from './FundsReceiptTransactionDetailsContent';

export const FundsReceiptTransactionDetails = NiceModal.create<{
  transaction: FundsReceiptSimulationTransaction;
}>(({ transaction }) => {
  const modal = useModal();

  return (
    <Drawer {...modalProps(modal)} placement="right" size="md">
      <DrawerOverlay />

      <DrawerContent>
        <DrawerHeader as={Flex} px={4} py={3} alignItems="center" borderBottom="1px" borderBottomColor="borderStroke">
          <Heading mr="auto">Funds Receipt</Heading>
          <IconButton aria-label="Close Details" size="sm" variant="ghost" onClick={modal.hide} color="contentTertiary">
            <Icon name="close" size={24} />
          </IconButton>
        </DrawerHeader>
        <FundsReceiptTransactionDetailsContent transaction={transaction} />
      </DrawerContent>
    </Drawer>
  );
});
