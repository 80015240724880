import { type ComponentStyleConfig, cssVar, defineStyleConfig } from '@chakra-ui/react';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

export const Skeleton: ComponentStyleConfig = defineStyleConfig({
  baseStyle: {
    [$startColor.variable]: 'colors.borderStroke',
    [$endColor.variable]: 'colors.brand.100',
  },
});
