import { create } from 'zustand';

import { sidebarCollapsed } from '@fin/utils';

type SidebarState = {
  isExpanded: boolean;
  isMobileOpened: boolean;
  toggle: () => void;
  openMobile: () => void;
  closeMobile: () => void;
};

export const useSidebarStore = create<SidebarState>((set) => ({
  isExpanded: localStorage.getItem(sidebarCollapsed) ? localStorage.getItem(sidebarCollapsed) === 'true' : true,
  toggle: () =>
    set((v) => {
      localStorage.setItem(sidebarCollapsed, String(!v.isExpanded));
      return { isExpanded: !v.isExpanded };
    }),
  isMobileOpened: false,
  openMobile: () => set({ isMobileOpened: true }),
  closeMobile: () => set({ isMobileOpened: false }),
}));
