import { type IconNames } from '@fin/icons';
import type { Currency } from '@fin/shared';

import type { BeneficiaryType } from './beneficiary';
import { type BillType } from './bill';

export enum PaymentStatus {
  unpaid = 'UNPAID',
  paid = 'PAID',
  inProgress = 'IN_PROGRESS',
  inReview = 'IN_REVIEW',
  failed = 'FAILED',
  cancelled = 'CANCELLED',
}

export type Conversion = {
  amount: number;
  baseCurrency: Currency;
  buyCurrency: Currency;
  clientBuyAmount: number;
  clientRate: number;
  clientSellAmount: number;
  fixedSide: 'buy' | 'sell';
  sellCurrency: Currency;
  conversionDate: string;
  id: string;
};

export type Payment = {
  amount: number;
  approvalStatus: string;
  beneficiaryId: BeneficiaryType['id'];
  businessId: string;
  currency: Currency;
  currencyWallet: Currency;
  conversion?: Conversion;
  description: string | null;
  id: string;
  apBills: Record<number, BillType> | null;
  paymentDate: string;
  properties: unknown;
  status: PaymentStatus;
  paymentReason: PaymentsReasons | null;
};

export const paymentStatusBadgeMapping: Record<PaymentStatus, { color: string; icon: IconNames }> = {
  [PaymentStatus.paid]: { color: 'gray', icon: 'check' },
  [PaymentStatus.unpaid]: { color: 'yellow', icon: 'error' },
  [PaymentStatus.inProgress]: { color: 'blue', icon: 'pending' },
  [PaymentStatus.inReview]: { color: 'blue', icon: 'pending' },
  [PaymentStatus.failed]: { color: 'red', icon: 'error' },
  [PaymentStatus.cancelled]: { color: 'red', icon: 'error' },
};

export const paymentStatusLabels: Record<PaymentStatus, string> = {
  [PaymentStatus.paid]: 'Paid',
  [PaymentStatus.unpaid]: 'Unpaid',
  [PaymentStatus.inProgress]: 'In Progress',
  [PaymentStatus.inReview]: 'In Review',
  [PaymentStatus.failed]: 'Unsuccessful',
  [PaymentStatus.cancelled]: 'Cancelled',
};

export type PaymentsCreateForm = {
  beneficiaryId: BeneficiaryType['id'] | null;
  reason: PaymentsReasons | null;
  description: string;
  amount: number;
  paymentMethod: Currency | null;
  billsIds: Array<BillType['id']> | undefined;
};

export enum PaymentsReasons {
  BILL_PAYMENT = 'BILL_PAYMENT',

  AUDIO_VISUAL_SERVICES = 'AUDIO_VISUAL_SERVICES',
  BUSINESS_EXPENSES = 'BUSINESS_EXPENSES',
  CONSTRUCTION = 'CONSTRUCTION',
  DONATION_CHARITABLE_CONTRIBUTION = 'DONATION_CHARITABLE_CONTRIBUTION',
  EDUCATION_TRAINING = 'EDUCATION_TRAINING',
  FREIGHT = 'FREIGHT',
  GOODS_PURCHASED = 'GOODS_PURCHASED',
  INVESTMENT_CAPITAL = 'INVESTMENT_CAPITAL',
  INVESTMENT_PROCEEDS = 'INVESTMENT_PROCEEDS',
  LOAN_CREDIT_REPAYMENT = 'LOAN_CREDIT_REPAYMENT',
  PROFESSIONAL_BUSINESS_SERVICES = 'PROFESSIONAL_BUSINESS_SERVICES',
  REAL_ESTATE = 'REAL_ESTATE',
  TAXES = 'TAXES',
  TECHNICAL_SERVICES = 'TECHNICAL_SERVICES',
  TRANSFER_TO_OWN_ACCOUNT = 'TRANSFER_TO_OWN_ACCOUNT',
  TRAVEL = 'TRAVEL',
  WAGES_SALARY = 'WAGES_SALARY',
  OTHER_SERVICES = 'OTHER_SERVICES',
}

export type SendMoneyReasons = Exclude<PaymentsReasons, PaymentsReasons.BILL_PAYMENT>;

export const sendMoneyReasonsLabels: Record<SendMoneyReasons, string> = {
  [PaymentsReasons.AUDIO_VISUAL_SERVICES]: 'Audiovisual services',
  [PaymentsReasons.BUSINESS_EXPENSES]: 'Business expenses',
  [PaymentsReasons.CONSTRUCTION]: 'Construction',
  [PaymentsReasons.DONATION_CHARITABLE_CONTRIBUTION]: 'Donation / charitable contribution',
  [PaymentsReasons.EDUCATION_TRAINING]: 'Education / training',
  [PaymentsReasons.FREIGHT]: 'Freight',
  [PaymentsReasons.GOODS_PURCHASED]: 'Goods purchased',
  [PaymentsReasons.INVESTMENT_CAPITAL]: 'Investment capital',
  [PaymentsReasons.INVESTMENT_PROCEEDS]: 'Investment proceeds',
  [PaymentsReasons.LOAN_CREDIT_REPAYMENT]: 'Loan / credit repayment',
  [PaymentsReasons.PROFESSIONAL_BUSINESS_SERVICES]: 'Professional / Business Services',
  [PaymentsReasons.REAL_ESTATE]: 'Real Estate',
  [PaymentsReasons.TAXES]: 'Taxes',
  [PaymentsReasons.TECHNICAL_SERVICES]: 'Technical Services',
  [PaymentsReasons.TRANSFER_TO_OWN_ACCOUNT]: 'Transfer to own account',
  [PaymentsReasons.TRAVEL]: 'Travel',
  [PaymentsReasons.WAGES_SALARY]: 'Wages / salary',
  [PaymentsReasons.OTHER_SERVICES]: 'Other Services',
};

export type SendMoneyFormType = {
  beneficiaryId: BeneficiaryType['id'] | null;
  reason: SendMoneyReasons | null;
  description: string;
  amount: number;
  paymentMethod: Currency | null;
  sendConfirmationEmail: boolean;
};
