import { countriesWithStates } from './countries';

export const getStates = (country: string | undefined) => {
  if (!country) return [];

  const states = countriesWithStates[country]?.states;
  if (!states) return [];

  return states
    .map((state) => ({
      label: state.name,
      value: state.value,
      code: state.code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getStateShortName = (country: string | undefined, stateName: string | undefined) => {
  if (!country || !stateName) return undefined;

  const states = getStates(country);

  return states.find(
    (state) =>
      stateName === state.label ||
      stateName === state.code ||
      stateName.replaceAll(' ', '').toLowerCase() === state.label.replaceAll(' ', '').toLowerCase(),
  )?.value;
};
