import { type ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '../colors';

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    bg: `${colors.brand['500']}e5`,
    rounded: 4,
    px: 2,
    py: 1.5,
    maxW: 64,
  },
};
