import React from 'react';

import {
  Badge,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
} from '@chakra-ui/react';

import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { type NumberFormatValues, NumericFormat } from 'react-number-format';
import type { KeyedMutator } from 'swr';

import { Select } from '@fin/components';
import { type CompanyInfoType } from '@fin/types';
import { handleApiError, showNotification } from '@fin/utils';

import { useBusinessAccounts } from '@app/hooks';
import { type SimulateFundsReceiptFormType, SimulationService } from '@app/services/simulation';

type SimulateFundsReceiptFormProps = {
  businessId: CompanyInfoType['id'];
  mutateTransactions: KeyedMutator<any>;
  close: () => void;
};

export const SimulateFundsReceiptForm = ({ businessId, mutateTransactions, close }: SimulateFundsReceiptFormProps) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<SimulateFundsReceiptFormType>({});

  const { accounts } = useBusinessAccounts(businessId);

  const currency = watch('currency');

  const onSubmit: SubmitHandler<SimulateFundsReceiptFormType> = async (form) => {
    try {
      await SimulationService.fundsReceipt(businessId, form);
      await mutateTransactions();
      close();

      showNotification({ title: 'Success', description: 'Funds receipt simulated', status: 'success' });
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <Flex flexDirection="column" gap={4} as="form" onSubmit={handleSubmit(onSubmit)} noValidate flex={1}>
      <Controller
        control={control}
        name="currency"
        rules={{ required: 'This is required' }}
        render={({ field, fieldState }) => (
          <FormControl id="currency" isInvalid={fieldState.invalid}>
            <FormLabel>Account to Send Funds to</FormLabel>
            <Select options={accounts?.map((i) => ({ label: `${i} Account`, value: i })) || []} {...field} />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="amount"
        rules={{ required: 'This is required' }}
        render={({ field: { onChange, value, name, ref }, fieldState }) => (
          <FormControl id="amount" isInvalid={fieldState.invalid} zIndex={1}>
            <FormLabel>Amount</FormLabel>
            <InputGroup>
              <Input
                name={name}
                placeholder="Enter amount"
                as={NumericFormat}
                thousandSeparator=","
                isAllowed={(v: NumberFormatValues) => !/\.(\d{3,})/.test(v.value)}
                value={value}
                onValueChange={(v: NumberFormatValues) => onChange(v.floatValue!)}
                getInputRef={ref}
              />
              {currency && (
                <InputRightElement mr={4}>
                  <Badge variant="currency">{currency}</Badge>
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      <FormControl isInvalid={!!errors?.payerName?.message}>
        <FormLabel>
          Payer Name
          <Text as="span" size="xs" ml={1}>
            (Optional)
          </Text>
        </FormLabel>
        <Input type="text" placeholder="Enter payer name" {...register('payerName')} />
        <FormErrorMessage>{errors?.payerName?.message}</FormErrorMessage>
      </FormControl>

      <FormControl id="reference" isInvalid={!!errors?.reference?.message}>
        <FormLabel>
          Description
          <Text as="span" size="xs" ml={1}>
            (Optional)
          </Text>
        </FormLabel>
        <Textarea placeholder="Enter description" {...register('reference')} />
        <FormErrorMessage>{errors?.reference?.message}</FormErrorMessage>
      </FormControl>
      <Button width="full" mt="auto" type="submit" isLoading={isSubmitting} loadingText="Loading...">
        Simulate Transaction
      </Button>
    </Flex>
  );
};
