import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Table: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    simple: {
      th: {
        color: 'contentSecondary',
        fontSize: 13,
        textTransform: 'unset',
        p: 2,
        fontWeight: 500,
        '+ th': {
          borderLeft: '1px solid',
          borderLeftColor: 'borderStroke',
        },
      },
      td: {
        px: 2,
        py: 3,
        fontWeight: 400,
        '+ td': {
          borderLeft: '1px solid',
          borderLeftColor: 'borderStroke',
        },
      },
      tbody: {
        'tr:last-child td': {
          borderBottom: 0,
        },
      },
    },
  },
  defaultProps: {},
};
