import { AxiosError } from 'axios';

import { Logger } from '@fin/shared';

import { showNotification } from './notification';

export type FieldError = { errorCode: number; message: string; name: string };

export class ApiError extends AxiosError {
  fieldErrors: FieldError[];
  constructor(error: AxiosError<any>) {
    super(error.message, error.code, error.config, error.request, error.response);
    this.fieldErrors = error.response?.data.errors ?? [];
  }
}

export const handleApiError = (error: any) => {
  if (error instanceof ApiError) {
    error.fieldErrors.forEach((error) => {
      showNotification({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 4000,
      });
    });
  } else {
    Logger.error(error);
    showNotification({
      title: 'Error',
      description: 'Something went wrong, please try again later',
      status: 'error',
    });
  }
};
