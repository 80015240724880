import type { FunctionComponent } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';
import { useParams } from 'react-router-dom';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { formatPrice, getBeneficiaryName } from '@fin/utils';

import { useBusinessBeneficiaries } from '@app/hooks';
import type { TransferSimulationTransaction } from '@app/types';

import { TransferTransactionDetails } from '../TransactionDetails/TransferTransactionDetails';

export const TransferTransactionItem = ({
  transaction,
  as,
}: {
  transaction: TransferSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const params = useParams<{ businessId: string }>();
  const { getBeneficiaryById } = useBusinessBeneficiaries(params?.businessId);

  const { transactionType, amount, currency, relatedEntity } = transaction;

  const TransactionItemComponent = as || TransactionItem;

  const isOutbound = transactionType === 'DC_DEBIT';

  const title = isOutbound
    ? `Outbound Real-Time Transfer to ${getBeneficiaryName(getBeneficiaryById(relatedEntity.beneficiaryId))}`
    : `Inbound Real-Time Transfer from ${relatedEntity.businessName}`;

  return (
    <TransactionItemComponent
      icon={isOutbound ? 'transactionOutboundTransfer' : 'transactionInboundTransfer'}
      title={<Text variant="primary">{title}</Text>}
      amount={
        <Flex alignItems="center" gap={1}>
          <Text size="inherit" variant="number" color={isOutbound ? 'inherit' : 'brandGreen.500'}>{`${
            isOutbound ? '-' : '+'
          }${formatPrice(Math.abs(amount))}`}</Text>
          <Badge variant="textual">{currency}</Badge>
        </Flex>
      }
      onClick={() =>
        NiceModal.show(TransferTransactionDetails, { transfer: relatedEntity, businessId: params?.businessId })
      }
    />
  );
};
