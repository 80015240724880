import type { FunctionComponent } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { Icon } from '@fin/icons';
import { formatPrice } from '@fin/utils';

import type { ConversionSimulationTransaction } from '@app/types';

import { ConversionTransactionDetails } from '../TransactionDetails/ConversionTransactionDetails';

export const ConversionTransactionItem = ({
  transaction,
  as,
}: {
  transaction: ConversionSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const { amount, currency, relatedEntity } = transaction;
  const hasEntity = relatedEntity && Object.keys(relatedEntity).length > 0;

  const TransactionItemComponent = as || TransactionItem;

  return (
    <TransactionItemComponent
      icon="transactionConvert"
      title={
        <Text variant="primary">
          {hasEntity
            ? `Currency Conversion ${relatedEntity.sellCurrency} to ${relatedEntity.buyCurrency}`
            : 'Currency Conversion'}
        </Text>
      }
      amount={
        hasEntity ? (
          <Text size="inherit" variant="number" color="inherit" as={Flex} alignItems="center" gap={1}>
            <Flex alignItems="center" gap={1}>
              <Text size="inherit" color="inherit">{`-${formatPrice(relatedEntity.clientSellAmount)}`}</Text>
              <Badge variant="textual">{relatedEntity.sellCurrency}</Badge>
            </Flex>
            <Icon name="arrow-right" size={16} />

            <Flex alignItems="center" gap={1}>
              <Text size="inherit" color="brandGreen.500">{`+${formatPrice(+relatedEntity.clientBuyAmount)}`}</Text>
              <Badge variant="textual">{relatedEntity.buyCurrency}</Badge>
            </Flex>
          </Text>
        ) : (
          <Flex alignItems="center" gap={1}>
            <Text size="inherit" variant="number" color={amount < 0 ? 'inherit' : 'brandGreen.500'}>{`${
              amount < 0 ? '-' : '+'
            }${formatPrice(Math.abs(amount))}`}</Text>
            <Badge variant="textual">{currency}</Badge>
          </Flex>
        )
      }
      onClick={
        Object.keys(relatedEntity).length
          ? () => NiceModal.show(ConversionTransactionDetails, { transaction })
          : undefined
      }
    />
  );
};
