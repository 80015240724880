import React from 'react';

import { Button, Flex, Heading, Text } from '@chakra-ui/react';

import { useAuth } from '@clerk/clerk-react';

import { OrganizationSwitcher } from '@fin/components';

import { routes } from '@app/routing/routes';

type NotFoundProps = {
  showLogout?: boolean;
};

export const NotFound = ({ showLogout = false }: NotFoundProps) => {
  const auth = useAuth();
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" h="100vh" textAlign="center">
      <Heading fontSize={100}>404</Heading>
      <Text fontSize={50} mb={4}>
        Page not found
      </Text>
      {showLogout && (
        <>
          <Flex
            gap={2}
            p={2}
            justifyContent="space-between"
            alignItems="center"
            rounded={4}
            border="1px"
            borderColor="borderStroke"
            mb={4}
          >
            <OrganizationSwitcher afterSelectOrganizationUrl={routes.home} afterLeaveOrganizationUrl={routes.home} />
          </Flex>
          <Button size="sm" variant="secondary" onClick={() => auth.signOut()}>
            Log out
          </Button>
        </>
      )}
    </Flex>
  );
};
