import { useCallback, useMemo } from 'react';

import { Badge, Box, Flex, Heading, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';
import { parseAsBoolean, parseAsString } from 'nuqs';

import { SearchBar, Select, Table, useEntitiesSort, useSearchFilters } from '@fin/components';
import { Icon } from '@fin/icons';
import { type CompanyInfoType, CompanyStatus } from '@fin/types';
import { formatPrice } from '@fin/utils';

import { useBusinesses, useCCConfigs, useDDConfigs } from '@app/hooks';

import { ConfigDetails } from './ConfigDetails';

const statusOptions: Array<{ label: string; value: boolean | null }> = [
  { label: 'All', value: null },
  { label: 'Enabled', value: true },
  { label: 'Not Enabled', value: false },
];

const globalLimitOptions: Array<{ label: string; value: boolean | null }> = [
  { label: 'All', value: null },
  { label: 'Outside', value: true },
  { label: 'Inside', value: false },
];

const searchParams = {
  search: parseAsString.withDefault(''),
  DDStatus: parseAsBoolean,
  globalLimit: parseAsBoolean,
  CCStatus: parseAsBoolean,
};

export const Config = () => {
  const { businesses, businessesIsLoading } = useBusinesses();
  const { DDConfigs, DDConfigsIsLoading } = useDDConfigs();
  const { CCConfigs, CCConfigsIsLoading } = useCCConfigs();

  const { filters, handleFilterChange, isMatchingString, isExactMatch } = useSearchFilters(searchParams);

  const hasSomeFilter = !!Object.values(filters).filter(Boolean).length;

  const getDDConfig = useCallback(
    (businessesId: CompanyInfoType['id']) => {
      if (!DDConfigs) return undefined;

      return DDConfigs.find((config) => config.businessId === businessesId);
    },
    [DDConfigs],
  );

  const getCCConfig = useCallback(
    (businessesId: CompanyInfoType['id']) => {
      if (!CCConfigs) return undefined;

      return CCConfigs.find((config) => config.businessId === businessesId);
    },
    [CCConfigs],
  );

  const filteredBusinesses = useMemo(() => {
    if (!businesses) return [];

    return businesses.filter((business) => {
      const DDConfig = getDDConfig(business.id);
      const CCConfig = getCCConfig(business.id);

      return (
        business.status === CompanyStatus.ACTIVE &&
        isMatchingString('search', business.companyName) &&
        isExactMatch('DDStatus', DDConfig?.enabled) &&
        isExactMatch('globalLimit', DDConfig?.rule.outsideGlobalLimits) &&
        isExactMatch('CCStatus', CCConfig?.enabled)
      );
    });
  }, [businesses, getDDConfig, getCCConfig, isMatchingString, isExactMatch]);

  const { sortedEntities, changeSortBy, sortBy } = useEntitiesSort(filteredBusinesses, 'id', {});

  const renderContent = () => (
    <Table
      templateColumns="minmax(max-content, 1fr) repeat(4, minmax(max-content, 190px))"
      keyProp="id"
      entities={sortedEntities}
      sortBy={sortBy}
      onChangeSort={changeSortBy}
      rowProps={(item) => ({
        _hover: { '> .cell': { bg: 'bg.100', cursor: 'pointer' } },
        onClick: () => {
          const DDConfig = getDDConfig(item.id);
          const CCConfig = getCCConfig(item.id);
          if (!DDConfig || !CCConfig) return null;

          NiceModal.show(ConfigDetails, { business: item, DDConfig, CCConfig });
        },
      })}
      loading={businessesIsLoading || DDConfigsIsLoading || CCConfigsIsLoading}
      columns={[
        {
          title: 'Company Name',
          field: 'companyName',
          keepOnMobile: true,
          renderCell: (item) => <Text variant="primary">{item.companyName}</Text>,
        },
        {
          title: 'Direct Debit Daily Limit',
          renderCell: (item) => {
            const DDConfig = getDDConfig(item.id);
            if (!DDConfig) return null;
            return <Text variant="primary">{formatPrice(DDConfig.effectiveConfiguration.dailyTransactionLimit)}</Text>;
          },
        },
        {
          title: 'In/Out of Global Limit',
          renderCell: (item) => {
            const DDConfig = getDDConfig(item.id);
            if (!DDConfig) return null;
            return (
              <Badge variant="status" colorScheme={DDConfig.rule.outsideGlobalLimits ? 'blue' : 'gray'}>
                <Icon name={DDConfig.rule.outsideGlobalLimits ? 'outside' : 'inside'} size={18} />
                {DDConfig.rule.outsideGlobalLimits ? 'Outside' : 'Inside'}
              </Badge>
            );
          },
        },
        {
          title: 'Direct Debit Status',
          renderCell: (item) => {
            const DDConfig = getDDConfig(item.id);
            if (!DDConfig) return null;
            return (
              <Badge variant="status" colorScheme={DDConfig.enabled ? 'green' : 'gray'}>
                <Icon name={DDConfig.enabled ? 'check' : 'error'} size={18} />
                {DDConfig.enabled ? 'Enabled' : 'Not Enabled'}
              </Badge>
            );
          },
        },
        {
          title: 'Credit Card Status',
          renderCell: (item) => {
            const CCConfig = getCCConfig(item.id);
            if (!CCConfig) return null;
            return (
              <Badge variant="status" colorScheme={CCConfig.enabled ? 'green' : 'gray'}>
                <Icon name={CCConfig.enabled ? 'check' : 'error'} size={18} />
                {CCConfig.enabled ? 'Enabled' : 'Not Enabled'}
              </Badge>
            );
          },
        },
      ]}
      emptyText={hasSomeFilter ? 'There are no businesses matching your search criteria' : 'There are no businesses'}
    />
  );

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={4}
        px={{ base: 4, lg: 6 }}
        bg="white"
        borderBottom="1px"
        borderBottomColor="borderStroke"
      >
        <Heading>Config</Heading>
      </Flex>
      <Box py={4} px={{ base: 4, lg: 6 }}>
        <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems="center" gap={2} mb={3}>
          <SearchBar
            value={filters.search || ''}
            onChange={handleFilterChange('search')}
            placeHolder="Search by company name or member name"
            hideOnMobile={false}
            w="full"
          />
          <Box minW={{ base: 'full', md: '270px' }} flex={0}>
            <Select
              size="sm"
              options={globalLimitOptions}
              isEmptyAllowed
              value={filters.globalLimit}
              onChange={handleFilterChange('globalLimit')}
              labelInside="In/Out of Global Limit:"
            />
          </Box>
          <Box minW={{ base: 'full', md: '270px' }} flex={0}>
            <Select
              size="sm"
              options={statusOptions}
              isEmptyAllowed
              value={filters.DDStatus}
              onChange={handleFilterChange('DDStatus')}
              labelInside="Direct Debit Status:"
            />
          </Box>
          <Box minW={{ base: 'full', md: '270px' }} flex={0}>
            <Select
              size="sm"
              options={statusOptions}
              isEmptyAllowed
              value={filters.CCStatus}
              onChange={handleFilterChange('CCStatus')}
              labelInside="Credit Cards Status:"
            />
          </Box>
        </Flex>
        {renderContent()}
      </Box>
    </>
  );
};
