import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Progress: ComponentStyleConfig = {
  baseStyle: {
    track: {
      rounded: 8,
      background: 'borderStroke',
    },
  },
  defaultProps: {
    colorScheme: 'brand',
  },
};
