import React from 'react';

import {
  Badge,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';

import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { type NumberFormatValues, NumericFormat, PatternFormat } from 'react-number-format';
import type { KeyedMutator } from 'swr';

import { Select } from '@fin/components';
import { type CompanyInfoType } from '@fin/types';
import { handleApiError, showNotification, supportedCreditCardCurrencies } from '@fin/utils';

import { useBusinessCards } from '@app/hooks';
import { type SimulateCardAuthorizationFormType, SimulationService } from '@app/services/simulation';

type SimulateCardAuthorizationFormProps = {
  businessId: CompanyInfoType['id'];
  mutateTransactions: KeyedMutator<any>;
  close: () => void;
};

export const SimulateCardAuthorizationForm = ({
  businessId,
  mutateTransactions,
  close,
}: SimulateCardAuthorizationFormProps) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<SimulateCardAuthorizationFormType>({});

  const { cards } = useBusinessCards(businessId);

  const transactionCurrency = watch('transactionCurrency');

  const onSubmit: SubmitHandler<SimulateCardAuthorizationFormType> = async (form) => {
    try {
      await SimulationService.cardAuthorization(businessId, form);
      await mutateTransactions();
      close();

      showNotification({ title: 'Success', description: 'Card authorization simulated', status: 'success' });
    } catch (e) {
      handleApiError(e);
    }
  };

  return (
    <Flex flexDirection="column" gap={4} as="form" onSubmit={handleSubmit(onSubmit)} noValidate flex={1}>
      <Controller
        control={control}
        name="cardId"
        rules={{ required: 'This is required' }}
        render={({ field, fieldState }) => (
          <FormControl id="cardId" isInvalid={fieldState.invalid}>
            <FormLabel>Credit Card</FormLabel>
            <Select
              options={
                cards?.map((card) => ({ label: `${card.nickname} (•••• ${card.lastFourDigits})`, value: card.id })) ||
                []
              }
              {...field}
            />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="transactionCurrency"
        rules={{ required: 'This is required' }}
        render={({ field, fieldState }) => (
          <FormControl id="transactionCurrency" isInvalid={fieldState.invalid}>
            <FormLabel>Currency ISO Code</FormLabel>
            <Select options={supportedCreditCardCurrencies?.map((i) => ({ label: i, value: i })) || []} {...field} />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="transactionAmount"
        rules={{ required: 'This is required' }}
        render={({ field: { onChange, value, name, ref }, fieldState }) => (
          <FormControl id="transactionAmount" isInvalid={fieldState.invalid} zIndex={1}>
            <FormLabel>Amount</FormLabel>
            <InputGroup>
              <Input
                name={name}
                placeholder="Enter amount"
                as={NumericFormat}
                thousandSeparator=","
                isAllowed={(v: NumberFormatValues) => !/\.(\d{3,})/.test(v.value)}
                value={value}
                onValueChange={(v: NumberFormatValues) => onChange(v.floatValue!)}
                getInputRef={ref}
              />
              {transactionCurrency && (
                <InputRightElement mr={4}>
                  <Badge variant="currency">{transactionCurrency}</Badge>
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      <FormControl isInvalid={!!errors?.merchantDescription?.message}>
        <FormLabel>Merchant Name</FormLabel>
        <Input type="text" placeholder="Enter merchant name" {...register('merchantDescription')} />
        <FormErrorMessage>{errors?.merchantDescription?.message}</FormErrorMessage>
      </FormControl>

      <Controller
        rules={{
          validate: (value) => {
            if (value && value.length < 4) return 'Wrong code format';
          },
        }}
        control={control}
        name="merchantCategoryCode"
        render={({ field: { onChange, value, ref }, fieldState }) => (
          <FormControl id="institutionNumber" isInvalid={fieldState.invalid}>
            <FormLabel>
              Category Code
              <Text as="span" size="xs" ml={1}>
                (Optional)
              </Text>
            </FormLabel>
            <Input
              type="text"
              placeholder="4 digits code"
              as={PatternFormat}
              format="####"
              getInputRef={ref}
              value={value}
              onValueChange={(v: NumberFormatValues) => {
                onChange(v.value || null);
              }}
            />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />

      <Button width="full" mt="auto" type="submit" isLoading={isSubmitting} loadingText="Loading...">
        Simulate Transaction
      </Button>
    </Flex>
  );
};
