import type { KYCFile } from '@fin/types';

import { api } from '@app/network';

export const KYCService = {
  getFileLink: async (kycId: string, fileId: KYCFile['id']) => {
    const res = await api.get<{ kycFile: { link: string } }>(`/admin/kyc/${kycId}/files/${fileId}?link=true`);

    return res.data?.kycFile.link;
  },
};
