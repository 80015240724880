import { type ComponentStyleConfig, theme as defaultTableTheme, type SystemStyleObject } from '@chakra-ui/react';

import { colors } from '../colors';

const numericBadgeStyles: SystemStyleObject = {
  borderRadius: 21,
  fontWeight: 400,
  px: 2,
  py: '1px',
  fontSize: '11px',
  lineHeight: '14px',
  outline: '1px solid',
  outlineColor: 'borderOpacity',
};

const statusColorMapping: Record<string, SystemStyleObject> = {
  gray: {
    background: 'brand.50',
    color: 'contentPrimary',
  },
  blue: {
    background: 'brandBlue.50',
    color: 'brandBlue.800',
  },
  yellow: {
    background: 'brandYellow.50',
    color: 'brandYellow.800',
  },
  green: {
    background: 'brandGreen.50',
    color: 'brandGreen.800',
  },
  red: {
    background: 'brandRed.50',
    color: 'brandRed.800',
  },
};

const percentageBadgeStyles: SystemStyleObject = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 500,
  gap: 1,
  px: 1.5,
  py: 0.5,
  rounded: 16,
  outline: '1px solid',
  outlineColor: 'borderOpacity',
};

export const Badge: ComponentStyleConfig = {
  variants: {
    percentageRed: {
      ...percentageBadgeStyles,
      bgColor: 'brandRed.50',
      color: 'brandRed.800',
    },
    percentageGreen: {
      ...percentageBadgeStyles,
      bgColor: 'brandGreen.50',
      color: 'brandGreen.800',
    },
    numericRed: {
      ...numericBadgeStyles,
      color: 'brandRed.800',
      background: 'brandRed.50',
    },
    numericGray: {
      ...numericBadgeStyles,
      color: 'contentPrimary',
      background: 'bg.100',
    },
    step: {
      background: 'bg.0',
      color: 'contentPrimary',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '17px',
      outline: '1px solid',
      outlineColor: 'borderStroke',
      borderRadius: 21,
      px: 2,
      py: '3px',
    },
    textual: {
      rounded: 21,
      bg: 'brand.50',
      color: 'contentPrimary',
      textTransform: 'none',
      outline: '1px solid',
      outlineColor: 'borderStroke',
      fontSize: 13,
      fontWeight: 500,
      px: 2,
      py: 0.5,
    },
    status: (props) => ({
      ...defaultTableTheme.components.Badge.variants?.solid(props),
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.3,
      py: 1,
      px: 2,
      letterSpacing: '-0.08px',
      borderRadius: 4,
      display: 'inline-flex',
      alignItems: 'center',
      gap: 2,
      border: '1px',
      borderColor: 'borderOpacity',
      ...statusColorMapping[props.colorScheme],
    }),
    currency: {
      rounded: 21,
      bg: 'bg.200',
      color: 'contentPrimary',
      textTransform: 'none',
      textAlign: 'center',
      fontSize: 13,
      fontWeight: 500,
      px: 1,
      py: 0.5,
      minW: '44px',
      boxShadow: `0 0 0 1px ${colors.borderStroke}`,
    },
    containedRect: {
      border: '1px',
      borderColor: 'borderStroke',
      color: 'contentPrimary',
      fontSize: 14,
      fontWeight: 500,
      px: 2,
      py: 1,
      rounded: 4,
      textAlign: 'center',
      textTransform: 'none',
    },
  },
  defaultProps: {
    variant: 'solid',
  },
};
