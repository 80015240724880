import { useCallback } from 'react';

import { usePlacesWidget } from 'react-google-autocomplete';

import type { AddressUnionFields, GeocoderAddressComponent } from '@fin/types';
import { getStateShortName, prettifyPostalCode } from '@fin/utils';

type Address = {
  addressLine_1: string;
  addressLine_2: string;
  state?: string;
  city: string;
  postalCode?: string;
};

export type UsePlaceAutocompleteProps<T extends Record<string, string | null | undefined>> = {
  country: string;
  onSelectAddress: (address: T) => void;
  addressMapping: Record<keyof Address, keyof T>;
  getFieldSchema?: (field: AddressUnionFields['key']) => AddressUnionFields | undefined;
};

const emptyAddress: Address = {
  addressLine_1: '',
  addressLine_2: '',
  state: '',
  city: '',
  postalCode: '',
};

export const usePlaceAutocomplete = <T extends Record<string, string | null | undefined>>({
  country,
  getFieldSchema,
  onSelectAddress,
  addressMapping,
}: UsePlaceAutocompleteProps<T>) => {
  const remapAddress = useCallback(
    (address: Address): T => {
      return Object.fromEntries(
        Object.entries(address).map(([key, value]) => [addressMapping[key as keyof Address], value]),
      ) as T;
    },
    [addressMapping],
  );

  const onPlaceSelected = useCallback(
    (place: { address_components: GeocoderAddressComponent[] }) => {
      if (!place?.address_components) return;

      const address = { ...emptyAddress };

      if (getFieldSchema && !getFieldSchema('administrative_area')) {
        delete address.state;
      }

      if (getFieldSchema && !getFieldSchema('postal_code')) {
        delete address.postalCode;
      }

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number':
            address.addressLine_1 = component.long_name;
            break;
          case 'route':
            address.addressLine_1 = `${address.addressLine_1 || ''} ${component.long_name || ''}`;
            break;
          case 'postal_code':
            if (getFieldSchema && !getFieldSchema('postal_code')) break;
            address.postalCode = prettifyPostalCode(component.long_name);
            break;
          case 'administrative_area_level_1': {
            if (getFieldSchema && !getFieldSchema('administrative_area')) break;

            let state = component.short_name;

            if (state && state.length > 2) {
              const stateShortName = getStateShortName(country, state);
              if (stateShortName) {
                state = stateShortName;
              }
            }

            address.state = state;
            break;
          }
          case 'locality':
            address.city = component.long_name;
            break;
        }
      }

      onSelectAddress(remapAddress(address));
    },
    [country, getFieldSchema, onSelectAddress, remapAddress],
  );

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    options: {
      componentRestrictions: { country: country ? [country] : [] },
      fields: ['address_components'],
      types: ['address'],
    },
    onPlaceSelected,
  });

  return { ref };
};
