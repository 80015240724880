import { useEffect, useRef } from 'react';

import { TabList, type TabListProps, Tabs, type TabsProps } from '@chakra-ui/react';

type TabBarProps = TabsProps & { tabListProps?: TabListProps };

export const TabBar = (props: TabBarProps) => {
  const { index, children, tabListProps, ...rest } = props;
  const tabList = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!tabList.current || index === undefined) return;

    const activeTab = tabList.current.querySelectorAll('[role="tab"]')[index];
    if (!activeTab) return;

    const rect = activeTab.getBoundingClientRect();

    if (rect.x < 0) {
      tabList.current.scrollLeft = tabList.current.scrollLeft - Math.abs(rect.left) - 16;
    }
    if (window.screen.width < rect.right) {
      tabList.current.scrollLeft = tabList.current.scrollLeft + rect.right - window.screen.width + 16;
    }
  }, [index]);

  return (
    <Tabs
      bg="white"
      borderBottom="1px"
      borderBottomColor="borderStroke"
      pos="sticky"
      top={0}
      zIndex={3}
      index={index}
      minH="40px"
      display="flex"
      alignItems="flex-end"
      {...rest}
    >
      <TabList
        ref={tabList}
        overflowX="auto"
        overflowY="visible"
        pb="2px"
        mb="-2px"
        pr={4}
        whiteSpace="nowrap"
        pt={2}
        px={{ base: 4, lg: 6 }}
        css={{
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        {...tabListProps}
      >
        {children}
      </TabList>
    </Tabs>
  );
};
