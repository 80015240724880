export const routes = {
  home: '/',
  login: '/login',
  impersonation: '/impersonation',
  kycList: '/kyc',
  kycDetails: '/kyc/:id',
  config: '/config',
  demoTools: '/demo-tools',
  demoToolsForBusiness: '/demo-tools/:businessId/:kycId',
  demoToolSkipKYC: '/demo-tools/:businessId/:kycId/skip-kyc',
  demoToolConnectAW: '/demo-tools/:businessId/:kycId/connect-aw',
  demoToolBeneficiaries: '/demo-tools/:businessId/:kycId/beneficiaries',
  demoToolTransactions: '/demo-tools/:businessId/:kycId/transactions',
};
