import { setUser } from '@sentry/browser';

import { type CompanyInfoType, type User } from '@fin/types';
import { swrCache } from '@fin/utils';

import { api } from '@app/network';

export const UserService = {
  logout: async () => {
    setUser(null);
    swrCache.clear();
  },
  impersonate: (userId: User['id'], businessId: CompanyInfoType['id']) =>
    api.post<{ token: string }>('/finofo_admin_impersonate_user', { userId, businessId }),
};
