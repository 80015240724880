import type { FunctionComponent } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { formatPrice } from '@fin/utils';

import type { FundsReceiptSimulationTransaction } from '@app/types';

import { FundsReceiptTransactionDetails } from '../TransactionDetails/FundsReceiptTransactionDetails';

export const FundsReceiptTransactionItem = ({
  transaction,
  as,
}: {
  transaction: FundsReceiptSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const { amount, currency, relatedEntity } = transaction;

  const TransactionItemComponent = as || TransactionItem;

  return (
    <TransactionItemComponent
      icon="transactionReceive"
      title={<Text variant="primary">{`Funds Received from ${relatedEntity.sender}`}</Text>}
      amount={
        <Flex alignItems="center" gap={1}>
          <Text size="inherit" variant="number" color="brandGreen.500">{`+${formatPrice(Math.abs(amount))}`}</Text>
          <Badge variant="textual">{currency}</Badge>
        </Flex>
      }
      onClick={() => NiceModal.show(FundsReceiptTransactionDetails, { transaction })}
    />
  );
};
