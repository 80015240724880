import React, { useEffect } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  type FlexProps,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';

import { Logo, UserButton } from '@fin/components';
import { Icon } from '@fin/icons';

import { routes } from '@app/routing/routes';
import { useSidebarStore } from '@app/stores';

import { Sidebar } from './Sidebar';

export const MobileSidebar = (props: FlexProps) => {
  const isMobileOpened = useSidebarStore((state) => state.isMobileOpened);
  const openMobile = useSidebarStore((state) => state.openMobile);
  const closeMobile = useSidebarStore((state) => state.closeMobile);
  const shouldShowMobile = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    { ssr: false },
  );

  useEffect(() => {
    if (!shouldShowMobile) {
      closeMobile();
    }
  }, [closeMobile, shouldShowMobile]);

  return (
    <>
      <Flex
        bg="white"
        pos="sticky"
        w="full"
        top={0}
        zIndex={10}
        px={2}
        minH="52px"
        borderBottom="1px"
        borderBottomColor="borderStroke"
        alignItems="center"
        {...props}
      >
        <IconButton aria-label="Open sidebar" onClick={openMobile} variant="ghost" h="40px" color="contentSecondary">
          <Icon name="menu" size={24} />
        </IconButton>
        <Flex as={Link} to={routes.home} mx="auto">
          <Logo color="brand.500" />
        </Flex>
        <UserButton />
      </Flex>
      <Drawer isOpen={isMobileOpened} onClose={closeMobile} placement="left" isFullHeight>
        <DrawerOverlay />
        <DrawerContent w="full" maxW={{ base: '100vw', sm: 60 }} maxHeight="100dvh">
          <DrawerBody p={0}>
            <Sidebar
              isExpanded
              pos="static"
              closeMobile={closeMobile}
              closeBtn={
                <IconButton
                  aria-label="Close sidebar"
                  onClick={closeMobile}
                  variant="ghost"
                  color="contentSecondary"
                  mr={1}
                >
                  <Icon name="close" size={24} />
                </IconButton>
              }
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
