import React, { useMemo, useState } from 'react';

import { Badge, Box, Flex, Heading, Spinner, Text } from '@chakra-ui/react';

import { generatePath, useNavigate } from 'react-router-dom';

import { ClipText, SearchBar, Select, Table, useEntitiesSort } from '@fin/components';
import { Icon } from '@fin/icons';
import { KYCBusinessStatus, KYCStatusBadgeMapping, KYCStatusLabels } from '@fin/types';
import { dayjs, formatDate } from '@fin/utils';

import { useKYCList } from '@app/hooks';
import { routes } from '@app/routing/routes';

type StatusOption = { label: string; value: KYCBusinessStatus };

const statusOptions: StatusOption[] = [
  { label: KYCStatusLabels[KYCBusinessStatus.APPROVED], value: KYCBusinessStatus.APPROVED },
  { label: KYCStatusLabels[KYCBusinessStatus.NOT_COMPLETED], value: KYCBusinessStatus.NOT_COMPLETED },
  { label: KYCStatusLabels[KYCBusinessStatus.IN_PROGRESS], value: KYCBusinessStatus.IN_PROGRESS },
  { label: KYCStatusLabels[KYCBusinessStatus.UNDER_VERIFICATION], value: KYCBusinessStatus.UNDER_VERIFICATION },
  { label: KYCStatusLabels[KYCBusinessStatus.WAITLISTED], value: KYCBusinessStatus.WAITLISTED },
];

export const KYCList = () => {
  const navigate = useNavigate();
  const { kycList, kycListIsLoading } = useKYCList();
  const [search, setSearch] = useState('');
  const [searchStatus, setSearchStatus] = useState<KYCBusinessStatus | null>(null);

  const filteredKYCList = useMemo(() => {
    if (!kycList) return [];

    return kycList.filter(
      (business) =>
        (!searchStatus || business.kycStatus === searchStatus) &&
        ((!search && !business.companyName) ||
          business.companyName?.toLowerCase().includes(search.trim().toLowerCase())),
    );
  }, [search, kycList, searchStatus]);

  const { sortedEntities, changeSortBy, sortBy } = useEntitiesSort(filteredKYCList, 'creationDate', {});

  const renderContent = () => (
    <Table
      templateColumns="minmax(min-content, max-content) repeat(4, minmax(max-content, 1fr))"
      keyProp="id"
      entities={sortedEntities}
      sortBy={sortBy}
      onChangeSort={changeSortBy}
      rowProps={(item) => ({
        _hover: { '> .cell': { bg: 'bg.100', cursor: 'pointer' } },
        onClick: () => navigate(generatePath(routes.kycDetails, { id: item.id })),
      })}
      columns={[
        {
          title: 'Created at',
          keepOnMobile: true,
          field: 'creationDate',
          renderCell: (item) => <Text variant="primary">{formatDate(dayjs(item.creationDate))}</Text>,
        },
        {
          title: 'Company Name',
          keepOnMobile: true,
          field: 'companyName',
          renderCell: (item) => (
            <ClipText maxLength={50} variant="primary" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              {item.companyName}
            </ClipText>
          ),
        },
        {
          title: 'Applicant Name',
          renderCell: (item) => {
            const applicant = item.applicant;
            if (!applicant) return null;

            const name = `${applicant.firstName} ${applicant.lastName}`;

            return (
              <ClipText maxLength={50} variant="primary" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                {name}
              </ClipText>
            );
          },
        },
        {
          title: 'Plaid Status',
          renderCell: (_item) => <Text variant="primary">Plaid Status</Text>,
        },
        {
          title: 'Status',
          field: 'kycStatus',
          keepOnMobile: true,
          renderCell: (item) =>
            item.kycStatus && (
              <Badge variant="status" colorScheme={KYCStatusBadgeMapping[item.kycStatus].color}>
                <Icon name={KYCStatusBadgeMapping[item.kycStatus].icon} size={18} />
                {KYCStatusBadgeMapping[item.kycStatus].label}
              </Badge>
            ),
        },
      ]}
      emptyText={
        search || searchStatus ? 'There are no businesses matching your search criteria' : 'There are no businesses'
      }
    />
  );

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        py={4}
        px={{ base: 4, lg: 6 }}
        bg="white"
        borderBottom="1px"
        borderBottomColor="borderStroke"
      >
        <Heading>KYC</Heading>
      </Flex>
      <Box py={4} px={{ base: 4, lg: 6 }}>
        <SearchBar
          value={search}
          onChange={setSearch}
          placeHolder="Search"
          mb={3}
          addBtn={
            <Box flex="none" minW="220px">
              <Select<StatusOption>
                options={statusOptions}
                value={searchStatus}
                isClearable
                onChange={setSearchStatus}
                size="sm"
              />
            </Box>
          }
        />
        {kycListIsLoading ? (
          <Flex flexDirection="column" flex={1} alignItems="center" justifyContent="center" py={4}>
            <Spinner color="brand.500" />
          </Flex>
        ) : (
          renderContent()
        )}
      </Box>
    </>
  );
};
