import React from 'react';

import {
  Box,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Text,
} from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Icon } from '@fin/icons';
import { type CompanyInfoType, type Transfer, TransferDirections, transferReasonsLabels } from '@fin/types';
import { formatPrice, formatTransactionDate, getBeneficiaryName, modalProps } from '@fin/utils';

import { useBusinessBeneficiaries } from '@app/hooks';

export const TransferTransactionDetails = NiceModal.create<{ transfer: Transfer; businessId: CompanyInfoType['id'] }>(
  ({ transfer, businessId }) => {
    const modal = useModal();
    const { getBeneficiaryById } = useBusinessBeneficiaries(businessId);
    const isOutbound = transfer.direction === TransferDirections.outgoing;

    const title = isOutbound ? `Outbound Real-Time Transfer` : `Inbound Real-Time Transfer`;

    return (
      <Drawer {...modalProps(modal)} placement="right" size="md">
        <DrawerOverlay />

        <DrawerContent>
          <DrawerHeader as={Flex} px={4} py={3} alignItems="center" borderBottom="1px" borderBottomColor="borderStroke">
            <Heading mr="auto">{title}</Heading>
            <IconButton
              aria-label="Close Details"
              size="sm"
              variant="ghost"
              onClick={modal.hide}
              color="contentTertiary"
            >
              <Icon name="close" size={24} />
            </IconButton>
          </DrawerHeader>
          <Flex flexDirection="column" gap={{ base: 4, md: 6 }} p={4}>
            <Flex alignItems="center" justifyContent="space-between">
              <Box>
                <Text size="xs" mb={2}>
                  {isOutbound ? 'Recipient Organization' : 'Sender Organization'}
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {isOutbound ? getBeneficiaryName(getBeneficiaryById(transfer.beneficiaryId)) : transfer.businessName}
                </Text>
              </Box>
            </Flex>
            <Box>
              <Text size="xs" mb={2}>
                Amount
              </Text>
              <Text variant="number" fontWeight={500}>
                {`${formatPrice(transfer.amount)} ${transfer.currency}`}
              </Text>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Date
              </Text>
              <Text variant="primary" fontWeight={500}>
                {formatTransactionDate(transfer.creationDate)}
              </Text>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Reason
              </Text>
              <Text variant="primary" fontWeight={500}>
                {transferReasonsLabels[transfer.transferReason]}
              </Text>
            </Box>
            {transfer.description && transfer.description.length > 0 && (
              <Box>
                <Text size="xs" mb={2}>
                  Description
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {transfer.description}
                </Text>
              </Box>
            )}
          </Flex>
        </DrawerContent>
      </Drawer>
    );
  },
);
