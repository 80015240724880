import {
  Box,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  IconButton,
  Text,
} from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Icon } from '@fin/icons';
import { formatPrice, formatTransactionDate, modalProps } from '@fin/utils';

import type { InboundFundsSimulationTransaction } from '@app/types';

export const InboundFundsTransactionDetails = NiceModal.create<{ transaction: InboundFundsSimulationTransaction }>(
  ({ transaction }) => {
    const modal = useModal();

    return (
      <Drawer {...modalProps(modal)} placement="right" size="md">
        <DrawerOverlay />

        <DrawerContent>
          <DrawerHeader as={Flex} px={4} py={3} alignItems="center" borderBottom="1px" borderBottomColor="borderStroke">
            <Heading mr="auto">Funds Receipt</Heading>
            <IconButton
              aria-label="Close Details"
              size="sm"
              variant="ghost"
              onClick={modal.hide}
              color="contentTertiary"
            >
              <Icon name="close" size={24} />
            </IconButton>
          </DrawerHeader>
          <Flex p={4} flexDirection="column" gap={6}>
            <Box>
              <Text size="xs" mb={2}>
                Amount Received
              </Text>
              <Text variant="primary" fontWeight={500}>
                {`${formatPrice(Math.abs(transaction.amount))} ${transaction.currency}`}
              </Text>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Date
              </Text>
              <Text variant="primary" fontWeight={500}>
                {formatTransactionDate(transaction.createdAt)}
              </Text>
            </Box>
          </Flex>
        </DrawerContent>
      </Drawer>
    );
  },
);
