import { Navigate, Route, Routes } from 'react-router-dom';

import { AppEnv } from '@fin/shared';
import { getAppEnv } from '@fin/utils';

import { Layout, RouteGuard } from '@app/components';
import { Config } from '@app/modules/Config';
import {
  DemoToolBeneficiaries,
  DemoToolConnectAW,
  DemoToolsCompanies,
  DemoToolsForBusinessLayout,
  DemoToolSkipKYC,
  DemoToolsList,
  DemoToolTransactions,
} from '@app/modules/DemoTools';
import { Impersonation } from '@app/modules/Impersonation';
import { KYCDetails } from '@app/modules/KYCDetails';
import { KYCList } from '@app/modules/KYCList';
import { NotFound } from '@app/modules/NotFound';
import { routes } from '@app/routing/routes';

const appEnv = getAppEnv();

export const MainRouting = () => {
  return (
    <Routes>
      <Route path={routes.home} element={<Layout />}>
        <Route path={routes.login} element={<Navigate to={routes.home} replace />} />
        <Route path={routes.impersonation} element={<Impersonation />} />
        <Route path={routes.kycList} element={<KYCList />} />
        <Route path={routes.kycDetails} element={<KYCDetails />} />
        <Route path={routes.config} element={<Config />} />

        <Route path={routes.demoTools} element={<RouteGuard hasAccess={appEnv !== AppEnv.prod} />}>
          <Route path={routes.demoTools} element={<DemoToolsCompanies />} />
          <Route path={routes.demoToolsForBusiness} element={<DemoToolsForBusinessLayout />}>
            <Route path={routes.demoToolsForBusiness} element={<DemoToolsList />} />
            <Route path={routes.demoToolSkipKYC} element={<DemoToolSkipKYC />} />
            <Route path={routes.demoToolConnectAW} element={<DemoToolConnectAW />} />
            <Route path={routes.demoToolBeneficiaries} element={<DemoToolBeneficiaries />} />
            <Route path={routes.demoToolTransactions} element={<DemoToolTransactions />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
