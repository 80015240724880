import React from 'react';

import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';

import { Flag } from '@fin/icons';
import { formatPrice, formatTransactionDate, getBankAccountTitle } from '@fin/utils';

import type { WithdrawalSimulationTransaction } from '@app/types';

export const WithdrawalTransactionDetailsContent = ({
  transaction,
}: {
  transaction: WithdrawalSimulationTransaction;
}) => {
  return (
    <SimpleGrid p={4} columns={{ base: 1, md: 2 }} spacingY={{ base: 4, md: 6 }} spacingX={2}>
      <Box>
        <Text size="xs" mb={2}>
          Source Account
        </Text>
        <Flex alignItems="center" gap={1}>
          <Flag currency={transaction.relatedEntity.currencyWallet} width={28} />
          <Text variant="primary" fontWeight={500}>
            {transaction.relatedEntity.currencyWallet} Account
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Destination Bank Account
        </Text>
        <Flex alignItems="center" gap={1}>
          <Flag currency={transaction.relatedEntity.withdrawalBankAccount.currency} width={28} />
          <Text variant="primary" fontWeight={500}>
            {getBankAccountTitle(transaction.relatedEntity.withdrawalBankAccount)}
          </Text>
        </Flex>
      </Box>
      <Box gridColumn="1/-1">
        <Text size="xs" mb={2}>
          Withdrawal Amount
        </Text>
        <Text variant="primary" fontWeight={500}>
          {`${formatPrice(Math.abs(transaction.relatedEntity.amount))} ${transaction.currency}`}
        </Text>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Date
        </Text>
        <Text variant="primary" fontWeight={500}>
          {formatTransactionDate(transaction.createdAt)}
        </Text>
      </Box>
    </SimpleGrid>
  );
};
