export const Radio = {
  baseStyle: {
    control: {
      borderColor: 'contentSecondary',

      _hover: {
        borderColor: 'contentPrimary',
      },
      _active: {
        borderColor: 'brand.600',
      },
      _invalid: {
        borderColor: 'brandRed.500',
      },
      _disabled: {
        borderColor: 'contentTertiary',
        bg: 'transparent',
        _hover: {
          borderColor: 'contentTertiary',
        },
      },

      _checked: {
        borderColor: 'brand.500',
        bg: 'transparent',
        color: 'brand.500',

        _hover: {
          borderColor: 'brand.500',
          bg: 'transparent',
          color: 'brand.500',
        },
        _disabled: {
          borderColor: 'contentTertiary',
          bg: 'transparent',
          color: 'contentTertiary',
        },
      },
    },
  },
};
