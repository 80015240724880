import useSWR from 'swr';

import type { Industry } from '@fin/types';
import { getIndustryInfo, prepareIndustries } from '@fin/utils';

export const useKYCIndustries = () => {
  const { data } = useSWR<{ categories: Industry[] }>('/admin/kyc/industries?group=true', {
    shouldRetryOnError: false,
    revalidateIfStale: false,
  });

  const KYCIndustries = prepareIndustries(data?.categories);

  return {
    KYCIndustries,
    getIndustryInfo: (value: string | null | undefined) => getIndustryInfo(KYCIndustries, value),
  };
};
