import { listAnatomy as parts } from '@chakra-ui/anatomy';
import { type ComponentStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

export const List: ComponentStyleConfig = {
  variants: {
    secondary: definePartsStyle({
      item: {
        p: 1,
        '&::marker': {
          color: 'contentSecondary',
        },
      },
    }),
  },
};
