/* eslint-disable no-console */
import { captureException } from '@sentry/react';

export const Logger = {
  log: (...args: any) => {
    if (import.meta.env.VITE_LOG_VERBOSE === 'true') {
      console.log(...args);
    }
  },
  error: (error: any) => {
    if (import.meta.env.VITE_LOG_VERBOSE === 'true') {
      console.error(error);
    }
    if (import.meta.env.VITE_CAPTURE_EXCEPTION === 'true') {
      captureException(error);
    }
  },
};
