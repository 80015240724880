import React, { useState } from 'react';

import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
} from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Select } from '@fin/components';
import { Icon } from '@fin/icons';
import { WithdrawalStatus, withdrawalStatusBadgeMapping, withdrawalStatusLabels } from '@fin/types';
import { handleApiError, modalProps, showNotification } from '@fin/utils';

import { PaymentSimulationStatuses, SimulationService } from '@app/services/simulation';
import type { WithdrawalSimulationTransaction } from '@app/types';

import { WithdrawalTransactionDetailsContent } from './WithdrawalTransactionDetailsContent';

const WithdrawalSimulationStatusesOptions = [
  { label: 'Sent', value: PaymentSimulationStatuses.SENT },
  { label: 'Cancelled', value: PaymentSimulationStatuses.CANCELLED },
  { label: 'Failed', value: PaymentSimulationStatuses.FAILED },
];

export const WithdrawalTransactionDetails = NiceModal.create<{ transaction: WithdrawalSimulationTransaction }>(
  ({ transaction }) => {
    const modal = useModal();

    const [status, setStatus] = useState<null | PaymentSimulationStatuses>();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const simulate = async () => {
      if (!status) return;

      try {
        setIsSubmitting(true);
        await SimulationService.changeWithdrawalStatus(
          transaction.relatedEntity.businessId,
          transaction.relatedEntity.id,
          status,
        );
        modal.hide();

        showNotification({
          title: 'Success',
          description: 'Widthdrawal transaction status changed to cancelled',
          status: 'success',
        });
      } catch (e) {
        handleApiError(e);
      } finally {
        setIsSubmitting(false);
      }
    };

    return (
      <Drawer {...modalProps(modal)} placement="right" size="md">
        <DrawerOverlay />

        <DrawerContent>
          <DrawerHeader
            as={Flex}
            px={4}
            py={3}
            gap={2}
            alignItems="center"
            borderBottom="1px"
            borderBottomColor="borderStroke"
          >
            <Heading>Withdrawal Transaction</Heading>
            {transaction.transactionType === 'PAYOUT' && (
              <Badge
                variant="status"
                colorScheme={withdrawalStatusBadgeMapping[transaction.relatedEntity.status].color}
              >
                <Icon name={withdrawalStatusBadgeMapping[transaction.relatedEntity.status].icon} size={18} />
                {withdrawalStatusLabels[transaction.relatedEntity.status]}
              </Badge>
            )}
            <IconButton
              aria-label="Close Details"
              size="sm"
              variant="ghost"
              ml="auto"
              onClick={modal.hide}
              color="contentTertiary"
            >
              <Icon name="close" size={24} />
            </IconButton>
          </DrawerHeader>

          {transaction.relatedEntity.status === WithdrawalStatus.IN_PROGRESS && (
            <Box p={4}>
              <Box p={3} bg="bg.100" rounded={4} border="1px" borderColor="borderStroke">
                <FormControl mb={3}>
                  <FormLabel>Status</FormLabel>
                  <Select options={WithdrawalSimulationStatusesOptions} value={status} onChange={setStatus} />
                </FormControl>
                <Button
                  w="full"
                  isDisabled={!status}
                  onClick={simulate}
                  isLoading={isSubmitting}
                  loadingText="Withdrawing..."
                >
                  Simulate Status Transition
                </Button>
              </Box>
            </Box>
          )}

          <WithdrawalTransactionDetailsContent transaction={transaction} />
        </DrawerContent>
      </Drawer>
    );
  },
);
