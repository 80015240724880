import React from 'react';

import { ChakraProvider, Flex, Spinner } from '@chakra-ui/react';

import { ClerkLoaded, ClerkLoading, ClerkProvider, useAuth } from '@clerk/clerk-react';
import NiceModal from '@ebay/nice-modal-react';
import '@fontsource/dm-sans';
import '@fontsource/dm-sans/500.css';
import '@fontsource/dm-sans/700.css';
import '@fontsource/hanken-grotesk';
import '@fontsource/hanken-grotesk/500.css';
import { CalendarAdapterProvider } from 'azdatepicker';
import { AdapterDayjs } from 'azdatepicker/dist/adapters/AdapterDayjs';
import { useNavigate } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { clerkGlobalAppearance } from '@fin/components';
import { theme } from '@fin/theme';
import { swrCacheProvider } from '@fin/utils';

import { Notifications } from '@app/modules/Notifications';
import { apiFetcher, initializeMiddleware } from '@app/network';

import { AppRouting } from './routing';

const clerkPublishableKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!clerkPublishableKey) {
  throw new Error('Missing VITE_CLERK_PUBLISHABLE_KEY Key');
}

const AxiosMiddlewareProvider = () => {
  const { getToken } = useAuth();

  initializeMiddleware(getToken);
  return null;
};

function App() {
  const navigate = useNavigate();

  return (
    <ChakraProvider theme={theme}>
      <ClerkProvider
        routerPush={(to) => navigate(to)}
        routerReplace={(to) => navigate(to, { replace: true })}
        publishableKey={clerkPublishableKey}
        supportEmail="support@finofo.com"
        appearance={clerkGlobalAppearance}
      >
        <AxiosMiddlewareProvider />
        <ClerkLoading>
          <Flex flexDirection="column" justifyContent="center" alignItems="center" h="100vh">
            <Spinner color="brand.500" size="xl" />
          </Flex>
        </ClerkLoading>
        <ClerkLoaded>
          <SWRConfig value={{ fetcher: apiFetcher, revalidateOnFocus: false, provider: swrCacheProvider }}>
            <CalendarAdapterProvider adapter={AdapterDayjs}>
              <NiceModal.Provider>
                <AppRouting />
              </NiceModal.Provider>
            </CalendarAdapterProvider>
            <Notifications />
          </SWRConfig>
        </ClerkLoaded>
      </ClerkProvider>
    </ChakraProvider>
  );
}

export default App;
