import { useMemo } from 'react';

import { Box, useBreakpointValue } from '@chakra-ui/react';

import { LoadingAndEmptyState } from './LoadingAndEmptyState';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import { TableWrapper } from './TableWrapper';
import { type TableProps } from './types';
import { useTableShadow } from './useTableShadow';

export const Table = <T,>(props: TableProps<T>) => {
  const isMobile = useBreakpointValue(
    {
      base: true,
      sm: false,
    },
    { ssr: false },
  );

  const {
    templateColumns,
    columns,
    sortBy,
    onChangeSort,
    entities,
    keyProp,
    emptyText,
    emptyElement,
    loading,
    subTable,
    tableProps,
    rowProps,
    footer,
    subTableProps,
    loadingFooter,
    hideHeader,
    withSkeleton,
    wrapperProps,
    numberOfSkeletonRows,
  } = props;

  const { tableRef, wrapperRef, tableShadowStyles, columnShadowStyles } = useTableShadow({ loading, isMobile });

  const columnsToRender = useMemo(
    () => columns.filter((column) => !column.hidden && (isMobile ? column.keepOnMobile : !column.mobileOnly)),
    [isMobile, columns],
  );

  const isEmptyStateVisible = !loading && !!emptyElement && !entities?.length;
  const isBorderHidden = Boolean(withSkeleton && (loading || isEmptyStateVisible));

  return (
    <TableWrapper
      {...{
        wrapperRef,
        tableRef,
        tableProps,
        templateColumns,
        isBorderHidden,
        columnShadowStyles,
        wrapperProps: { ...wrapperProps, sx: isEmptyStateVisible || loading ? undefined : tableShadowStyles },
      }}
    >
      {!hideHeader && !isEmptyStateVisible && (
        <TableHeader {...{ columns: columnsToRender, isBorderHidden, loading, withSkeleton, sortBy, onChangeSort }} />
      )}

      {entities?.map((item, rowIndex) => (
        <TableRow
          key={item[keyProp] as string}
          {...{
            item,
            rowIndex,
            rowProps,
            columns: columnsToRender,
            subTable,
            subTableProps,
            keyProp,
            entities,
          }}
        />
      ))}

      <LoadingAndEmptyState
        {...{ loading, columns: columnsToRender, emptyText, emptyElement, withSkeleton, numberOfSkeletonRows }}
        isEmpty={!entities?.length}
      />

      {footer?.() && (
        <Box borderTop="1px solid" borderTopColor="borderStroke" gridColumn="1/-1">
          {footer?.()}
        </Box>
      )}
      {loadingFooter && <Box gridColumn="1/-1">{loadingFooter}</Box>}
    </TableWrapper>
  );
};
