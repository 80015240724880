import { type HTMLChakraProps, type SkeletonTextProps } from '@chakra-ui/react';

/**
 * Creates a function that returns props for a SkeletonText component based on loading state.
 *
 * @param {boolean} isLoading - Indicates whether the content is currently loading.
 * @returns {Function} A function that takes skeleton props and optional loaded props, and returns the appropriate props based on the loading state.
 * @param {SkeletonTextProps} skeletonProps - Props to be applied when content is loading.
 * @param {HTMLChakraProps<'div'>} [loadedProps] - Optional props to be applied when content is loaded.
 * @returns {SkeletonTextProps} The conditional props for the component based on loading state.
 */

export const createSkeletonProps =
  (isLoading: boolean) => (skeletonProps: SkeletonTextProps, loadedProps?: HTMLChakraProps<'div'>) => ({
    isLoaded: !isLoading,
    ...(isLoading ? skeletonProps : (loadedProps ?? {})),
  });

const MIN_PERCENTAGE = 35;
const MAX_PERCENTAGE = 85;

type Percentages = {
  min?: number;
  max?: number;
};

export const getRandomPercentage = (params?: Percentages) => {
  const min = params?.min ?? MIN_PERCENTAGE;
  const max = params?.max ?? MAX_PERCENTAGE;

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const groupBy = <T extends { [K in P]: unknown }, P extends keyof T>(
  arr: T[],
  key: P,
): Map<T[P], Extract<T, Record<P, T[P]>>[]> => {
  const map = new Map<T[P], Extract<T, Record<P, T[P]>>[]>();

  for (const item of arr) {
    const itemKey = item[key];
    if (!map.has(itemKey)) {
      map.set(itemKey, []);
    }
    map.get(itemKey)!.push(item as Extract<T, Record<P, T[P]>>);
  }

  return map;
};
