import { type ModalProps } from '@chakra-ui/react';

import { type NiceModalHandler } from '@ebay/nice-modal-react';

export const modalProps = (
  modal: NiceModalHandler,
  onClose?: () => Promise<unknown>,
): Pick<ModalProps, 'isOpen' | 'onClose' | 'onCloseComplete'> => {
  return {
    isOpen: modal.visible,
    onClose: () => (onClose ?? modal.hide)(),
    onCloseComplete: () => {
      modal.resolveHide();
      !modal.keepMounted && modal.remove();
    },
  };
};
