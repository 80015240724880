import { Flex, type FlexProps, Skeleton } from '@chakra-ui/react';

import { Icon, type IconNames } from '@fin/icons';

type ColumnHeaderProps = Omit<FlexProps, 'onClick'> & {
  title: string;
  onClick?: (v: string) => void;
  field?: string;
  icon?: IconNames;
  sortBy?: string | null;
  renderHeader?: () => any;
  withSkeleton?: boolean;
  loading?: boolean;
};

export const ColumnHeader = (props: ColumnHeaderProps) => {
  const { onClick, icon, title, sortBy, field, renderHeader, withSkeleton, loading, ...rest } = props;
  const isClickable = onClick && field && !loading;
  return (
    <Flex
      alignItems="center"
      gap={2}
      onClick={isClickable ? () => onClick(field) : undefined}
      cursor={isClickable ? 'pointer' : 'default'}
      whiteSpace="nowrap"
      p={2}
      color="contentSecondary"
      {...rest}
    >
      {renderHeader?.()}
      {icon && (
        <Skeleton isLoaded={!withSkeleton || !loading} fadeDuration={0}>
          <Icon name={icon} size={16} />
        </Skeleton>
      )}
      <Skeleton isLoaded={!withSkeleton || !loading} fadeDuration={0}>
        {title}
      </Skeleton>
      {field && !loading && (
        <Icon
          name="arrow-up"
          opacity={sortBy?.includes(field) ? 1 : 0}
          size={16}
          style={{ transform: `rotate(${sortBy?.startsWith('!') ? 0 : 180}deg)` }}
        />
      )}
    </Flex>
  );
};
