import { CompanyLegalEntityType } from '@fin/types';

const userPositionOptions = [
  { label: 'Founder / Owner', value: 'owner' },
  { label: 'CFO', value: 'cfo' },
  { label: 'CEO', value: 'ceo' },
  { label: 'COO', value: 'coo' },
  { label: 'CTO', value: 'cto' },
  { label: 'Managing Director', value: 'managing_director' },
  { label: 'Controller / Finance Lead', value: 'controller' },
  { label: 'Accountant / Bookkeeper', value: 'accountant' },
];

const usageOptions = [
  { label: 'Vendor payments', value: 'vendor_payments' },
  { label: 'Currency exchange', value: 'currency_exchange' },
  { label: 'Expense management', value: 'expense_management' },
  { label: 'Domestic payments', value: 'domestic_payments' },
  { label: 'International payments', value: 'international_payments' },
  { label: 'Collecting payments', value: 'collecting_payments' },
  { label: 'Payroll', value: 'payroll' },
];

const sourceOfFundsOptions = [
  { label: 'Venture Capital', value: 'venture_capital' },
  { label: 'Shareholder funds', value: 'shareholder_funds' },
  { label: 'Loan from directors', value: 'loan_from_directors' },
  { label: 'Grants', value: 'grants' },
  { label: 'Business loan', value: 'business_loan' },
  { label: 'Business income', value: 'business_income' },
];

const hearAboutFinofoOptions = [
  { label: 'Google', value: 'google' },
  { label: 'Media', value: 'media' },
  { label: 'Referral', value: 'referral' },
  { label: 'Twitter / LinkedIn', value: 'twitter_linkedIn' },
  { label: 'Other', value: 'other' },
];

const legalStructureOptions = [
  { label: 'Corporation', value: CompanyLegalEntityType.CORPORATION },
  { label: 'Partnership', value: CompanyLegalEntityType.PARTNERSHIP },
  { label: 'Trust', value: CompanyLegalEntityType.TRUST },
  { label: 'Sole Proprietor', value: CompanyLegalEntityType.SOLE_PROPRIETOR },
];

const typeOfCorporationOptions = [
  { label: 'C-Corporation', value: 'CCORP' },
  { label: 'S-Corporation', value: 'SCORP' },
  { label: 'Limited Liability Company', value: 'LLC' },
];

export const KYC = {
  userPositionOptions,
  usageOptions,
  sourceOfFundsOptions,
  hearAboutFinofoOptions,
  legalStructureOptions,
  typeOfCorporationOptions,
};
