import { useState } from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';

import { useNavigate, useParams } from 'react-router-dom';

import { CompanyStatus } from '@fin/types';
import { handleApiError, showNotification } from '@fin/utils';

import { useBusinessDetails, useKYCDetails } from '@app/hooks';
import { SimulationService } from '@app/services/simulation';

export const DemoToolConnectAW = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams<{ kycId: string; businessId: string }>();

  const { businessDetails, businessDetailsIsLoading, mutateBusinessDetails } = useBusinessDetails(params?.businessId);
  const { mutateKYCDetails } = useKYCDetails(params?.kycId);

  const connect = async () => {
    if (!params?.businessId) return;

    try {
      setIsSubmitting(true);
      await SimulationService.connectToAW(params.businessId);
      await mutateBusinessDetails();
      await mutateKYCDetails();
      navigate(-1);
      showNotification({
        title: 'Success',
        description: 'Account connected',
        status: 'success',
      });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" flex={1} gap={2}>
      <Text variant="primary" fontWeight={500}>
        Only for Dev & Demo Purposes
      </Text>
      <Text size="sm">If you want to verify a real customer, go to KYC page</Text>
      <Button
        mt={4}
        onClick={connect}
        isLoading={isSubmitting || businessDetailsIsLoading}
        isDisabled={businessDetails?.status === CompanyStatus.ACTIVE}
        loadingText="Loading..."
      >
        Activate Business for Dev&Demo
      </Button>
    </Flex>
  );
};
