import { Navigate, Route, Routes } from 'react-router-dom';

import { Login } from '@app/modules/Login';
import { routes } from '@app/routing/routes';

export const PreLoggedInRouting = () => {
  return (
    <Routes>
      <Route path={routes.login} element={<Login />} />
      <Route path="*" element={<Navigate to={routes.login} replace />} />
    </Routes>
  );
};
