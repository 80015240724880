import axios, { AxiosError, type AxiosRequestConfig } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

import { ApiError, isUUID } from '@fin/utils';

import { UserService } from '@app/services';

const caseMiddlewareOptions = {
  preservedKeys: (input: string) => isUUID(input),
};

export const api = applyCaseMiddleware(
  axios.create({
    baseURL: import.meta.env.VITE_ENV === 'local' ? `/api` : import.meta.env.VITE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
  caseMiddlewareOptions,
);

export const initializeMiddleware = (getToken: () => Promise<string | null | undefined>) => {
  api.interceptors.request.use(
    async (config) => {
      const aToken = await getToken();
      if (aToken) {
        config.headers.Authorization = `Bearer ${aToken}`;
      }
      return config;
    },
    (error) => {
      throw error;
    },
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        UserService.logout();
      }
      if (error.response?.status === 500) {
        throw error;
      }
      if (error instanceof AxiosError) {
        throw new ApiError(error);
      }
      throw error;
    },
  );
};

export const apiFetcher = (url: string | { url: string; params?: AxiosRequestConfig['params'] }) => {
  const requestUrl = typeof url === 'string' ? url : url.url;
  const params: AxiosRequestConfig['params'] = typeof url === 'string' ? {} : url.params;
  return api.get(requestUrl, { params }).then(({ data }) => data);
};
