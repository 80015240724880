import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';

import { NavLink } from 'react-router-dom';

import { Icon, type IconNames } from '@fin/icons';
import { colors } from '@fin/theme';

import { useSidebarStore } from '@app/stores';

type NavItemProps = { isOpen: boolean; link: string; title: string; icon: IconNames };

export const NavItem = ({ isOpen, link, title, icon }: NavItemProps) => {
  const closeMobile = useSidebarStore((state) => state.closeMobile);
  return (
    <Box w="full" onClick={closeMobile} px={2}>
      <NavLink to={link}>
        {({ isActive }) => {
          return (
            <Flex
              alignItems="center"
              gap={3}
              py={2}
              px={isOpen ? 2 : 0}
              justifyContent={isOpen ? 'flex-start' : 'center'}
              bg={isActive ? 'brand.100' : colors.white}
              rounded={4}
              _hover={{
                bg: 'brand.100',
                color: 'brand.500',
              }}
              position="relative"
              color="contentSecondary"
            >
              <Icon name={icon} size={20} color={isActive ? 'brand.500' : 'inherit'} />
              {isOpen && (
                <Text color={isActive ? 'brand.500' : 'inherit'} fontWeight={500}>
                  {title} {isActive}
                </Text>
              )}
            </Flex>
          );
        }}
      </NavLink>
    </Box>
  );
};
