import { useCallback, useMemo } from 'react';

import type { CalendarDateRange } from 'azdatepicker';
import type { Dayjs } from 'dayjs';
import { parseAsIsoDate, useQueryStates } from 'nuqs';

import { dayjs } from '@fin/utils';

export const searchDateParams = {
  dateFrom: parseAsIsoDate,
  dateTo: parseAsIsoDate,
};

export const useSearchDateFilter = <T extends typeof searchDateParams>(searchParams?: T) => {
  const [filters, setFilters] = useQueryStates(searchParams ?? searchDateParams);

  const searchDate = useMemo(
    (): CalendarDateRange<Dayjs> => ({
      start: filters.dateFrom ? dayjs(filters.dateFrom) : null,
      end: filters.dateTo ? dayjs(filters.dateTo) : null,
    }),
    [filters.dateFrom, filters.dateTo],
  );

  const apiDateFilters = useMemo(
    () => ({
      start: filters.dateFrom ? dayjs(filters.dateFrom).startOf('day').toISOString() : null,
      end: filters.dateTo ? dayjs(filters.dateTo).endOf('day').toISOString() : null,
    }),
    [filters.dateFrom, filters.dateTo],
  );

  const handleSearchDateChange = useCallback(
    (value: CalendarDateRange<Dayjs> | null) => {
      setFilters({ dateFrom: value?.start?.toDate() || null, dateTo: value?.end?.toDate() || null });
    },
    [setFilters],
  );

  const isDateMatchingFilter = useCallback(
    (date: string | null | undefined) => {
      const { start, end } = searchDate;
      if (!start) return true; // No date filtering if start is not defined

      if (!date) return false;

      const dueDate = dayjs(date);

      if (!end) return dueDate.isSameOrAfter(start, 'd');

      return dueDate.isBetween(start, end, 'd', '[]');
    },
    [searchDate],
  );

  return {
    searchDate,
    apiDateFilters,
    handleSearchDateChange,
    isDateMatchingFilter,
  };
};
