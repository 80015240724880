import { forwardRef, useState } from 'react';

import { IconButton, Input, InputGroup, type InputProps, InputRightElement } from '@chakra-ui/react';

import { Icon } from '@fin/icons';

type PasswordInputProps = InputProps;

export const PasswordInput = forwardRef((props: PasswordInputProps, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((v) => !v);
  };

  return (
    <InputGroup size={props.size}>
      <Input type={isVisible ? 'text' : 'password'} {...props} ref={ref} />
      <InputRightElement>
        <IconButton
          aria-label="Toogle password visibility"
          size="xs"
          variant="ghost"
          color="contentSecondary"
          onClick={toggleVisibility}
          rounded="100%"
        >
          <Icon name={isVisible ? 'eye-off' : 'eye'} size={20} />
        </IconButton>
      </InputRightElement>
    </InputGroup>
  );
});

PasswordInput.displayName = 'PasswordInput';
