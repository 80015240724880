import { Box, type BoxProps } from '@chakra-ui/react';

import { ERPPlatform, Logger } from '@fin/shared';

import dynamicsIcon from './specific/dynamics.svg?react';
import netsuiteIcon from './specific/netsuite.svg?react';
import quickbooksIcon from './specific/quickbooks.svg?react';
import sageIcon from './specific/sage.svg?react';
import xeroIcon from './specific/xero.svg?react';

const ERPIcons: Record<ERPPlatform, typeof sageIcon> = {
  [ERPPlatform.SAGE_INTACCT]: sageIcon,
  [ERPPlatform.SAGE_BUSINESS_CLOUD]: sageIcon,
  [ERPPlatform.DYNAMICS365]: dynamicsIcon,
  [ERPPlatform.XERO]: xeroIcon,
  [ERPPlatform.NETSUITE]: netsuiteIcon,
  [ERPPlatform.QUICKBOOKS]: quickbooksIcon,
};

type IconProps = BoxProps & {
  platform: ERPPlatform;
  size?: number;
};

export const ERPIcon = ({ platform, size, ...rest }: IconProps) => {
  if (!ERPIcons[platform]) {
    Logger.error(`There is icon for ${platform}`);
    return null;
  }

  return <Box as={ERPIcons[platform]} width={size && `${size}px`} height={size && `${size}px`} {...rest} />;
};
