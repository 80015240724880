import { type ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '../colors';

export const Select: ComponentStyleConfig = {
  variants: {
    whiteInput: {
      field: {
        fontSize: 14,
        borderRadius: '4px',
        bg: colors.white,
        border: '1px solid',
        borderColor: 'borderStroke',
        _placeholder: {
          color: 'contentSecondary',
        },
        _invalid: {
          borderColor: 'brandRed.500',
        },
        _hover: {
          borderColor: 'contentTertiary',
          _disabled: {
            bg: 'bg.200',
            borderColor: 'bg.200',
          },
        },
        _focus: {
          borderColor: 'brand.500',
        },
        _disabled: {
          bg: 'bg.200',
          borderColor: 'bg.200',
          opacity: 1,
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        px: 4,
        height: '50px',
      },
    },
    sm: {
      field: {
        px: 2,
        height: '36px',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'whiteInput',
  },
};
