import type { MutableRefObject, PropsWithChildren } from 'react';

import { Box, type BoxProps, Grid } from '@chakra-ui/react';

import { colors } from '@fin/theme';

import type { TableProps } from './types';

type TableWrapperProps<T> = PropsWithChildren<
  Pick<TableProps<T>, 'tableProps' | 'wrapperProps' | 'templateColumns'> & {
    wrapperRef: MutableRefObject<HTMLDivElement | null>;
    tableRef: MutableRefObject<HTMLDivElement | null>;
    isBorderHidden: boolean;
    columnShadowStyles: NonNullable<BoxProps['sx']>;
  }
>;

export const TableWrapper = <T,>({
  children,
  wrapperRef,
  wrapperProps,
  tableProps,
  tableRef,
  templateColumns,
  isBorderHidden,
  columnShadowStyles,
}: TableWrapperProps<T>) => {
  return (
    <Box ref={wrapperRef} flex={1} {...wrapperProps}>
      <Grid
        ref={tableRef}
        gridTemplateColumns={templateColumns}
        gridTemplateRows="min-content"
        gridAutoRows="min-content"
        alignItems="flex-start"
        borderY="1px solid"
        borderTopColor={isBorderHidden ? 'transparent' : 'borderStroke'}
        borderBottomColor={isBorderHidden ? 'transparent' : 'borderStroke'}
        bg="bg.0"
        maxH="100%"
        sx={{
          container: 'table / inline-size',
          '.cell': {
            borderRight: '1px solid',
            borderRightColor: isBorderHidden ? 'transparent' : 'borderStroke',
            minH: 46,
            bg: colors.white,
          },
          '.columnHeader': {
            minH: 37,
          },
          '.row > .cell.last': {
            borderRight: 0,
          },
          '.row > .cell.first': {
            position: 'sticky',
            left: 0,
            zIndex: 1,
          },
          ...columnShadowStyles,
        }}
        overflow={isBorderHidden ? 'clip' : 'auto'}
        {...tableProps}
      >
        {children}
      </Grid>
    </Box>
  );
};
