import { type ComponentStyleConfig } from '@chakra-ui/react';

import { colors } from '../colors';

export const Textarea: ComponentStyleConfig = {
  baseStyle: {
    _placeholder: {
      color: 'contentSecondary',
    },
  },
  variants: {
    outline: {
      bg: colors.white,
      fontSize: '14px',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: 'borderStroke',
      _placeholder: {
        color: 'contentSecondary',
      },
      _invalid: {
        outline: 0,
        boxShadow: 'none',
        borderColor: 'brandRed.500',
      },
      _focusVisible: {
        outline: 0,
        borderColor: 'brand.500',
        boxShadow: 'none',
      },
      _hover: {
        borderColor: 'contentTertiary',
        _disabled: {
          bg: 'bg.100',
          borderColor: 'bg.100',
        },
      },
      _focus: {
        borderColor: 'brand.500',
      },
      _disabled: {
        bg: 'bg.100',
        borderColor: 'bg.100',
        opacity: 1,
      },
    },
  },
};
