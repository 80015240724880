import React from 'react';

import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';

import { Flag } from '@fin/icons';
import { formatPrice, formatTransactionDate } from '@fin/utils';

import type { FundsReceiptSimulationTransaction } from '@app/types';

export const FundsReceiptTransactionDetailsContent = ({
  transaction,
}: {
  transaction: FundsReceiptSimulationTransaction;
}) => {
  return (
    <SimpleGrid p={4} columns={1} spacingY={{ base: 4, md: 6 }} spacingX={2}>
      <Box>
        <Text size="xs" mb={2}>
          Destination Account
        </Text>
        <Flex alignItems="center" gap={1}>
          <Flag currency={transaction.currency} width={28} />
          <Text variant="primary" fontWeight={500}>
            {transaction.currency} Account
          </Text>
        </Flex>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Amount
        </Text>
        <Text variant="primary" fontWeight={500}>
          {`${formatPrice(Math.abs(transaction.amount))} ${transaction.currency}`}
        </Text>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Date
        </Text>
        <Text variant="primary" fontWeight={500}>
          {formatTransactionDate(transaction.createdAt)}
        </Text>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Sender
        </Text>
        <Text variant="primary" fontWeight={500}>
          {transaction.relatedEntity.sender}
        </Text>
      </Box>
      <Box>
        <Text size="xs" mb={2}>
          Details provided by sender
        </Text>
        <Text variant="primary" fontWeight={500}>
          {transaction.relatedEntity.senderReference}
        </Text>
      </Box>
    </SimpleGrid>
  );
};
