import { colors } from '../colors';

export const Calendar = {
  Calendar: {
    baseStyle: {
      calendar: {
        shadow: 'none',
        w: 'full',
      },
    },
  },
  CalendarControl: {
    baseStyle: {
      controls: {
        p: 2,
        pt: 4,
      },
    },
  },
  CalendarDay: {
    baseStyle: {
      color: 'contentPrimary',
      maxHeight: '32px',
      minWidth: '32px',
      w: '32px',
      fontSize: 13,
      p: 2,
      shadow: 'none',
      _active: {
        bgColor: 'brand.600',
        color: colors.white,
      },
      _hover: {
        rounded: 4,
        bgColor: 'borderStroke',
      },
      _disabled: {
        color: 'contentDisabled',
      },
    },
    variants: {
      selected: {
        rounded: 4,
        bgColor: 'brand.500',
        _hover: {
          bgColor: 'brand.600',
        },
        '&:has(+ button[aria-details=range]), &:has(+ button[aria-details=selected])': {
          borderRightRadius: 0,
        },
        '+ button[aria-details=selected]': {
          borderLeftRadius: 0,
        },
      },
      outside: {
        color: 'contentDisabled',
      },
      today: {
        bgColor: 'bg.0',
        borderColor: 'borderStroke',
        borderWidth: 1,
        rounded: 4,
        _hover: {
          bgColor: 'brand.200',
        },
      },
      range: {
        bgColor: 'content50',
        color: 'contentPrimary',

        _hover: {
          rounded: 0,
          bgColor: 'borderStroke',
        },

        _disabled: {
          _hover: {
            bgColor: 'contentSecondary',
          },
        },
        '+ button[aria-details=selected]': {
          borderLeftRadius: 0,
        },
      },
    },
  },
  CalendarMonth: {
    baseStyle: {
      name: {
        fontWeight: 700,
        fontSize: 14,
      },

      month: {
        '& + div': {
          ml: 2,
          pl: 2,
          borderLeft: '1px solid',
          borderLeftColor: 'borderStroke',
        },
      },

      weekday: {
        fontSize: 14,
        fontWeight: 500,
        color: 'contentPrimary',
        mb: 2,
      },
    },
  },
};
