import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Switch,
  Text,
} from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { type Control, Controller, type SubmitHandler, useForm } from 'react-hook-form';
import { type NumberFormatValues, NumericFormat } from 'react-number-format';

import { Select } from '@fin/components';
import { Icon } from '@fin/icons';
import { type BusinessConfig, type BusinessConfigPaymentMethod, type CompanyInfoType } from '@fin/types';
import { formatPrice, handleApiError, modalProps, showNotification } from '@fin/utils';

import { type CCConfig, useBusinessDDTotal, useCCConfigs, useDDConfigs, useDDPaymentMethods } from '@app/hooks';
import { CCConfigService, DDConfigService } from '@app/services';

type ConfigDetailsProps = {
  business: CompanyInfoType;
  DDConfig: BusinessConfig;
  CCConfig: CCConfig;
};

type ConfigForm = {
  DDEnabled: boolean;
  DDDailyTransactionLimit: number;
  DDSingleTransactionLimit: number;
  DDOutsideGlobalLimits: boolean;
  DDPaymentMethods: Array<BusinessConfigPaymentMethod['id']>;

  CCEnabled: boolean;
};

export const ConfigDetails = NiceModal.create<ConfigDetailsProps>(({ business, DDConfig, CCConfig }) => {
  const modal = useModal();
  const { mutateDDConfigs } = useDDConfigs();
  const { mutateCCConfigs } = useCCConfigs();

  const {
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = useForm<ConfigForm>({
    defaultValues: {
      DDEnabled: DDConfig.enabled,
      DDDailyTransactionLimit: DDConfig.rule.dailyTransactionLimit,
      DDSingleTransactionLimit: DDConfig.rule.singleTransactionLimit,
      DDOutsideGlobalLimits: DDConfig.rule.outsideGlobalLimits,
      DDPaymentMethods: DDConfig.rule.paymentMethods.map((i) => i.id),
      CCEnabled: CCConfig.enabled,
    },
  });

  const isDDEnabled = watch('DDEnabled');

  const submitDDConfig = async (values: ConfigForm) => {
    let ruleId = DDConfig.ruleId;
    if (DDConfig.rule.type === 'default') {
      if (
        values.DDEnabled &&
        ('DDPaymentMethods' in dirtyFields ||
          'DDDailyTransactionLimit' in dirtyFields ||
          'DDSingleTransactionLimit' in dirtyFields ||
          'DDOutsideGlobalLimits' in dirtyFields)
      ) {
        const { data } = await DDConfigService.createRule(business.id, {
          name: `${business.companyName} Rule`,
          description: `${business.companyName} Rule`,
          outsideGlobalLimits: values.DDOutsideGlobalLimits,
          dailyTransactionLimit: values.DDDailyTransactionLimit,
          singleTransactionLimit: values.DDSingleTransactionLimit,
        });
        ruleId = data.rule.id;
        await DDConfigService.updatePaymentMethods(business.id, values.DDPaymentMethods);
      }
    } else {
      if (
        values.DDEnabled &&
        ('DDDailyTransactionLimit' in dirtyFields ||
          'DDSingleTransactionLimit' in dirtyFields ||
          'DDOutsideGlobalLimits' in dirtyFields)
      ) {
        await DDConfigService.updateRule(business.id, {
          name: `${business.companyName} Rule`,
          description: `${business.companyName} Rule`,
          outsideGlobalLimits: values.DDOutsideGlobalLimits,
          dailyTransactionLimit: values.DDDailyTransactionLimit,
          singleTransactionLimit: values.DDSingleTransactionLimit,
        });
      }
    }
    if ('DDPaymentMethods' in dirtyFields) {
      await DDConfigService.updatePaymentMethods(business.id, values.DDPaymentMethods);
    }
    await DDConfigService.updateConfig(business.id, { enabled: values.DDEnabled, ruleId });
    await mutateDDConfigs();
  };

  const submitCCConfig = async (values: ConfigForm) => {
    await CCConfigService.updateConfig(business.id, { enabled: values.CCEnabled });
    await mutateCCConfigs();
  };

  const onSubmit: SubmitHandler<ConfigForm> = async (values) => {
    try {
      if (
        'DDEnabled' in dirtyFields ||
        'DDPaymentMethods' in dirtyFields ||
        'DDDailyTransactionLimit' in dirtyFields ||
        'DDSingleTransactionLimit' in dirtyFields ||
        'DDOutsideGlobalLimits' in dirtyFields
      ) {
        await submitDDConfig(values);
      }

      if ('CCEnabled' in dirtyFields) {
        await submitCCConfig(values);
      }

      modal.hide();
      showNotification({ title: 'Success', description: 'Config was changed', status: 'success' });
    } catch (e) {
      handleApiError(e);
    }
  };

  const renderDDConfigForm = () => {
    return (
      <>
        <Flex alignItems="center" gap={2}>
          <Icon name="direct-debit" size={16} color="contentSecondary" />
          <Heading size="md">Direct Debit</Heading>
        </Flex>

        <Controller
          control={control}
          name="DDEnabled"
          render={({ field: { onChange, value, name }, fieldState }) => (
            <FormControl id="DDEnabled" isInvalid={fieldState.invalid}>
              <FormLabel>Enabled</FormLabel>
              <Switch name={name} isChecked={value} onChange={onChange} />
            </FormControl>
          )}
        />

        {isDDEnabled && <DDConfigForm control={control} businessId={business.id} />}
      </>
    );
  };

  const renderCCConfigForm = () => (
    <>
      <Flex alignItems="center" gap={2}>
        <Icon name="credit-card" size={16} color="contentSecondary" />
        <Heading size="md">Credit Cards</Heading>
      </Flex>

      <Controller
        control={control}
        name="CCEnabled"
        render={({ field: { onChange, value, name }, fieldState }) => (
          <FormControl id="CCEnabled" isInvalid={fieldState.invalid}>
            <FormLabel>Enabled</FormLabel>
            <Switch name={name} isChecked={value} onChange={onChange} />
          </FormControl>
        )}
      />
    </>
  );

  return (
    <Drawer {...modalProps(modal)} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader as={Flex} px={4} py={3} alignItems="center" borderBottom="1px" borderBottomColor="borderStroke">
          <Heading mr="auto">{business.companyName}</Heading>
          <IconButton aria-label="Close Details" size="sm" variant="ghost" onClick={modal.hide} color="contentTertiary">
            <Icon name="close" size={24} />
          </IconButton>
        </DrawerHeader>
        <DrawerBody
          p={4}
          display="flex"
          flexDirection="column"
          as="form"
          gap={4}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {renderDDConfigForm()}

          <Box alignSelf="stretch" border="0.5px solid" borderColor="borderStroke" mx={-4} />

          {renderCCConfigForm()}

          <Flex gap={2} mt="auto">
            <Button flex={1} variant="secondary" onClick={modal.hide}>
              Cancel
            </Button>
            <Button flex={1} type="submit" isLoading={isSubmitting} isDisabled={!isDirty} loadingText="Saving...">
              Save
            </Button>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
});

const DDConfigForm = ({ control, businessId }: { control: Control<ConfigForm>; businessId: string }) => {
  const { businessDDTotal } = useBusinessDDTotal(businessId);
  const { DDPaymentMethods } = useDDPaymentMethods();

  return (
    <>
      {DDPaymentMethods && (
        <Controller
          control={control}
          name="DDPaymentMethods"
          rules={{ required: 'This is required' }}
          render={({ field, fieldState }) => (
            <FormControl id="paymentMethods" isInvalid={fieldState.invalid}>
              <FormLabel>Payment Methods</FormLabel>
              <Select
                options={DDPaymentMethods.map((i) => ({ label: i.currency, value: i.id }))}
                {...field}
                // @ts-expect-error bad types for multi mode
                isMulti
                placeholder="Select payment methods"
              />
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      )}
      <Controller
        control={control}
        name="DDDailyTransactionLimit"
        rules={{ required: 'This is required' }}
        render={({ field: { onChange, value, name, ref }, fieldState }) => (
          <FormControl id="DDDailyTransactionLimit" isInvalid={fieldState.invalid}>
            <FormLabel>Daily Limit</FormLabel>
            <Input
              name={name}
              placeholder="Enter amount"
              as={NumericFormat}
              thousandSeparator=","
              isAllowed={(v: NumberFormatValues) => !/\.(\d{3,})/.test(v.value)}
              value={value}
              onValueChange={(v: NumberFormatValues) => {
                onChange(v.floatValue!);
              }}
              getInputRef={ref}
            />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      {businessDDTotal !== undefined && (
        <Text mt={-2}>
          Used Today:{' '}
          <Text as="span" variant="number" color="contentPrimary" fontWeight={500}>
            {formatPrice(businessDDTotal)}
          </Text>
        </Text>
      )}
      <Controller
        control={control}
        name="DDSingleTransactionLimit"
        rules={{ required: 'This is required' }}
        render={({ field: { onChange, value, name, ref }, fieldState }) => (
          <FormControl id="DDSingleTransactionLimit" isInvalid={fieldState.invalid}>
            <FormLabel>Single Transaction Limit</FormLabel>
            <Input
              name={name}
              placeholder="Enter amount"
              as={NumericFormat}
              thousandSeparator=","
              isAllowed={(v: NumberFormatValues) => !/\.(\d{3,})/.test(v.value)}
              value={value}
              onValueChange={(v: NumberFormatValues) => {
                onChange(v.floatValue!);
              }}
              getInputRef={ref}
            />
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="DDOutsideGlobalLimits"
        render={({ field: { onChange, value, name }, fieldState }) => (
          <FormControl id="outsideGlobalLimits" isInvalid={fieldState.invalid}>
            <FormLabel>Out of Global Limit</FormLabel>
            <Switch name={name} isChecked={value} onChange={onChange} />
          </FormControl>
        )}
      />
    </>
  );
};
