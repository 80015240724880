import { Fragment, type ReactNode } from 'react';

import { Divider, Flex, Grid, type GridProps, Spinner, Text } from '@chakra-ui/react';

import { formatDate, getEntries } from '@fin/utils';

import { LoadingState } from './LoadingState';

type TransactionsTableProps<T extends { id: string }> = Omit<GridProps, 'children'> & {
  transactionsByDate: Record<string, T[]>;
  loaderRef?: any;
  isValidating?: boolean;
  hasMore?: boolean;
  isEmpty?: boolean;
  isLoading?: boolean;
  emptyElement?: ReactNode;
  children: (tr: T) => ReactNode;
};

export const TransactionsTable = <T extends { id: string }>({
  transactionsByDate,
  loaderRef,
  isValidating,
  hasMore,
  isEmpty,
  isLoading,
  children,
  emptyElement,
  borderY,
  ...rest
}: TransactionsTableProps<T>) => {
  const isEmptyStateVisible = !isLoading && !Object.keys(transactionsByDate)?.length;

  const loading = !Object.keys(transactionsByDate)?.length && isLoading;

  return (
    <Grid
      gridTemplateColumns={{ base: '1fr', md: 'minmax(0, 1fr) minmax(350px, max-content)' }}
      gridTemplateRows="min-content"
      gridAutoRows="min-content"
      alignItems="flex-start"
      bg="white"
      position="relative"
      sx={{
        container: 'table / inline-size',
        '.cell': {
          borderRight: '1px solid',
          borderRightColor: 'borderStroke',
          minH: 46,
        },
        '.columnHeader': {
          minH: 37,
        },
        '.row': {
          w: '100cqw',
        },
        '.row > .cell:last-of-type': {
          borderRight: 0,
        },
      }}
      borderY={!isEmptyStateVisible && !loading ? borderY : undefined}
      overflow="auto"
      {...rest}
    >
      {loading ? (
        <LoadingState<T> children={children} />
      ) : (
        getEntries(transactionsByDate).map(([date, transactions], dateIndex, dateArray) => (
          <Fragment key={date}>
            <Flex
              alignItems="center"
              minH="37px"
              gridColumn="1/-1"
              bg="bg.50"
              px={2}
              borderY="1px solid"
              borderColor="borderStroke"
              position="sticky"
              top={0}
              className="row"
            >
              <Text size="xs">{formatDate(date)}</Text>
            </Flex>
            {transactions.map((transaction, index, array) => (
              <Fragment key={transaction.id}>
                {children(transaction)}
                {/* show border for every row except last item in date group */}
                {/* and show for last item in last date group */}
                {(index < array.length - 1 || (!isValidating && dateIndex === dateArray.length - 1)) && (
                  <Divider gridColumn="1/-1" />
                )}
              </Fragment>
            ))}
          </Fragment>
        ))
      )}

      {isEmptyStateVisible && (
        <Flex
          gridColumn="1/-1"
          borderTop={isLoading || emptyElement ? undefined : '1px solid'}
          borderTopColor="borderStroke"
          justifyContent="center"
          py={1}
        >
          {emptyElement || (
            <Text py={2} textAlign="center">
              There are no transactions matching your search criteria
            </Text>
          )}
        </Flex>
      )}

      {!isLoading && !isEmpty && (
        <Flex gridColumn="1/-1" ref={loaderRef}>
          {(hasMore || isValidating) && (
            <Flex
              flex={1}
              borderTop="1px solid"
              borderTopColor="borderStroke"
              justifyContent="center"
              alignItems="center"
              py={2}
            >
              {isValidating && <Spinner color="brand.500" />}
            </Flex>
          )}
        </Flex>
      )}
    </Grid>
  );
};
