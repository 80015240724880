import { type MouseEvent, useEffect, useRef, useState } from 'react';

import {
  Box,
  Flex,
  IconButton,
  Input,
  InputGroup,
  type InputProps,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';

import {
  Calendar,
  CalendarControls,
  type CalendarDateRange,
  CalendarDays,
  CalendarMonth,
  CalendarMonthName,
  CalendarMonths,
  CalendarNextButton,
  CalendarPrevButton,
  type CalendarSingleDate,
  CalendarWeek,
  type CustomSelectHandler,
  type RangeCalendarProps,
  Target,
} from 'azdatepicker';
import { type Dayjs } from 'dayjs';
import isEqual from 'fast-deep-equal';

import { Icon } from '@fin/icons';
import { formatDate } from '@fin/utils';

export type DatePickerCustomRange = {
  title: string;
  start: NonNullable<CalendarDateRange<Dayjs>['start']>;
  end: NonNullable<CalendarDateRange<Dayjs>['end']>;
};

type DatePickerProps = Omit<RangeCalendarProps<Dayjs>, 'value' | 'onSelectDate'> & {
  size?: InputProps['size'];
  clearable?: boolean;
  onSelectDate: (value: CalendarDateRange<Dayjs> | null) => void;
  value: CalendarDateRange<Dayjs> | null;
  inputProps?: InputProps;
  placeholder?: string;
  months?: number;
  customRanges?: DatePickerCustomRange[];
  matchWidth?: boolean;
  isDisabled?: boolean;
};

export const DateRangePicker = ({
  value,
  onSelectDate,
  size,
  clearable,
  inputProps,
  placeholder,
  months = 2,
  customRanges,
  matchWidth = false,
  ...rest
}: DatePickerProps) => {
  const [tempDate, setTempDate] = useState<CalendarDateRange<Dayjs> | null>(value);
  const shouldUpdateOnClose = useRef(false);
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: () => {
      // need to call onSelectDate only if end date was not selected and picker was closed
      if (!isEqual(value, tempDate) && shouldUpdateOnClose.current) {
        onSelectDate(tempDate);
      }
    },
    onOpen: () => {
      shouldUpdateOnClose.current = true;
    },
  });

  const monthsAmount = useBreakpointValue({ base: 1, md: months }, { ssr: false }) || 1;

  const inputRef = useRef(null);
  const calendarRef = useRef(null);

  const handleSelectDates = (date: CalendarDateRange<Dayjs>) => {
    setTempDate(date);
    if (date.end) {
      onSelectDate(date);
      shouldUpdateOnClose.current = false;
      onClose();
    }
  };

  const handleOpen = () => {
    if (inputProps?.isDisabled) {
      return;
    }

    onOpen();
  };

  const onClear = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setTempDate(null);
    onSelectDate(null);
  };

  const customSelectHandler: CustomSelectHandler<Dayjs, CalendarDateRange<Dayjs> | CalendarSingleDate<Dayjs>> = (
    date,
    { onSelectDate, currentValue, adapter, target, changeTarget },
  ) => {
    if (!currentValue) return;

    if ('start' in currentValue) {
      // Handle CalendarDateRange
      if (target === Target.END && currentValue.start && adapter.isAfter(date, currentValue.start)) {
        changeTarget(Target.START);
        return onSelectDate({ start: currentValue.start, end: date });
      }
      changeTarget(Target.END);
      return onSelectDate({ start: date, end: undefined });
    } else {
      // Handle CalendarSingleDate
      return onSelectDate(date);
    }
  };

  useOutsideClick({
    ref: calendarRef,
    handler: onClose,
    enabled: isOpen,
  });

  useEffect(() => {
    setTempDate(value);
  }, [value]);

  return (
    <Popover
      placement="bottom-start"
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={inputRef}
      isLazy
      matchWidth={matchWidth}
    >
      <PopoverTrigger>
        <InputGroup cursor="pointer" size={size} w={{ base: 'full', md: 'auto' }} minW={250} onClick={handleOpen}>
          <Input
            placeholder={placeholder || 'Select date range'}
            value={
              tempDate?.start
                ? `${tempDate.start ? formatDate(tempDate.start) : ''} / ${tempDate.end ? formatDate(tempDate.end) : ''}`
                : ''
            }
            readOnly
            name="dateRange"
            cursor="pointer"
            ref={inputRef}
            {...inputProps}
          />
          {clearable && tempDate?.start ? (
            <InputRightElement color="contentTertiary" onClick={onClear}>
              <Icon name="close" size={18} />
            </InputRightElement>
          ) : (
            <InputRightElement color="contentTertiary" cursor={inputProps?.isDisabled ? 'not-allowed' : undefined}>
              <Icon name="calendar" size={18} />
            </InputRightElement>
          )}
        </InputGroup>
      </PopoverTrigger>

      <PopoverContent
        w={matchWidth ? 'full' : 'min-content'}
        border="none"
        outline="none"
        shadow="none"
        ref={calendarRef}
        mr={{ base: 2, sm: 'initial' }}
      >
        <Calendar
          value={tempDate ?? { start: undefined, end: undefined }}
          onSelectDate={handleSelectDates}
          highlightToday
          months={monthsAmount}
          weekdayFormat="dd"
          customSelectHandler={customSelectHandler}
          {...rest}
        >
          <Flex>
            {customRanges && customRanges.length > 0 && (
              <>
                <Flex py={2} flexDirection="column" flex={1}>
                  {customRanges.map(({ title, start, end }, index, array) => {
                    const firstSelectedIndex = array.findIndex(
                      (range) =>
                        tempDate?.start &&
                        tempDate?.end &&
                        range.start.isSame(tempDate.start, 'date') &&
                        range.end.isSame(tempDate.end, 'date'),
                    );

                    const isSelected = index === firstSelectedIndex;

                    return (
                      <Text
                        key={title}
                        size="sm"
                        variant="primary"
                        whiteSpace="nowrap"
                        bg={isSelected ? 'bg.200' : 'bg.0'}
                        onClick={() => handleSelectDates({ start, end })}
                        cursor="pointer"
                        _hover={{
                          bg: 'bg.200',
                        }}
                        fontWeight={isSelected ? 500 : 400}
                        py={1}
                        px={2}
                      >
                        {title}
                      </Text>
                    );
                  })}
                </Flex>
                <Box alignSelf="stretch" border="0.5px solid" borderColor="borderStroke" />
              </>
            )}
            <Box position="relative" flex={0}>
              <CalendarControls>
                <CalendarPrevButton
                  as={({ onClick }) => (
                    <IconButton
                      aria-label="Prev month"
                      onClick={onClick}
                      variant="ghost"
                      color="contentPrimary"
                      size="xs"
                    >
                      <Icon name="calendar-prev" size={16} />
                    </IconButton>
                  )}
                />
                <CalendarNextButton
                  as={({ onClick }) => (
                    <IconButton
                      aria-label="Prev month"
                      onClick={onClick}
                      variant="ghost"
                      color="contentPrimary"
                      size="xs"
                    >
                      <Icon name="calendar-next" size={16} />
                    </IconButton>
                  )}
                />
              </CalendarControls>
              <CalendarMonths>
                {Array.from({ length: monthsAmount }).map((_, i) => (
                  <CalendarMonth month={i} key={i}>
                    <CalendarMonthName />
                    <CalendarWeek />
                    <CalendarDays />
                  </CalendarMonth>
                ))}
              </CalendarMonths>
            </Box>
          </Flex>
        </Calendar>
      </PopoverContent>
    </Popover>
  );
};
