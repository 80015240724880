import useSWR from 'swr';

import type { BusinessConfig } from '@fin/types';

export const useBusinessDDTotal = (businessId?: BusinessConfig['id']) => {
  const { data, isLoading } = useSWR<{ totals: { dailyTransactionTotal: number } }>(
    businessId ? `/admin/businesses/${businessId}/direct_debit/deposits/totals` : null,
  );

  return {
    businessDDTotal: data?.totals.dailyTransactionTotal,
    businessDDTotalIsLoading: isLoading,
  };
};
