import React from 'react';

import { Badge, Flex, Heading, IconButton, Spinner } from '@chakra-ui/react';

import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Icon } from '@fin/icons';
import { KYCStatusBadgeMapping } from '@fin/types';

import { useBusinessDetails, useKYCDetails } from '@app/hooks';

const KYCStatusBadge = () => {
  const params = useParams<{ kycId: string; businessId: string }>();

  const { kycDetails } = useKYCDetails(params?.kycId);

  if (!kycDetails) return null;

  return (
    kycDetails.kycStatus && (
      <Badge variant="status" colorScheme={KYCStatusBadgeMapping[kycDetails.kycStatus].color}>
        <Icon name={KYCStatusBadgeMapping[kycDetails.kycStatus].icon} size={18} />
        {KYCStatusBadgeMapping[kycDetails.kycStatus].label}
      </Badge>
    )
  );
};

export const DemoToolsForBusinessLayout = () => {
  const navigate = useNavigate();
  const params = useParams<{ kycId: string; businessId: string }>();

  const { businessDetails } = useBusinessDetails(params?.businessId);
  const { kycDetails } = useKYCDetails(params?.kycId);

  const goBack = () => {
    navigate(-1);
  };

  if (!businessDetails) {
    return (
      <Flex flexDirection="column" justifyContent="center" alignItems="center" h="100vh">
        <Spinner color="brand.500" size="xl" />
      </Flex>
    );
  }

  return (
    <>
      <Flex
        alignItems="center"
        py={4}
        px={{ base: 4, lg: 6 }}
        gap={4}
        bg="white"
        borderBottom="1px"
        borderBottomColor="borderStroke"
        minH="65px"
      >
        <IconButton aria-label="Go back" onClick={goBack} variant="ghost" size="xs">
          <Icon name="arrow-left" size={24} color="brand.500" />
        </IconButton>
        <Heading>{businessDetails?.companyName || kycDetails?.companyName || ''}</Heading>
        <KYCStatusBadge />
      </Flex>
      <Flex flexDirection="column" py={4} px={{ base: 4, lg: 6 }} flex={1}>
        <Outlet />
      </Flex>
    </>
  );
};
