import { Text, type TextProps, Tooltip } from '@chakra-ui/react';

import { Logger } from '@fin/shared';

type ClipTextProps = { maxLength: number } & TextProps;

export const ClipText = ({ children, maxLength, ...rest }: ClipTextProps) => {
  if (typeof children !== 'string') {
    if (children !== null && children !== undefined) {
      Logger.log('Please provide child as a string', children);
    }
    return null;
  }

  if (children.length < maxLength) {
    return <Text {...rest}>{children}</Text>;
  }

  return (
    <Tooltip label={children} hasArrow gutter={4} placement="auto">
      <Text {...rest}>{children.substring(0, maxLength)}...</Text>
    </Tooltip>
  );
};
