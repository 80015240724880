import React from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { Outlet } from 'react-router-dom';

import { MobileSidebar, Sidebar } from '@app/components';
import { useSidebarStore } from '@app/stores';

export const Layout = () => {
  const isExpanded = useSidebarStore((state) => state.isExpanded);
  const toggle = useSidebarStore((state) => state.toggle);

  return (
    <Flex minH="100vh" flexDirection={{ base: 'column', lg: 'row' }}>
      <Sidebar
        isExpanded={isExpanded}
        toggle={toggle}
        display={{ base: 'none', lg: 'flex' }}
        borderRight="1px"
        borderRightColor="borderStroke"
        minHeight="100vh"
      />
      <MobileSidebar display={{ base: 'flex', lg: 'none' }} />
      <Box w="full">
        <Flex
          flexDirection="column"
          minH={{
            base: 'calc(100vh - 52px)',
            md: '100vh',
          }}
        >
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  );
};
