import React, { useMemo, useState } from 'react';

import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
} from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Select } from '@fin/components';
import { Icon, type IconNames } from '@fin/icons';
import { Logger } from '@fin/shared';
import { handleApiError, modalProps, showNotification } from '@fin/utils';

import { DDDTransactionStatuses, SimulationService } from '@app/services/simulation';
import { type DDDSimulationTransaction } from '@app/types';

import { DDDTransactionDetailsContent } from './DDDTransactionDetailsContent';

const DDDTransactionStatusesOptions = [
  { label: 'Reject', value: DDDTransactionStatuses.reject },
  { label: 'Reverse', value: DDDTransactionStatuses.reverse },
  { label: 'Settle', value: DDDTransactionStatuses.settle },
];

export const DDDTransactionDetails = NiceModal.create<{ transaction: DDDSimulationTransaction }>(({ transaction }) => {
  const modal = useModal();

  const [nextStatus, setNextStatus] = useState<null | DDDTransactionStatuses>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const simulate = async () => {
    if (!nextStatus) return;

    try {
      setIsSubmitting(true);
      await SimulationService.changeDDStatus(
        transaction.relatedEntity.businessId,
        transaction.relatedEntity.id,
        nextStatus,
      );
      modal.hide();

      showNotification({
        title: 'Success',
        description: 'Deposit transaction status changed',
        status: 'success',
      });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isReversal = transaction.transactionType === 'DEPOSIT_REVERSAL';

  const status = useMemo(() => {
    switch (transaction.status) {
      case 'SETTLED': {
        if (transaction.transactionType === 'DEPOSIT_REVERSAL') return 'REVERSED';
        return 'SETTLED';
      }
      case 'CANCELLED': {
        return 'CANCELLED';
      }
      case 'PENDING': {
        return 'PENDING';
      }
      default: {
        Logger.error(`DDTransaction unhandled case, ${JSON.stringify(transaction)}`);
        return 'SETTLED';
      }
    }
  }, [transaction]);

  const badgeStatusMapping: Record<typeof status, { icon: IconNames; color: string; title: string }> = {
    SETTLED: { icon: 'check', color: 'gray', title: 'Settled' },
    REVERSED: { icon: 'error', color: 'red', title: 'Reversed' },
    CANCELLED: { icon: 'error', color: 'red', title: 'Cancelled' },
    PENDING: { icon: 'pending', color: 'blue', title: 'Pending' },
  };

  const badgeData = badgeStatusMapping[status];

  return (
    <Drawer {...modalProps(modal)} placement="right" size="md">
      <DrawerOverlay />

      <DrawerContent>
        <DrawerHeader
          as={Flex}
          px={4}
          py={3}
          gap={2}
          alignItems="center"
          borderBottom="1px"
          borderBottomColor="borderStroke"
        >
          <Heading>{isReversal ? 'Deposit Reversal' : 'Deposit'}</Heading>
          <Badge variant="status" colorScheme={badgeData.color}>
            <Icon name={badgeData.icon} size={18} />
            {badgeData.title}
          </Badge>
          <IconButton
            aria-label="Close Details"
            size="sm"
            variant="ghost"
            ml="auto"
            onClick={modal.hide}
            color="contentTertiary"
          >
            <Icon name="close" size={24} />
          </IconButton>
        </DrawerHeader>

        <Box p={4}>
          <Box p={3} bg="bg.100" rounded={4} border="1px" borderColor="borderStroke">
            <FormControl mb={3}>
              <FormLabel>Status</FormLabel>
              <Select options={DDDTransactionStatusesOptions} value={nextStatus} onChange={setNextStatus} />
            </FormControl>
            <Button w="full" isDisabled={!status} onClick={simulate} isLoading={isSubmitting} loadingText="Loading...">
              Simulate Status Transition
            </Button>
          </Box>
        </Box>

        <DDDTransactionDetailsContent transaction={transaction} />
      </DrawerContent>
    </Drawer>
  );
});
