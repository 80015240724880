import useSWR from 'swr';

import type { BeneficiaryType, CompanyInfoType } from '@fin/types';

export const useBusinessBeneficiaries = (businessId: CompanyInfoType['id'] | undefined) => {
  const { data, isLoading, mutate } = useSWR<{ beneficiaries: BeneficiaryType[] }>(
    businessId ? `/admin/businesses/${businessId}/beneficiaries` : null,
    { revalidateIfStale: false },
  );

  const getBeneficiaryById = (beneficiaryId: string | undefined | null) => {
    return beneficiaryId ? data?.beneficiaries.find((beneficiary) => beneficiary.id === beneficiaryId) : undefined;
  };

  return {
    beneficiaries: data?.beneficiaries,
    getBeneficiaryById,
    beneficiariesIsLoading: isLoading,
    mutateBeneficiaries: mutate,
  };
};
