import useSWR from 'swr';

import type { BusinessConfig, BusinessMembership } from '@fin/types';

export const useBusinessMemberships = (businessId: BusinessConfig['id']) => {
  const { data, isLoading } = useSWR<{ memberships: BusinessMembership[] }>(
    `/admin/businesses/${businessId}/memberships`,
  );

  return {
    memberships: data?.memberships,
    membershipsIsLoading: isLoading,
  };
};
