const colorPalette = {
  white: '#ffffff',
  gray: {
    25: '#f9f9f9',
    50: '#f3f3f3',
    100: '#ebebeb',
    200: '#e3e2e2',
    300: '#cacacb',
    400: '#b0afb0',
    500: '#959395',
    600: '#868486',
    700: '#717072',
    800: '#535255',
    900: '#404042',
    1000: '#1d1d20',
    '1000-08': '#1D1D2014',
  },
  green: {
    50: '#eaffee',
    500: '#009f50',
    600: '#008844',
    700: '#006a33',
    800: '#004e24',
  },
  red: {
    50: '#fff6f5',
    100: '#ffe9e6',
    500: '#e64340',
    600: '#c52b2c',
    700: '#b2171e',
    800: '#940011',
  },
  yellow: {
    50: '#fffbe6',
    500: '#faad14',
    800: '#874d00',
  },
  blue: {
    50: '#f5f9ff',
    400: '#639AFF',
    500: '#377AF7', // in figma it is same as bluescale.600, which is relict from old design
    600: '#1A5DD8',
    700: '#0042b4',
    800: '#073588',
  },
} as const;

export const colors = {
  white: colorPalette.white,
  content50: colorPalette.gray['100'],
  contentPrimary: colorPalette.gray['1000'],
  contentSecondary: colorPalette.gray['700'],
  contentTertiary: colorPalette.gray['500'],
  contentDisabled: colorPalette.gray['300'],
  borderStroke: colorPalette.gray['200'],
  borderOpacity: colorPalette.gray['1000-08'],
  borderDisabled: colorPalette.gray['100'],
  brand: {
    50: colorPalette.gray['25'],
    100: colorPalette.gray['50'],
    200: colorPalette.gray['500'],
    400: colorPalette.gray['800'],
    500: colorPalette.gray['1000'],
    600: colorPalette.gray['900'],
  },
  bg: {
    0: colorPalette.white,
    50: colorPalette.white,
    100: colorPalette.gray['25'],
    200: colorPalette.gray['50'],
  },
  brandGreen: {
    50: colorPalette.green['50'],
    400: colorPalette.green['500'],
    500: colorPalette.green['600'],
    600: colorPalette.green['700'],
    800: colorPalette.green['800'],
  },
  brandBlue: {
    50: colorPalette.blue['50'],
    400: colorPalette.blue['400'],
    500: colorPalette.blue['500'],
    600: colorPalette.blue['600'],
    700: colorPalette.blue['700'],
    800: colorPalette.blue['800'],
  },
  brandYellow: {
    50: colorPalette.yellow['50'],
    500: colorPalette.yellow['500'],
    800: colorPalette.yellow['800'],
  },
  brandRed: {
    50: colorPalette.red['50'],
    100: colorPalette.red['100'],
    400: colorPalette.red['500'],
    500: colorPalette.red['600'],
    600: colorPalette.red['700'],
    800: colorPalette.red['800'],
  },
};
