import React, { useState } from 'react';

import {
  Badge,
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Select } from '@fin/components';
import { Flag, Icon } from '@fin/icons';
import {
  BeneficiaryEntityType,
  type Payment,
  PaymentMethodsType,
  PaymentsReasons,
  paymentStatusBadgeMapping,
  paymentStatusLabels,
  sendMoneyReasonsLabels,
} from '@fin/types';
import {
  formatPrice,
  formatTransactionDate,
  getBeneficiaryName,
  handleApiError,
  modalProps,
  showNotification,
} from '@fin/utils';

import { useBusinessBeneficiaries } from '@app/hooks';
import { PaymentSimulationStatuses, SimulationService } from '@app/services/simulation';

type PaymentTransactionDetailsProps = {
  payment: Payment;
};

const PaymentSimulationStatusesOptions = [
  { label: 'Sent', value: PaymentSimulationStatuses.SENT },
  { label: 'Cancelled', value: PaymentSimulationStatuses.CANCELLED },
  { label: 'Failed', value: PaymentSimulationStatuses.FAILED },
];

export const PaymentTransactionDetails = NiceModal.create<PaymentTransactionDetailsProps>(({ payment }) => {
  const modal = useModal();
  const { getBeneficiaryById } = useBusinessBeneficiaries(payment.businessId);

  const [status, setStatus] = useState<null | PaymentSimulationStatuses>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const simulate = async () => {
    if (!status) return;

    try {
      setIsSubmitting(true);
      await SimulationService.changePaymentStatus(payment.businessId, payment.id, status);
      modal.hide();

      showNotification({
        title: 'Success',
        description: 'Payment status changed',
        status: 'success',
      });
    } catch (e) {
      handleApiError(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [isFlipped, setIsFlipped] = useState(false);

  const beneficiary = getBeneficiaryById(payment?.beneficiaryId);

  const isSameCurrency = payment.currency === payment.currencyWallet;

  return (
    <Drawer {...modalProps(modal)} placement="right" size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader as={Flex} px={4} py={3} alignItems="center" borderBottom="1px" borderBottomColor="borderStroke">
          <Heading mr={2}>
            {payment.paymentReason === PaymentsReasons.BILL_PAYMENT ? 'Bill Payment' : 'Money Sent'}
          </Heading>
          <Badge variant="status" colorScheme={paymentStatusBadgeMapping[payment.status].color} mr="auto">
            <Icon name={paymentStatusBadgeMapping[payment.status].icon} size={18} />
            {paymentStatusLabels[payment.status]}
          </Badge>
          <IconButton aria-label="Close Details" size="sm" variant="ghost" onClick={modal.hide} color="contentTertiary">
            <Icon name="close" size={24} />
          </IconButton>
        </DrawerHeader>
        <Flex overflow="auto" flexDirection="column" flex={1}>
          <Box p={4}>
            <Box p={3} bg="bg.100" rounded={4} border="1px" borderColor="borderStroke">
              <FormControl mb={3}>
                <FormLabel>Status</FormLabel>
                <Select options={PaymentSimulationStatusesOptions} value={status} onChange={setStatus} />
              </FormControl>
              <Button
                w="full"
                isDisabled={!status}
                onClick={simulate}
                isLoading={isSubmitting}
                loadingText="Loading..."
              >
                Simulate Status Transition
              </Button>
            </Box>
          </Box>
          <SimpleGrid py={4} px={4} columns={{ base: 1, md: 2 }} spacingX={2} spacingY={6}>
            {beneficiary && (
              <Box gridColumn="1/-1">
                <Text size="xs" mb={2}>
                  Beneficiary
                </Text>
                <Flex flex={1} alignItems="center" gap={1}>
                  <Icon
                    name={
                      beneficiary.beneficiaryEntityType === BeneficiaryEntityType.COMPANY
                        ? 'beneficiary-company'
                        : 'beneficiary-individual'
                    }
                    size={16}
                    color="contentSecondary"
                    mr={1}
                  />
                  <Text variant="primary" fontWeight={500}>
                    {getBeneficiaryName(beneficiary)}
                  </Text>
                  <Text>•</Text>
                  <Text>{beneficiary.currency}</Text>
                </Flex>
              </Box>
            )}
            <Box>
              <Text size="xs" mb={2}>
                Paid with
              </Text>
              <Flex alignItems="center" gap={1}>
                <Flag currency={payment.currencyWallet} width={28} />
                <Text variant="primary" fontWeight={500}>
                  {payment.currencyWallet} Account
                </Text>
              </Flex>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Payment method
              </Text>
              {beneficiary && (
                <Text variant="primary" fontWeight={500}>
                  {beneficiary.paymentMethods.find((i) => i.type === PaymentMethodsType.awPayment)?.details.label}
                </Text>
              )}
            </Box>
            {!isSameCurrency && payment.conversion && (
              <>
                <Box>
                  <Text size="xs" mb={2}>
                    You Converted
                  </Text>
                  <Text variant="primary" fontWeight={500}>
                    {`${formatPrice(payment.conversion.clientSellAmount)} ${payment.conversion.sellCurrency}`}
                  </Text>
                </Box>
                <Box gridColumn="1/-1">
                  <Text size="xs" mb={2}>
                    Exchange Rate
                  </Text>
                  <Flex alignItems="center" gap={2}>
                    <Text variant="primary" fontWeight={500}>
                      1 {isFlipped ? payment.conversion?.buyCurrency : payment.conversion?.sellCurrency} ={' '}
                      {`${formatPrice(
                        payment.conversion
                          ? Math.pow(
                              Math.pow(
                                payment.conversion.clientRate,
                                payment.conversion.baseCurrency === payment.conversion.sellCurrency ? 1 : -1,
                              ),
                              isFlipped ? -1 : 1,
                            )
                          : 0,
                        {
                          minimumFractionDigits: 6,
                          maximumFractionDigits: 6,
                        },
                      )} ${(isFlipped ? payment.conversion?.sellCurrency : payment.conversion?.buyCurrency) ?? 'CAD'}`}
                    </Text>
                    <IconButton
                      aria-label="Flip"
                      onClick={() => setIsFlipped((v) => !v)}
                      variant="ghost"
                      color="brand.500"
                      size="xs"
                    >
                      <Icon name="flip" size={16} />
                    </IconButton>
                  </Flex>
                </Box>
              </>
            )}
            <Box>
              <Text size="xs" mb={2}>
                Amount Paid Out to Beneficiary
              </Text>
              <Text variant="primary" fontWeight={500}>
                {`${formatPrice(payment.amount)} ${payment.currency ?? ''}`}
              </Text>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Fees
              </Text>
              <Text variant="primary" fontWeight={500}>
                {`${formatPrice(0)} ${payment.currency}`}
              </Text>
            </Box>
            <Box>
              <Text size="xs" mb={2}>
                Date
              </Text>
              <Text variant="primary" fontWeight={500}>
                {formatTransactionDate(payment.paymentDate)}
              </Text>
            </Box>
            {payment.paymentReason && payment.paymentReason !== PaymentsReasons.BILL_PAYMENT && (
              <Box gridColumn="1/-1">
                <Text size="xs" mb={2}>
                  Reason
                </Text>
                <Text variant="primary" fontWeight={500}>
                  {sendMoneyReasonsLabels[payment.paymentReason]}
                </Text>
              </Box>
            )}
          </SimpleGrid>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
});
