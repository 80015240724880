import { type FieldNamesMarkedBoolean } from 'react-hook-form';

import { AppEnv, Logger } from '@fin/shared';

type Entries<T> = Array<{ [K in keyof T]: [K, T[K]] }[keyof T]>;

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

export const getDirtyFields = <T extends object>(form: T, dirtyFields: FieldNamesMarkedBoolean<T>) =>
  // @ts-expect-error difficult to add proper type
  Object.fromEntries(getEntries(form).filter(([key]) => dirtyFields[key]));

export function getDirtyValues<
  Values extends Record<keyof DirtyFields, unknown>,
  DirtyFields extends Record<string, unknown>,
>(values: Values, dirtyFields: DirtyFields): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev;

    const value =
      typeof dirtyFields[key] === 'object'
        ? getDirtyValues(values[key] as Values, dirtyFields[key] as DirtyFields)
        : values[key];

    if (typeof value === 'object' && value !== null && Object.values(value).length === 0) {
      return prev;
    }

    return {
      ...prev,
      [key]: value,
    };
  }, {});

  return dirtyValues;
}

export const getAppEnv = () => {
  const env = import.meta.env.VITE_ENV as AppEnv;

  if (!env || !AppEnv[env]) {
    const error = new Error('Unknown env');
    Logger.error(error);
    throw error;
  }

  return AppEnv[env];
};

export const pickFromObject = <T extends Record<string, any>, K extends keyof T>(
  obj: T | undefined,
  keys: K[],
): Pick<T, K> => {
  const result = {} as any as Pick<T, K>;

  if (!obj) return result;

  keys.forEach((key) => {
    if (key in obj) {
      result[key] = obj[key];
    }
  });

  return result;
};

export const getByPath = (obj: any, path: string) => {
  const keys = path.split('.');
  let value = obj;

  for (const key of keys) {
    if (!value || typeof value !== 'object') {
      return undefined;
    }
    value = value[key];
  }

  return value;
};

const isDateRegexp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

export const isDateString = (str: any) => typeof str === 'string' && isDateRegexp.test(str);

const isObject = (value: any): boolean => value !== null && typeof value === 'object';

const trimValue = (value: any) => (typeof value === 'string' ? value.trim() : value);

export const trimValues = (data: Record<string, any>): Record<string, any> =>
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => (isObject(value) ? [key, trimValues(value)] : [key, trimValue(value)])),
  );

export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return (...args: Parameters<T>): void => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;

type UUID = string & { __brand: 'UUID' };
export const isUUID = (str: string): str is UUID => uuidRegex.test(str);

export const chunkArray = <T>(arr: T[] | null, chunkSize: number): T[][] => {
  if (!arr) return [];

  const chunkedArr: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunkedArr.push(arr.slice(i, i + chunkSize));
  }
  return chunkedArr;
};
