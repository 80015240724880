export type User = {
  businessId: string | null;
  companyName: string;
  companyPosition: string | null;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string | null;
  roles: UserRoles;
  tosId: string | null;
};

export enum UserRoles {
  owner = 'owner',
  admin = 'admin',
  clerk = 'clerk',
}

export const RoleLabels = {
  [UserRoles.owner]: 'Owner',
  [UserRoles.admin]: 'Admin',
  [UserRoles.clerk]: 'Collaborator',
};
