import useSWR from 'swr';

import type { BusinessConfig } from '@fin/types';

export const useDDConfigs = () => {
  const { data, isLoading, mutate } = useSWR<{ businessConfigs: BusinessConfig[] }>(
    '/admin/direct_debit/businesses/configuration',
  );

  return {
    DDConfigs: data?.businessConfigs,
    DDConfigsIsLoading: isLoading,
    mutateDDConfigs: mutate,
  };
};
