import useSWR from 'swr';

import type { CompanyInfoType } from '@fin/types';

export const useBusinessDetails = (businessId: CompanyInfoType['id'] | undefined) => {
  const { data, isLoading, mutate } = useSWR<CompanyInfoType>(businessId ? `/admin/businesses/${businessId}` : null);

  return {
    businessDetails: data,
    businessDetailsIsLoading: isLoading,
    mutateBusinessDetails: mutate,
  };
};
