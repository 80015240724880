import { type Dayjs } from 'dayjs';

import type { IconNames } from '@fin/icons';
import type { Currency } from '@fin/shared';

export enum BeneficiaryEntityType {
  COMPANY = 'company',
  INDIVIDUAL = 'individual',
}

export type BeneficiaryBaseType = {
  contactName: string;
  nickname: string;
  accountNumber: string;
  addressLine_1: string;
  addressLine_2: string;
  beneficiaryEntityType: BeneficiaryEntityType;
  beneficiaryFirstName: string | null;
  beneficiaryLastName: string | null;
  city: string | null;
  currency: Currency;
  email: string;
  financialInstitution: string;
  phone: string;
  state: string | null;
  transit: string;
  vendorCountry: string;
  vendorName: string | null;
  zip: string | null;
};

export const enum TransferType {
  local = 'local',
  swift = 'swift',
}

type CurrencyInfoType<T> = Record<
  TransferType,
  {
    label: string;
    fields: Array<{ key: string; label: string; constant?: string; bankAccountInfoField?: boolean } & T>;
  }
>;

export type BeneficiaryBankInfo = Record<Lowercase<Currency>, CurrencyInfoType<{ validation: string }>>;

export type BeneficiaryFormType = BeneficiaryBaseType & {
  paymentMethods: BeneficiaryPaymentMethod[] | null;
  paymentMethodsError?: string;
};

export enum PaymentMethodsType {
  awPayment = 'aw_payment',
  awTransfer = 'aw_transfer',
}

export type BeneficiaryPaymentMethodsAWPayment = {
  type: PaymentMethodsType.awPayment;
  details: {
    awPaymentMethod: TransferType;
    label: string;
    fields: Array<{ key: string; label: string; value: string }>;
  };
};

export type BeneficiaryPaymentMethodsAWTransfer = {
  type: PaymentMethodsType.awTransfer;
  details: {
    finofoTag: string;
  };
};

export type BeneficiaryPaymentMethod = BeneficiaryPaymentMethodsAWPayment | BeneficiaryPaymentMethodsAWTransfer;

export type BeneficiaryType = BeneficiaryBaseType & {
  id: string;
  status: string;
  lastPayment: {
    id: string;
    amount: number;
    paymentDate: Dayjs;
  } | null;
  openBalance: number;
  approvalStatus: BeneficiaryApprovalStatus;
  paymentMethods: BeneficiaryPaymentMethod[];
};

export type DraftBeneficiary = BeneficiaryFormType & {
  id: string;
  batchId: string;
  batchRowIndex: number;
  initialData: BeneficiaryBaseType;
  errors: Array<{ errorCode: number; field: keyof BeneficiaryBaseType; message: string }>;
  shouldPromote: boolean | null;
  phone: null | string;
  creationDate: string;
  updateDate: string;
  businessId: string;
};

type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

type Ensure<T, K extends keyof T> = Omit<T, K> & RequiredNotNull<Pick<T, K>>;

export type PreparedDraftBeneficiary = Pick<
  Ensure<DraftBeneficiary, 'shouldPromote' | 'phone'>,
  keyof BeneficiaryFormType | 'shouldPromote' | 'phone'
>;

export enum BeneficiaryApprovalStatus {
  approved = 'APPROVED',
  pending = 'PENDING',
  rejected = 'REJECTED',
  draft = 'DRAFT',
}

export const BeneficiaryApprovalStatusLabels: Record<BeneficiaryApprovalStatus, string> = {
  [BeneficiaryApprovalStatus.approved]: 'Active',
  [BeneficiaryApprovalStatus.pending]: 'Pending Approval',
  [BeneficiaryApprovalStatus.rejected]: 'Declined',
  [BeneficiaryApprovalStatus.draft]: 'Draft',
};

export const beneficiaryStatusBadgeMapping: Record<BeneficiaryApprovalStatus, { color: string; icon: IconNames }> = {
  [BeneficiaryApprovalStatus.approved]: { color: 'gray', icon: 'check' },
  [BeneficiaryApprovalStatus.pending]: { color: 'blue', icon: 'pending' },
  [BeneficiaryApprovalStatus.rejected]: { color: 'red', icon: 'close' },
  [BeneficiaryApprovalStatus.draft]: { color: 'gray', icon: 'draft' },
};

export type DraftBeneficiaryBatch = {
  id: string;
  name: string;
  creationDate: string;
  updateDate: string;
  draftBeneficiaryCount: number;
};

export type BulkImport<C extends { id: string }, E extends { id: string }> = {
  id: string;
  name: string;
  totalCount: number;
  completedCount: number;
  completedObjectIds: Array<C['id']> | null;
  erroredCount: number;
  erroredObjectIds: Array<E['id']> | null;
  errors: [];
  creationDate: string;
  updateDate: string;
  completionDate: string;
};

export type DraftBillBatch = {
  id: string;
  name: string;
  creationDate: string;
  updateDate: string;
  draftApBillCount: number;
};
