import { extendTheme } from '@chakra-ui/react';

import { CalendarDefaultTheme } from 'azdatepicker';

import { colors } from './colors';
import { Avatar } from './components/Avatar';
import { Badge } from './components/Badge';
import { Button } from './components/Button';
import { Calendar } from './components/Calendar';
import { Checkbox } from './components/Checkbox';
import { Divider } from './components/Divider';
import { Drawer } from './components/Drawer';
import { Form } from './components/Form';
import { FormError } from './components/FormError';
import { FormLabel } from './components/FormLabel';
import { Heading } from './components/Heading';
import { Input } from './components/Input';
import { Link } from './components/Link';
import { List } from './components/List';
import { Modal } from './components/Modal';
import { Progress } from './components/Progress';
import { Radio } from './components/Radio';
import { Select } from './components/Select';
import { Skeleton } from './components/Skeleton';
import { Switch } from './components/Switch';
import { Table } from './components/Table';
import { Tabs } from './components/Tabs';
import { Tag } from './components/Tag';
import { Text } from './components/Text';
import { Textarea } from './components/Textarea';
import { Tooltip } from './components/Tooltip';

export const theme = extendTheme(CalendarDefaultTheme, {
  fonts: {
    heading: `'DM Sans', sans-serif`,
    body: `'DM Sans', sans-serif`,
  },
  lineHeights: {
    base: 1.3,
  },
  breakpoints: {
    '2xs': '22em',
    xs: '30em',
    '2xl': '85em',
    '3xl': '96em',
  },
  radii: {
    md: '4px',
  },
  colors,
  styles: {
    global: {
      'html body': {
        mr: '0 !important', // hack to fix shifting because of drawer
        '--chakra-colors-chakra-border-color': colors.borderStroke,
      },
      'p div': {
        color: 'red !important',
        background: 'red !important',
      },
      body: {
        color: colors.contentPrimary,
      },
      '#root': {
        backgroundColor: colors.bg['0'],
      },
      '[id^=plaid-link-iframe]': {
        opacity: 0,
        transition: 'opacity 0.3s',
      },
      '[id^=plaid-link-iframe].visible': {
        opacity: 1,
      },
      '.pac-container': {
        zIndex: '1000051 !important',
      },
      'a[disabled]': {
        pointerEvents: 'none',
      },
      '.chakra-tooltip__arrow': {
        background: 'transparent !important',
        borderWidth: '6px 6px 0 6px',
        borderColor: `${colors.brand['500']}e5 transparent transparent transparent`,
      },
      '[data-popper-placement*="bottom"] .chakra-tooltip__arrow': {
        top: '-6px !important',
        transform: 'rotate(180deg) !important',
      },
      '[data-popper-placement*="top"] .chakra-tooltip__arrow': {
        top: 'unset !important',
        bottom: '-6px !important',
        transform: 'rotate(0deg) !important',
      },
      '[data-popper-placement*="right"] .chakra-tooltip__arrow': {
        transform: 'rotate(90deg) !important',
        left: '-7px !important',
      },
      '[data-popper-placement*="left"] .chakra-tooltip__arrow': {
        left: 'unset !important',
        transform: 'rotate(-90deg) !important',
        right: '-7px !important',
      },
      '@keyframes bg-position-animation': {
        '0%': {
          backgroundPosition: '0% 50%',
        },
        '50%': {
          backgroundPosition: '100% 50%',
        },
        '100%': {
          backgroundPosition: '0% 50%',
        },
      },
    },
  },
  components: {
    Avatar,
    Badge,
    Button,
    Checkbox,
    Divider,
    Drawer,
    Form,
    FormError,
    FormLabel,
    Heading,
    Input,
    Link,
    List,
    Modal,
    Progress,
    Radio,
    Select,
    Skeleton,
    Switch,
    Table,
    Tabs,
    Tag,
    Text,
    Textarea,
    Tooltip,
    ...Calendar,
  },
});
