import React, { useEffect, useRef, useState } from 'react';

import { Text } from '@chakra-ui/react';

import { create } from 'zustand';

export type RefreshRateNameSpace = 'convertCurrencies' | 'payment';

type RefreshRateState = {
  refreshAt: number | null;
  nameSpace: RefreshRateNameSpace | null;
  reset: (nameSpace: RefreshRateNameSpace) => void;
};

const initialInterval = 45;

export const useRefreshRateStore = create<RefreshRateState>((set) => ({
  refreshAt: null,
  nameSpace: null,
  reset: (nameSpace) => set({ refreshAt: +new Date() + initialInterval * 1000, nameSpace }),
}));

type CountdownTimerProps = {
  onRefresh: () => void;
  isActive: boolean;
  nameSpace: RefreshRateNameSpace;
};

export const CountdownTimer = (props: CountdownTimerProps) => {
  const { reset, refreshAt, nameSpace } = useRefreshRateStore();

  const getCurrentDiff = React.useCallback(
    () => (refreshAt ? Math.round((refreshAt - +new Date()) / 1000) : 0),
    [refreshAt],
  );

  const [seconds, setSeconds] = useState<number | null>(getCurrentDiff());
  const intervalId = useRef<null | ReturnType<typeof setInterval>>(null);

  const clear = () => {
    if (intervalId.current) clearInterval(intervalId.current);
  };

  useEffect(() => {
    if (!props.isActive) {
      clear();
      return;
    }

    if (!getCurrentDiff() || nameSpace !== props.nameSpace) {
      reset(props.nameSpace);
    } else {
      setSeconds(getCurrentDiff());

      clear();
      intervalId.current = setInterval(() => {
        const diff = getCurrentDiff();
        setSeconds(diff);

        if (diff < 1) {
          props.onRefresh();
          clear();
          reset(props.nameSpace);
        }
      }, 1000);

      return clear;
    }
  }, [refreshAt, props.isActive, props.nameSpace, props, getCurrentDiff, nameSpace, reset]);

  return (
    <Text color="brand.500" as="span" size="2xs">
      {seconds} sec
    </Text>
  );
};
