import React from 'react';

import { NuqsAdapter } from 'nuqs/adapters/react-router';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

(async () => {
  if (!('ResizeObserver' in window)) {
    // Loads polyfill asynchronously, only if required.
    const module = await import('resize-observer-polyfill');
    // @ts-expect-error no typings for ResizeObserver
    window.ResizeObserver = module.default;
  }
})();

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <NuqsAdapter>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </NuqsAdapter>
  </React.StrictMode>,
);
