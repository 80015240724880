import type { FunctionComponent } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { PaymentsReasons } from '@fin/types';
import { formatPrice, getBeneficiaryName } from '@fin/utils';

import type { PaymentSimulationTransaction } from '@app/types';

import { PaymentTransactionDetails } from '../TransactionDetails/PaymentTransactionDetails';

export const PaymentTransactionItem = ({
  transaction,
  as,
}: {
  transaction: PaymentSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const { amount, currency, relatedEntity } = transaction;

  const name = getBeneficiaryName(relatedEntity.beneficiary);
  const TransactionItemComponent = as || TransactionItem;

  const isBillPayment = relatedEntity.paymentReason === PaymentsReasons.BILL_PAYMENT;

  return (
    <TransactionItemComponent
      icon={isBillPayment ? 'transactionPayment' : 'transactionSendMoney'}
      title={
        <Text variant="primary">{`${isBillPayment ? 'Bill Payment' : 'Money Sent'} to ${name.substring(0, 25)}${
          name.length > 25 ? '...' : ''
        }`}</Text>
      }
      iconColor="brandGreen.500"
      amount={
        <Flex alignItems="center" gap={1}>
          <Text size="inherit" variant="number" color={amount < 0 ? 'inherit' : 'brandGreen.500'}>{`${
            amount < 0 ? '-' : '+'
          }${formatPrice(Math.abs(amount))}`}</Text>
          <Badge variant="textual">{currency}</Badge>
        </Flex>
      }
      onClick={() => NiceModal.show(PaymentTransactionDetails, { payment: relatedEntity })}
    />
  );
};
