import { UserButton as ClerkUserButton } from '@clerk/clerk-react';
import type { UserButtonProps } from '@clerk/types';

import { colors } from '@fin/theme';

export const UserButton = (props: UserButtonProps) => {
  return (
    <ClerkUserButton
      appearance={{
        elements: {
          avatarBox: {
            width: 32,
            height: 32,
            '> span': {
              display: 'none',
            },
          },
          userPreview: {
            padding: '12px 16px',
            gap: 12,
          },
          userButtonPopoverActions: {
            paddingBottom: 8,
          },
          userButtonPopoverActionButton: {
            padding: '12px 16px',
            gap: 11,
            fontSize: 16,
            '&:hover': {
              backgroundColor: colors.brand['100'],
            },
          },
          userButtonPopoverActionButtonIconBox: {
            marginLeft: -3,
          },
          userButtonPopoverActionButtonIcon: {
            width: 20,
            height: 20,
            color: colors.contentSecondary,
          },
          userPreviewMainIdentifier: {
            color: colors.contentPrimary,
          },
          userPreviewSecondaryIdentifier: {
            color: colors.contentSecondary,
            fontSize: 14,
          },
          userButtonPopoverCard: {
            borderRadius: 4,
            boxShadow: '0px 4px 12px 0px rgba(29, 29, 32, 0.06)',
            border: `1px solid ${colors.borderStroke}`,
          },
          userButtonPopoverMain: {
            borderRadius: 4,
          },
        },
      }}
      {...props}
    />
  );
};
