import { forwardRef, type ReactNode } from 'react';

import { Divider } from '@chakra-ui/react';

import { chakraComponents } from 'chakra-react-select';

import { type Option, Select, type SelectProps } from '../Select';

type SelectWithActionProps = SelectProps<Option> & {
  actionBtn: ReactNode;
};

export const SelectWithAction = forwardRef<HTMLDivElement, SelectWithActionProps>(({ actionBtn, ...rest }, ref) => {
  return (
    <Select
      ref={ref}
      {...rest}
      components={{
        MenuList: ({ children, ...props }: any) => {
          return (
            <chakraComponents.MenuList {...props}>
              {children}
              <Divider />
              {actionBtn}
            </chakraComponents.MenuList>
          );
        },
      }}
    />
  );
});

SelectWithAction.displayName = 'SelectWithAction';
