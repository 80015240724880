import type { BusinessConfig, BusinessConfigPaymentMethod, BusinessConfigRule } from '@fin/types';

import { api } from '@app/network';

export const DDConfigService = {
  createRule: (
    businessId: BusinessConfig['id'],
    data: {
      name: string;
      description: string;
      outsideGlobalLimits: boolean;
      dailyTransactionLimit: number;
      singleTransactionLimit: number;
    },
  ) => api.post<{ rule: BusinessConfigRule }>(`/admin/direct_debit/rules/businesses/${businessId}`, data),
  updateRule: (
    businessId: BusinessConfig['id'],
    data: {
      name: string;
      description: string;
      outsideGlobalLimits: boolean;
      dailyTransactionLimit: number;
      singleTransactionLimit: number;
    },
  ) => api.put<{ rule: BusinessConfigRule }>(`/admin/direct_debit/rules/businesses/${businessId}`, data),
  updateConfig: (businessId: BusinessConfig['id'], data: { enabled: boolean; ruleId: BusinessConfigRule['id'] }) =>
    api.put<{ rule: BusinessConfigRule }>(`/admin/businesses/${businessId}/direct_debit/configuration`, data),
  updatePaymentMethods: (businessId: BusinessConfig['id'], data: Array<BusinessConfigPaymentMethod['id']>) =>
    api.put<{ rule: BusinessConfigRule }>(`/admin/direct_debit/rules/businesses/${businessId}/payment_methods`, {
      paymentMethods: data,
    }),
};
