import { Box, type BoxProps } from '@chakra-ui/react';

import { type Currency, Logger } from '@fin/shared';

import type { FlagNames } from './FlagNames';
import sprite from './flags_sprite.svg';

export type FlagProps = BoxProps & { width: number } & (
    | { country: Uppercase<FlagNames>; currency?: never }
    | { country?: never; currency: Currency }
  );

const aspectRatio = 32 / 24;

export const Flag = ({ country, currency, width, ...rest }: FlagProps) => {
  let flagName;

  if (country) {
    flagName = country.toLowerCase();
  }

  if (currency) {
    flagName = currency.slice(0, 2).toLowerCase();
  }

  if (!flagName) {
    Logger.error(`There is flag icon for ${country} or ${currency}`);
    return null;
  }

  return (
    <Box as="svg" width={`${width}px`} height={`${width / aspectRatio}px`} rounded={2} flexShrink={0} {...rest}>
      <use href={`${sprite}#${flagName}`} />
    </Box>
  );
};
