import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Avatar: ComponentStyleConfig = {
  sizes: {
    md: {
      container: {
        width: 9,
        height: 9,
      },
      label: {
        fontSize: 16,
      },
    },
    sm: {
      container: {
        width: 8,
        height: 8,
      },
      label: {
        fontSize: 16,
      },
    },
  },
  variants: {
    squared: {
      container: {
        rounded: 4,
      },
    },
  },
  baseStyle: {
    container: {
      background: 'bg.200',
      color: 'contentPrimary',
    },
  },
};
