import type { Appearance } from '@clerk/types';

import { colors } from '@fin/theme';

export const clerkGlobalAppearance: Appearance = {
  variables: {
    colorPrimary: 'hsl(240, 5%, 12%)',
    colorText: 'hsl(240, 5%, 12%)',
    colorTextSecondary: 'hsl(270, 1%, 44%)',
    colorDanger: 'hsl(360, 64%, 47%)',
    colorSuccess: 'hsl(150, 100%, 27%)',
    colorWarning: 'hsl(40, 96%, 53%)',
    fontSize: '16px',
    borderRadius: '4px',
  },
  elements: {
    cardBox: {
      boxShadow: 'none',
      borderRadius: '4px',
    },
    formFieldInput: {
      minHeight: 50,
      borderRadius: 4,
      '&:not([type="tel"]):focus-within': {
        boxShadow: `${colors.brand[500]} 0px 0px 0px 1px, ${colors.brand[500]} 0px 0px 1px 0px !important`,
      },
      '&[type="checkbox"]': {
        minHeight: 'auto',
        marginTop: 4,
        cursor: 'pointer',
      },
    },
    actionCard: {
      boxShadow: 'none',
      border: `1px solid ${colors.borderStroke}`,
    },
    table: {
      boxShadow: 'none',
      border: `1px solid ${colors.borderStroke}`,
      'tr:not(:first-of-type):hover': {
        backgroundColor: colors.bg['100'],
      },
      'tr > td': {
        padding: 8,
      },
      'tr > th:first-of-type': {
        paddingLeft: 12,
      },
    },
    avatarBox: {
      backgroundColor: 'transparent',
    },
    formFieldRadioLabel: {
      cursor: 'pointer',
    },
    otpCodeFieldInput: {
      '&:not([type="tel"]):focus-within': {
        boxShadow: `${colors.brand[500]} 0px 0px 0px 1px, ${colors.brand[500]} 0px 0px 1px 0px !important`,
      },
    },
    button: {
      fontSize: 14,
      '&[data-variant="solid"][data-color="primary"]': {
        backgroundColor: colors.brand[500],
        minHeight: 36,
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: colors.brand[400],
        },
        '&:active': {
          backgroundColor: colors.brand[600],
        },
        '::after': {
          display: 'none',
        },
        '&:disabled': {
          backgroundColor: colors.contentDisabled,
          opacity: 1,
          boxShadow: 'none',
        },
      },
    },
    formFieldInputShowPasswordButton: {
      padding: 8,
      '&:focus': {
        boxShadow: 'none',
      },
      '> svg': {
        width: 20,
        height: 20,
      },
    },
    formFieldLabel: {
      fontSize: 13,
    },
    formFieldAction: {
      fontSize: 13,
    },
    buttonArrowIcon: {
      display: 'none',
    },

    phoneInputBox: {
      '&:focus-within': {
        boxShadow: `${colors.brand[500]} 0px 0px 0px 1px, ${colors.brand[500]} 0px 0px 1px 0px !important`,
      },
    },
    selectSearchInput: {
      background: 'transparent',
      border: `1px solid ${colors.borderStroke}`,
    },
    navbar: {
      maxWidth: 250,
      width: 250,
      flexBasis: 250,
      background: colors.bg['0'],
    },
    navbarButton: {
      color: colors.contentSecondary,
      opacity: 1,
      '&:hover': {
        background: colors.bg['200'],
      },
      '&[data-color="primary"]': {
        background: colors.content50,
        color: colors.contentPrimary,
      },
    },
    organizationAvatarUploaderContainer: {
      '& button[data-variant="ghost"][data-color="primary"]': {
        backgroundColor: 'transparent',
        borderColor: colors.brand['500'],
      },
    },
    avatarImageActionsUpload: {
      minHeight: 36,
      color: colors.contentPrimary,
      '&[data-variant="outline"]': {
        boxShadow: `${colors.borderStroke} 0px 0px 0px 1px, rgba(29, 29, 32, 0.05) 0px 1px 2px 0px`,
      },
    },
    userButtonTrigger: {
      '&:focus': {
        boxShadow: `${colors.borderStroke} 0px 0px 0px 4px`,
      },
    },
    formFieldRow__slug: {
      display: 'none',
    },
    pageScrollBox: {
      padding: 16,
    },
    selectButton: {
      color: colors.contentPrimary,
      '&[data-variant="outline"]:focus': {
        boxShadow: `${colors.borderStroke} 0px 0px 0px 4px`,
      },
    },
    selectOptionsContainer: {
      borderRadius: 4,
    },
    selectOption: {
      borderRadius: 2,
      p: {
        color: colors.contentPrimary,
      },
      '&:hover': {
        backgroundColor: colors.brand['50'],
      },
      '&[data-focused="true"]': {
        backgroundColor: colors.brand['100'],
        p: {
          color: colors.contentPrimary,
        },
      },
    },
    badge: {
      borderRadius: '2px',
    },
    notificationBadge: {
      animation: 'none',
    },
  },
};
