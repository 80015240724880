import { Fragment, type MouseEvent } from 'react';

import { Box, type BoxProps, Flex } from '@chakra-ui/react';

import type { TableProps } from './types';
import { isRowClickable } from './utils';

type TableHeaderProps<T> = Pick<TableProps<T>, 'rowProps' | 'columns' | 'keyProp' | 'subTable' | 'subTableProps'> & {
  item: T;
  entities: T[];
  rowIndex: number;
  rowRef?: any;
} & BoxProps;

export const TableRow = <T,>({
  rowProps,
  item,
  entities,
  columns,
  keyProp,
  rowIndex,
  subTable,
  subTableProps,
  rowRef,
  ...rest
}: TableHeaderProps<T>) => {
  const _rowProps = typeof rowProps === 'function' ? rowProps(item) : rowProps;
  const onRowClick = (e: MouseEvent<HTMLDivElement>) => {
    if (isRowClickable(e.target, e.currentTarget)) _rowProps?.onClick?.(e);
  };

  const isBorderHidden = entities.length - 1 === rowIndex || subTable?.(item);

  return (
    <Fragment>
      <Box
        display="grid"
        gridTemplateColumns="subgrid"
        gridColumn="1/-1"
        borderBottom={`${isBorderHidden ? 0 : '1px'} solid`}
        borderBottomColor="borderStroke"
        className="row"
        bg="bg.0"
        maxH="100%"
        {..._rowProps}
        onClick={(e) => onRowClick(e)}
        {...rest}
        ref={rowRef}
      >
        {columns.map((column, i, arr) => {
          const cellProps = typeof column.cellProps === 'function' ? column.cellProps(item) : column.cellProps;

          const cn = `cell ${i === 0 ? 'first' : ''} ${arr.length - 1 === i ? 'last' : ''} ${
            column.nonClickable ? 'nonClickable' : ''
          } ${cellProps?.className || ''}`;

          return (
            <Flex
              key={`${item[keyProp] as string}_${column.title || ''}_${i}`}
              alignItems="center"
              p={2}
              {...cellProps}
              className={cn}
              onClick={(e) => column.onClick?.(item, e)}
              cursor={column.onClick ? 'pointer' : 'default'}
              data-colum-index={i}
            >
              {column.renderCell(item, i)}
            </Flex>
          );
        })}
      </Box>
      {subTable?.(item) && (
        <Box
          gridColumn="1/-1"
          bg="bg.100"
          pb={2}
          borderBottom={`${entities.length - 1 === rowIndex ? 0 : '1px'} solid`}
          borderBottomColor="borderStroke"
          pos="sticky"
          left={0}
          w="100cqw"
          {...subTableProps}
        >
          {subTable(item)}
        </Box>
      )}
    </Fragment>
  );
};
