import { Logger } from '@fin/shared';

import { getEntries } from './common';
import json from './countriesData.json';

type CountriesData = Array<[string, string, Array<[string, string]>]>;

const countriesData = json as CountriesData;

const preferredCountries = new Set(['CA', 'US']);

export const countries: Record<string, string> = Object.fromEntries(
  countriesData
    .map((country) => [country[0], country[1]])
    .sort((a, b) => {
      const aIsPreferred = preferredCountries.has(a[0]);
      const bIsPreferred = preferredCountries.has(b[0]);

      if (aIsPreferred && !bIsPreferred) {
        return -1;
      } else if (!aIsPreferred && bIsPreferred) {
        return 1;
      }

      return 0;
    }),
);

export const countriesWithStates = Object.fromEntries(
  countriesData.map((country) => [
    country[0],
    {
      name: country[1],
      code: country[0],
      states: country[2].map((state) => ({
        name: state[1],
        value: state[0],
        code: `${country[0]}-${state[0]}`,
      })),
    },
  ]),
);

export const prepareCounty = (rawCountry: string) => {
  if (rawCountry in countries) return rawCountry;

  if (/^[A-Z]{3}$/.test(rawCountry)) return rawCountry.slice(0, 2);

  const countryByFullName = getEntries(countries).find(([_, name]) => name === rawCountry);

  if (countryByFullName) {
    return countryByFullName[0];
  }

  Logger.error(`unexpected country format: ${rawCountry}`);
  return '';
};
