import { tabsAnatomy } from '@chakra-ui/anatomy';
import { type ComponentStyleConfig, createMultiStyleConfigHelpers } from '@chakra-ui/react';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const contained = definePartsStyle({
  tab: {
    border: '1px solid',
    borderColor: 'borderStroke',
    rounded: 4,
    bg: 'brand.50',
    px: 3,
    py: 2,
    fontSize: '16px',
    '--tab-accent-color': 'var(--chakra-colors-contentSecondary)',
    _hover: {
      bg: 'brand.50',
      borderColor: 'brand.200',
    },
    _selected: {
      borderColor: 'brand.500',
      bg: 'brand.50',
      '--tab-accent-color': 'var(--chakra-colors-brand-500)',
    },
  },
  tablist: {
    gap: 2,
  },
});

const radio = definePartsStyle({
  tab: {
    border: '1px solid',
    borderColor: 'borderStroke',
    rounded: 4,
    px: 4,
    py: 3,
    fontSize: '14px',
    '--tab-accent-color': 'var(--chakra-colors-brand-200)',
    _hover: {
      bg: 'brand.50',
      borderColor: 'brand.200',
    },
    _selected: {
      borderColor: 'brand.500',
      bg: 'brand.50',
      '--tab-accent-color': 'var(--chakra-colors-brand-500)',
    },
  },
  tablist: {
    gap: 2,
  },
});

export const Tabs: ComponentStyleConfig = {
  variants: {
    line: {
      tablist: {
        borderBottomColor: 'transparent',
        gap: 6,
      },
      tab: {
        color: 'contentSecondary',
        bg: 'transparent',
        px: 0,
        py: 1,
        fontSize: 16,
        fontWeight: 500,
        _hover: {
          color: 'contentPrimary',
          borderColor: 'contentTertiary',
        },
        _selected: {
          color: 'brand.500',
          _hover: {
            color: 'brand.500',
            borderColor: 'brand.500',
          },
        },
      },
    },
    contained,
    radio,
  },
  defaultProps: {
    size: 'lg',
    colorScheme: 'brand',
  },
};
