import { currencies as currenciesShared, type Currency as CurrencyShared } from '@fin/shared';

export { type CurrencyShared as Currency, currenciesShared as currencies };

// names taken from https://salsa.debian.org/iso-codes-team/iso-codes/-/blob/main/iso_4217/fr.po
export const currenciesNames: Record<CurrencyShared, string> = {
  AED: 'UAE Dirham',
  AOA: 'Angolan Kwanza',
  AUD: 'Australian Dollar',
  BWP: 'Botswanan Pula',
  CAD: 'Canadian Dollar',
  CHF: 'Swiss Franc',
  CLP: 'Chilean Peso',
  CNY: 'Chinese Yuan',
  CZK: 'Czech Koruna',
  DKK: 'Danish Krone',
  EGP: 'Egyptian Pound',
  EUR: 'Euro',
  GBP: 'Pound Sterling',
  GMD: 'Gambian Dalasi',
  HKD: 'Hong Kong Dollar',
  HUF: 'Hungarian Forint',
  IDR: 'Indonesian Rupiah',
  ILS: 'Israeli Shekel',
  INR: 'Indian Rupee',
  JPY: 'Japanese Yen',
  KES: 'Kenyan Shilling',
  KRW: 'Korean Won',
  LKR: 'Sri Lankan Rupee',
  LSL: 'Lesotho Loti',
  MAD: 'Moroccan Dirham',
  MGA: 'Malagasy Ariary',
  MWK: 'Malawian Kwacha',
  MXN: 'Mexican Peso',
  MYR: 'Malaysian Ringgit',
  NAD: 'Namibian Dollar',
  NGN: 'Nigerian Naira',
  NOK: 'Norwegian Krone',
  NPR: 'Nepalese Rupee',
  NZD: 'New Zealand Dollar',
  PHP: 'Philippine Peso',
  PLN: 'Polish Zloty',
  PYG: 'Paraguayan Guarani',
  RON: 'Romanian Leu',
  RWF: 'Rwandan Franc',
  SAR: 'Saudi Riyal',
  SEK: 'Swedish Krona',
  SGD: 'Singapore Dollar',
  THB: 'Thai Baht',
  TRY: 'Turkish Lira',
  USD: 'US Dollar',
  UYU: 'Uruguayan Peso',
  VND: 'Vietnamese Dong',
  XAF: 'Central African CFA Franc',
  XOF: 'West African CFA Franc',
  ZAR: 'South African Rand',
  ZMW: 'Zambian Kwacha',
};

export const mainCurrencies: CurrencyShared[] = ['CAD', 'USD'];
export const supportedAccountCurrencies: CurrencyShared[] = [
  'CAD',
  'USD',
  'EUR',
  'GBP',
  'AUD',
  'AED',
  'CHF',
  'CNY',
  'CZK',
  'DKK',
  'HKD',
  'HUF',
  'ILS',
  'JPY',
  'MXN',
  'NOK',
  'NZD',
  'PLN',
  'RON',
  'SEK',
  'SGD',
  'ZAR',
];

export const supportedCreditCardCurrencies: CurrencyShared[] = [
  'CAD',
  'USD',
  'EUR',
  'GBP',
  'CHF',
  'AUD',
  'HKD',
  'JPY',
  'SGD',
  'NZD',
];
