import useSWR from 'swr';

import type { CompanyInfoType } from '@fin/types';

export type CCConfig = { id: string; businessId: CompanyInfoType['id']; enabled: boolean };

export const useCCConfigs = () => {
  const { data, isLoading, mutate } = useSWR<{
    configs: CCConfig[];
  }>('/admin/card_issuing/businesses/configuration');

  return {
    CCConfigs: data?.configs,
    CCConfigsIsLoading: isLoading,
    mutateCCConfigs: mutate,
  };
};
