import React from 'react';

import { Button, Divider, SimpleGrid } from '@chakra-ui/react';

import { generatePath, Link as RRLink, useParams } from 'react-router-dom';

import { Icon } from '@fin/icons';
import { CompanyStatus, KYCBusinessStatus } from '@fin/types';

import { useBusinessDetails, useKYCDetails } from '@app/hooks';
import { routes } from '@app/routing/routes';

export const DemoToolsList = () => {
  const params = useParams<{ kycId: string; businessId: string }>();

  const { kycDetails } = useKYCDetails(params?.kycId);
  const { businessDetails } = useBusinessDetails(params?.businessId);

  return (
    <>
      <SimpleGrid columns={3} spacingX={4} spacingY={4}>
        <Button
          variant="secondary"
          px={6}
          py={4}
          minH="80px"
          isDisabled={
            !kycDetails ||
            kycDetails.kycStatus === KYCBusinessStatus.UNDER_VERIFICATION ||
            businessDetails?.status === CompanyStatus.ACTIVE
          }
          as={RRLink}
          to={generatePath(routes.demoToolSkipKYC, { kycId: params.kycId, businessId: params.businessId })}
        >
          Skip KYC
        </Button>
        <Button
          variant="secondary"
          px={6}
          py={4}
          minH="80px"
          as={RRLink}
          to={generatePath(routes.demoToolConnectAW, { kycId: params.kycId, businessId: params.businessId })}
          isDisabled={businessDetails?.status === CompanyStatus.ACTIVE}
        >
          Connect to AW
        </Button>
      </SimpleGrid>
      <Divider my={6} />
      <SimpleGrid columns={3} spacingX={4} spacingY={4}>
        <Button
          variant="secondary"
          minH="80px"
          leftIcon={<Icon name="beneficiaries" size={18} />}
          as={RRLink}
          to={generatePath(routes.demoToolBeneficiaries, { kycId: params.kycId, businessId: params.businessId })}
          isDisabled={!businessDetails || businessDetails.status !== CompanyStatus.ACTIVE}
        >
          Beneficiaries
        </Button>
        <Button
          variant="secondary"
          minH="80px"
          leftIcon={<Icon name="transactions" size={18} />}
          as={RRLink}
          to={generatePath(routes.demoToolTransactions, { kycId: params.kycId, businessId: params.businessId })}
          isDisabled={!businessDetails || businessDetails.status !== CompanyStatus.ACTIVE}
        >
          Transactions
        </Button>
      </SimpleGrid>
    </>
  );
};
