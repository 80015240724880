import type { IconNames } from '@fin/icons';
import type { Currency } from '@fin/shared';

import type { DDBankAccount, Withdrawal } from './bank';
import { type BeneficiaryType } from './beneficiary';
import type { CardTransaction } from './card';
import { type Conversion, type Payment } from './payment';
import type { Transfer } from './transfer';

// sourceType:
//   | 'PAYOUT'
//   | 'PAYMENT'
//   | 'WITHDRAWAL'
//   | 'CONVERSION'
//   | 'DEPOSIT'
//   | 'ADJUSTMENT'
//   | 'FEE'
//   | 'PAYMENT_ATTEMPT'
//   | 'REFUND'
//   | 'DISPUTE'
//   | 'CHARGE'
//   | 'TRANSFER'
//   | 'YIELD'
//   | 'BATCH_PAYOUT'
//   | 'CARD_PURCHASE'
//   | 'CARD_REFUND'
//   | 'PURCHASE'
//   | 'REFUND_REVERSAL';

type Transaction = {
  id: string;
  businessId: string;
  shortReference: string;
  cardLastFourDigits: string | null;
  cardholderName: string | null;
  merchant: string | null;
  currencyPair: string | null;
  sellCurrency: Currency;
  sellAmount: number | null;
  buyCurrency: Currency;
  buyAmount: number | null;
  clientRate: number | null;
  payerName: string | null;
  payeeName: string | null;
  payeeAccountNumber: string | null;
  sourceSyncStatus: 'SYNCED' | 'NOT_REQUIRED' | 'MISSING';
  paymentId: string | null;
  withdrawalId: string | null;
  conversionId: string | null;
  fundsReceiptId: string | null;
  directDebitDepositId: string | null;
  cardIssuingTransactionId: string | null;
  transactionDate: string;
};

export enum TransactionSourceType {
  CONVERSION = 'CONVERSION',
  PAYMENT = 'PAYMENT',
  BILL_PAYMENT = 'BILL_PAYMENT',
  PAYOUT = 'PAYOUT',
  DEPOSIT = 'DEPOSIT',
  FUNDS_RECEIPT = 'FUNDS_RECEIPT',
  WITHDRAWAL = 'WITHDRAWAL',
  DIRECT_DEBIT_DEPOSIT = 'DIRECT_DEBIT_DEPOSIT',
  CARD_PURCHASE = 'CARD_PURCHASE',
  CARD_REFUND = 'CARD_REFUND',
  TRANSFER = 'TRANSFER',
  FEE = 'FEE',
}

export enum TransactionType {
  PAYOUT = 'PAYOUT',
  PAYIN = 'PAYIN',
  FEE = 'FEE',
  CONVERSION = 'CONVERSION',
  HOLD = 'HOLD',
  RELEASE = 'RELEASE',
  CAPTURE = 'CAPTURE',
  REFUND = 'REFUND',
  FAILURE = 'FAILURE',
  REVERSAL = 'REVERSAL',
  ADJUSTMENT = 'ADJUSTMENT',
  TRANSFER = 'TRANSFER',
}

export const enum TransactionDirection {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export const enum TransactionEntryStatus {
  PENDING = 'PENDING',
  SETTLED = 'SETTLED',
  CANCELLED = 'CANCELLED',
}

export const transactionEntryStatusLabels: Record<TransactionEntryStatus, string> = {
  [TransactionEntryStatus.PENDING]: 'Pending',
  [TransactionEntryStatus.SETTLED]: 'Settled',
  [TransactionEntryStatus.CANCELLED]: 'Cancelled',
};

export const transactionEntryStatusBadgeMapping: Record<TransactionEntryStatus, { color: string; icon: IconNames }> = {
  [TransactionEntryStatus.PENDING]: { color: 'blue', icon: 'pending' },
  [TransactionEntryStatus.SETTLED]: { color: 'gray', icon: 'check' },
  [TransactionEntryStatus.CANCELLED]: { color: 'red', icon: 'close' },
};

type TransactionEntry<T extends string> = {
  id: string;
  businessId: string;
  shortReference: string;
  status: TransactionEntryStatus;
  transactionEntryType: T;
  transactionDate: string;
  transactionAmount: number;
  transactionCurrency: Currency;
  transactionDirection: TransactionDirection;
  transactionCreatedAt: string;
  transactionSettledAt: string | null;
};

export type ConversionTransaction = Transaction & {
  sourceType: TransactionSourceType.CONVERSION;
  transactionType: TransactionType.CONVERSION;
  entries: [TransactionEntry<'CONVERSION_BUY'>, TransactionEntry<'CONVERSION_SELL'>];
  relatedObject: Conversion;
};

export type PaymentTransaction = Transaction & {
  payeeName: string;
  paymentId: string;
  sourceType: TransactionSourceType.PAYMENT | TransactionSourceType.BILL_PAYMENT;
  transactionType: TransactionType.PAYOUT | TransactionType.REVERSAL | TransactionType.FEE;
  entries: [TransactionEntry<'PAYOUT' | 'PAYOUT_REVERSAL' | 'FEE'>];
  relatedObject: Payment & {
    beneficiary: BeneficiaryType;
  };
};

export type PayoutTransaction = Transaction & {
  sourceType: TransactionSourceType.PAYOUT;
  transactionType: TransactionType.PAYOUT;
  entries: [TransactionEntry<'PAYOUT' | 'PAYOUT_REVERSAL'>];
  relatedObject: null;
};

export type InboundFundsTransaction = Transaction & {
  sourceType: TransactionSourceType.DEPOSIT;
  transactionType: TransactionType.PAYIN;
  entries: [TransactionEntry<'DEPOSIT'>];
  relatedObject: unknown;
};

export type DirectDeposit = {
  directDebitBankAccount: DDBankAccount;
  directDebitBankAccountId: string;
  amount: number;
  businessId: string;
  id: string;
  status: 'SETTLED' | 'REJECTED' | 'REVERSED';
};

export type DDDTransaction = Transaction & {
  sourceType: TransactionSourceType.DIRECT_DEBIT_DEPOSIT;
  transactionType: TransactionType.PAYIN | TransactionType.REVERSAL;
  entries: [TransactionEntry<'DEPOSIT' | 'DEPOSIT_REVERSAL'>];
  relatedObject: DirectDeposit;
};

export enum DDDTransactionStatus {
  'DEPOSIT',
  'DEPOSIT_REVERSAL',
  'PENDING',
  'CANCELLED',
}

export type FundsReceipt = {
  id: string;
  businessId: string;
  awId: string;
  sender: string;
  senderReference: string;
  currency: Currency;
  amount: number;
  receivedPaymentDate: string;
};

export type FundsReceiptTransaction = Transaction & {
  fundsReceiptId: string;
  sourceType: TransactionSourceType.FUNDS_RECEIPT;
  transactionType: TransactionType.PAYIN;
  entries: [TransactionEntry<'DEPOSIT'>];
  relatedObject: FundsReceipt & {
    senderSentEmails: string[];
  };
};

export type WithdrawalTransaction = Transaction & {
  sourceType: TransactionSourceType.WITHDRAWAL;
  transactionType: TransactionType.PAYOUT | TransactionType.REVERSAL;
  entries: [TransactionEntry<'PAYOUT_REVERSAL' | 'PAYOUT'>];
  relatedObject: Withdrawal;
};

export type CreditCardTransaction = Transaction & {
  sourceType: TransactionSourceType.CARD_PURCHASE | TransactionSourceType.CARD_REFUND;
  transactionType: TransactionType.PAYOUT | TransactionType.REVERSAL;
  entries: [
    TransactionEntry<
      'ISSUING_AUTHORISATION_HOLD' | 'ISSUING_AUTHORISATION_RELEASE' | 'ISSUING_CAPTURE' | 'ISSUING_REFUND'
    >,
  ];
  relatedObject: CardTransaction;
};

export type TransferTransaction = Transaction & {
  sourceType: TransactionSourceType.TRANSFER;
  transactionType: TransactionType.TRANSFER;
  entries: [TransactionEntry<'DC_DEBIT' | 'DC_CREDIT'>];
  relatedObject: Transfer | null;
};

export type FeeTransaction = Transaction & {
  sourceType: TransactionSourceType.FEE;
  transactionType: TransactionType.FEE;
  entries: [TransactionEntry<'FEE'>];
  relatedObject: unknown;
};

export type TransactionUnionType =
  | ConversionTransaction
  | PaymentTransaction
  | PayoutTransaction
  | InboundFundsTransaction
  | DDDTransaction
  | FundsReceiptTransaction
  | WithdrawalTransaction
  | CreditCardTransaction
  | TransferTransaction
  | FeeTransaction;

export type StatementTransaction = {
  id: string;
  businessId: string;
  shortReference: string;
  sourceId: string;
  sourceType: TransactionSourceType;
  status: 'SETTLED' | 'REJECTED' | 'REVERSED';
  transactionEntryType: string;
  transactionDate: string;
  transactionAmount: number;
  transactionDirection: 'DEBIT' | 'CREDIT';
  transactionCurrency: Currency;
  transactionCreatedAt: string;
  transactionSettledAt: string | null;
  description: string;
  currencyPair: null;
  sellCurrency: null;
  sellAmount: null;
  buyCurrency: null;
  buyAmount: null;
  clientRate: null;
  payerName: string;
  payeeName: null;
  payeeAccountNumber: null;
  merchant: unknown;
  cardLastFourDigits: string;
  cardholderName: string;
};
