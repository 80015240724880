import { type IconNames } from '@fin/icons';

import { type CompanyRegistrationType } from './business';

export enum KYCBusinessStatus {
  NOT_COMPLETED = 'NOT_COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  AWAITING_APPLICANT_IDV = 'AWAITING_APPLICANT_IDV',
  SIGNATORY_REVIEW = 'SIGNATORY_REVIEW',
  AWAITING_SIGNATORY_IDV = 'AWAITING_SIGNATORY_IDV',
  AWAITING_ALL_IDV = 'AWAITING_ALL_IDV',
  UNDER_VERIFICATION = 'UNDER_VERIFICATION',
  REQUIRES_MORE_INFORMATION = 'REQUIRES_MORE_INFORMATION',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  WAITLISTED = 'WAITLISTED',
}

export enum KYCUserIDVStatus {
  NOT_ASSIGNED = 'not_assigned',
  NOT_STARTED = 'not_started',
  NOT_REQUIRED = 'not_required',
  ACTIVE = 'active',
  SUCCESS = 'success',
  FAILED = 'failed',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  PENDING_REVIEW = 'pending_review',
}

export type KYCUser = {
  addressLine_1: string;
  addressLine_2: string;
  businessId: string;
  city: string;
  country: string;
  creationDate: string;
  dateOfBirth: string;
  emailAddress: string;
  firstName: string;
  id: string;
  idvClientUserId: string;
  idvIsPrimary: boolean;
  idvProviderId: string | null;
  idvStatus: KYCUserIDVStatus;
  isADirector: boolean;
  isAnAuthorizedSignatory: boolean;
  isAnOwner: boolean;
  isApplicant: boolean;
  isControlPerson: boolean;
  kycId: string;
  lastName: string;
  ownershipPercentage: number;
  postalCode: string;
  primaryPhone: string;
  province: string;
  title: string;
  authorizedSignatoryDeclaration: {
    businessId: string;
    userId: string;
    acceptedDate: string;
  } | null;
};

export enum CompanyLegalEntityType {
  CORPORATION = 'CORPORATION',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  TRUST = 'TRUST',
}

export type KYCStep =
  | 'CONTACT_INFORMATION'
  | 'PERSONAL_ADDRESS'
  | 'BUSINESS_DETAILS'
  | 'BUSINESS_DESCRIPTION'
  | 'COMPANY_INFORMATION'
  | 'COMPANY_DOCUMENTS'
  | 'COMPANY_LEADERSHIP'
  | 'COMPANY_OWNERSHIP'
  | 'COMPANY_SIGNATORY'
  | 'APPLICANT_REVIEW'
  | 'SIGNATORY_USER_REVIEW'
  | 'SIGNATORY_APPLICATION_REVIEW'
  | 'APPLICANT_IDV'
  | 'SIGNATORY_IDV'
  | 'SUBMIT';

export type KYCFormStep =
  | KYCStep
  | 'PERSONAL_ADDRESS'
  | 'COMPANY_OWNERSHIP_LIST'
  | 'COMPANY_SIGNATORY_LIST'
  | 'REVIEW'
  | 'IDV'
  | 'IDV_SUCCESS'
  | 'IDV_FAILED'
  | 'DECLARATION'
  | 'idle'
  | 'detectStep';

export type KYCBusiness = {
  id: string;
  creationDate: string;
  businessId: string;
  kycStatus: KYCBusinessStatus;
  companyName: string;
  companyTradingName: string;
  companyLegalEntityType: CompanyLegalEntityType;

  companyDateOfRegistration: string;
  companyAddressLine_1: string;
  companyAddressLine_2: string;
  companyCity: string;
  companyProvince: string | null;
  companyCountry: string;
  companyPostalCode: string | null;
  industryCode: string;
  companyWebsite: string | null;
  companyHasNoWebsite: boolean;
  companyAdditionalInformation: string | null;
  companyPrimaryPurpose: string;
  companyPrimarySourceOfFunds: string;
  companyEstimatedFrequency: null;
  companyEstimatedVolume: null;
  companyTransactionCountries: null;
  companyIncomingCurrencies: null;
  companyOutgoingCurrencies: null;
  companyHowTheyFoundFinofo: string;
  // ca based companies
  companyTypeOfRegistration: CompanyRegistrationType;
  companyProvinceOfRegistration: string | undefined;
  companyRegistrationNumber: string;
  companyCraBusinessNumber: string;
  // us based companies
  companyStateOfIncorporation: string;
  companyTypeOfCorporation: string;
  companyEin: string;

  step: KYCStep;
  fileNames: any;
  referrer: string | null;
  applicant: KYCUser;
  customerDemographics: string | null;
  corporateExpenses: string | null;
  productOrServicesSold: string | null;
};

export const KYCStatusLabels: Record<KYCBusinessStatus, string> = {
  [KYCBusinessStatus.NOT_COMPLETED]: 'Not completed',
  [KYCBusinessStatus.IN_PROGRESS]: 'Partially Completed',
  [KYCBusinessStatus.AWAITING_APPLICANT_IDV]: 'Awaiting applicant IDV',
  [KYCBusinessStatus.SIGNATORY_REVIEW]: 'Signatory review',
  [KYCBusinessStatus.AWAITING_SIGNATORY_IDV]: 'Awaiting signatory IDV',
  [KYCBusinessStatus.AWAITING_ALL_IDV]: 'Awaiting all IDV',
  [KYCBusinessStatus.UNDER_VERIFICATION]: 'Under Verification',
  [KYCBusinessStatus.REQUIRES_MORE_INFORMATION]: 'Requires more information',
  [KYCBusinessStatus.DECLINED]: 'Declined',
  [KYCBusinessStatus.APPROVED]: 'Verified',
  [KYCBusinessStatus.WAITLISTED]: 'On the waitlist',
};

export const KYCStatusBadgeMapping: Record<KYCBusinessStatus, { color: string; icon: IconNames; label: string }> = {
  [KYCBusinessStatus.NOT_COMPLETED]: {
    color: 'red',
    icon: 'error',
    label: KYCStatusLabels[KYCBusinessStatus.NOT_COMPLETED],
  },
  [KYCBusinessStatus.IN_PROGRESS]: {
    color: 'yellow',
    icon: 'pending',
    label: KYCStatusLabels[KYCBusinessStatus.IN_PROGRESS],
  },
  [KYCBusinessStatus.AWAITING_APPLICANT_IDV]: {
    color: 'yellow',
    icon: 'pending',
    label: KYCStatusLabels[KYCBusinessStatus.AWAITING_APPLICANT_IDV],
  },
  [KYCBusinessStatus.SIGNATORY_REVIEW]: {
    color: 'yellow',
    icon: 'pending',
    label: KYCStatusLabels[KYCBusinessStatus.SIGNATORY_REVIEW],
  },
  [KYCBusinessStatus.AWAITING_SIGNATORY_IDV]: {
    color: 'yellow',
    icon: 'pending',
    label: KYCStatusLabels[KYCBusinessStatus.AWAITING_SIGNATORY_IDV],
  },
  [KYCBusinessStatus.AWAITING_ALL_IDV]: {
    color: 'yellow',
    icon: 'pending',
    label: KYCStatusLabels[KYCBusinessStatus.AWAITING_ALL_IDV],
  },
  [KYCBusinessStatus.UNDER_VERIFICATION]: {
    color: 'blue',
    icon: 'pending',
    label: KYCStatusLabels[KYCBusinessStatus.UNDER_VERIFICATION],
  },
  [KYCBusinessStatus.REQUIRES_MORE_INFORMATION]: {
    color: 'yellow',
    icon: 'info',
    label: KYCStatusLabels[KYCBusinessStatus.REQUIRES_MORE_INFORMATION],
  },
  [KYCBusinessStatus.DECLINED]: { color: 'red', icon: 'close', label: KYCStatusLabels[KYCBusinessStatus.DECLINED] },
  [KYCBusinessStatus.APPROVED]: { color: 'gray', icon: 'check', label: KYCStatusLabels[KYCBusinessStatus.APPROVED] },
  [KYCBusinessStatus.WAITLISTED]: {
    color: 'yellow',
    icon: 'info',
    label: KYCStatusLabels[KYCBusinessStatus.WAITLISTED],
  },
};

export type KYCFile = {
  businessId: string;
  fileSize: number;
  filename: string;
  id: string;
  kycId: string;
  type: 'articles_of_incorporation' | 'proof_of_funds' | 'proof_of_business_activity';
};

export enum WaitlistReason {
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  UNSUPPORTED_JURISDICTION = 'UNSUPPORTED_JURISDICTION',
}

export type Industry = {
  name: string;
  type: 'LIST';
  values: IndustryValue[];
};

type IndustryValue = CodeValue | QuestionValue;

type CodeValue = {
  name: string;
  type: 'CODE';
  value: string;
};

type QuestionValue = {
  name: string;
  type: 'QUESTION';
  question: string;
  values: AnswerValue[];
};

type AnswerValue = {
  answer: string;
  type: 'CODE';
  value: string;
};

export type CodeValueOption = CodeValue & { label: string; sector: string };
export type QuestionValueOption = QuestionValue & { label: string; value: string; sector: string };

export type IndustryOption = CodeValueOption | QuestionValueOption;
export type IndustryGroupOption = {
  label: string;
  options: IndustryOption[];
};
