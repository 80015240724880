import React, { useState } from 'react';

import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
} from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import type { KeyedMutator } from 'swr';

import { Select } from '@fin/components';
import { Icon } from '@fin/icons';
import { type CompanyInfoType } from '@fin/types';
import { modalProps } from '@fin/utils';

import { SimulationTransactionSourceType } from '@app/types';

import { SimulateCardAuthorizationForm } from './forms/SimulateCardAuthorizationForm';
import { SimulateFundsReceiptForm } from './forms/SimulateFundsReceiptForm';

type TransactionType = SimulationTransactionSourceType.FUNDS_RECEIPT | SimulationTransactionSourceType.CARD_PURCHASE;

type SimulateNewTransactionProps = {
  businessId: CompanyInfoType['id'];
  mutateTransactions: KeyedMutator<any>;
};

export const SimulateNewTransaction = NiceModal.create(
  ({ businessId, mutateTransactions }: SimulateNewTransactionProps) => {
    const modal = useModal();
    const [transactionType, setTransactionType] = useState<TransactionType | null>(null);

    const renderForm = () => {
      switch (transactionType) {
        case SimulationTransactionSourceType.FUNDS_RECEIPT:
          return (
            <SimulateFundsReceiptForm
              businessId={businessId}
              mutateTransactions={mutateTransactions}
              close={modal.hide}
            />
          );
        case SimulationTransactionSourceType.CARD_PURCHASE:
          return (
            <SimulateCardAuthorizationForm
              businessId={businessId}
              mutateTransactions={mutateTransactions}
              close={modal.hide}
            />
          );
        default:
          return null;
      }
    };

    return (
      <Drawer {...modalProps(modal)} placement="right" size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader as={Flex} px={4} py={3} alignItems="center" borderBottom="1px" borderBottomColor="borderStroke">
            <Flex alignItems="center" gap={2} mr="auto">
              <Heading>Simulate New Transaction</Heading>
            </Flex>
            <IconButton
              aria-label="Close Details"
              size="sm"
              variant="ghost"
              onClick={modal.hide}
              color="contentTertiary"
              ml={4}
            >
              <Icon name="close" size={24} />
            </IconButton>
          </DrawerHeader>
          <DrawerBody p={4} display="flex" flexDirection="column" gap={4}>
            <FormControl id="type">
              <FormLabel>Transaction Type</FormLabel>
              <Select<{ label: string; value: TransactionType }>
                options={[
                  { label: 'Funds Receipt', value: SimulationTransactionSourceType.FUNDS_RECEIPT },
                  { label: 'Card Authorization', value: SimulationTransactionSourceType.CARD_PURCHASE },
                ]}
                value={transactionType}
                onChange={setTransactionType}
              />
            </FormControl>
            <Divider my={2} />
            {renderForm()}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  },
);
