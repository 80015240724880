import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { type ComponentStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { colors } from '../colors';

// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

export const Modal: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      background: `${colors.brand['500']}28`,
    },
    closeButton: {
      color: 'contentTertiary',
    },
    header: {
      fontWeight: 500,
      px: { base: 4, md: 6 },
    },
    body: {
      px: { base: 4, md: 6 },
      py: 4,
    },
    footer: {
      px: { base: 4, md: 6 },
    },
  },
  sizes: {
    mobile: definePartsStyle({
      dialog: {
        maxW: '100vw',
        minH: '100svh',
        maxH: '100svh',
        rounded: 0,
        my: 0,
      },
    }),
    sm: {
      dialog: { mx: 2 },
    },
  },
};
