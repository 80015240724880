import useSWR from 'swr';

import { type KYCUser } from '@fin/types';

export const useKYCUsers = (id: string | undefined) => {
  const { data, isLoading } = useSWR<{ kycPeople: KYCUser[] }>(id ? `/admin/kyc/${id}/people` : null);

  return {
    kycUsers: data?.kycPeople,
    kycUsersIsLoading: isLoading,
  };
};
