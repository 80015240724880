import { Flex } from '@chakra-ui/react';

import { SignIn } from '@clerk/clerk-react';

import { clerkSignInAppearance } from '@fin/components';
import { deepMerge } from '@fin/utils';

export const Login = () => {
  return (
    <Flex minH="100vh" alignItems="center" justify="center" css={{ minHeight: '100svh' }} bg="#F5FAF3">
      <Flex direction="column" maxW="sm" w="full">
        <SignIn
          routing="hash"
          appearance={deepMerge(clerkSignInAppearance, {
            elements: {
              card: {
                padding: 16,
              },
              footer: {
                display: 'none',
              },
            },
          })}
        />
      </Flex>
    </Flex>
  );
};
