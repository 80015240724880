import type { FunctionComponent } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import NiceModal from '@ebay/nice-modal-react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { formatPrice, getBankAccountTitle } from '@fin/utils';

import type { WithdrawalSimulationTransaction } from '@app/types';

import { WithdrawalTransactionDetails } from '../TransactionDetails/WithdrawalTransactionDetails';

export const WithdrawalTransactionItem = ({
  transaction,
  as,
}: {
  transaction: WithdrawalSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const { amount, currency, relatedEntity } = transaction;

  const TransactionItemComponent = as || TransactionItem;

  return (
    <TransactionItemComponent
      icon="transactionWithdraw"
      title={
        <Text variant="primary">{`${currency} Withdrawal to ${getBankAccountTitle(relatedEntity.withdrawalBankAccount)}`}</Text>
      }
      iconColor="brandGreen.500"
      amount={
        <Flex alignItems="center" gap={1}>
          <Text size="inherit" variant="number" color={amount < 0 ? 'inherit' : 'brandGreen.500'}>{`${
            amount < 0 ? '-' : '+'
          }${formatPrice(Math.abs(amount))}`}</Text>
          <Badge variant="textual">{currency}</Badge>
        </Flex>
      }
      onClick={() => NiceModal.show(WithdrawalTransactionDetails, { transaction })}
    />
  );
};
