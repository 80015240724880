import React from 'react';

import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Icon } from '@fin/icons';
import { type BusinessMembership, RoleLabels } from '@fin/types';
import { modalProps } from '@fin/utils';

type ConfirmImpersonationProps = {
  onAccept: () => void;
  onCancel: () => void;
  membership: BusinessMembership;
};

export const ConfirmImpersonation = NiceModal.create<ConfirmImpersonationProps>(
  ({ onAccept, onCancel, membership }) => {
    const modal = useModal();

    const userName = `${membership.user.firstName} ${membership.user.lastName}`;

    const handleCancel = () => {
      onCancel();
      modal.hide();
    };

    const handleAccept = () => {
      onAccept();
      modal.hide();
    };

    return (
      <Modal {...modalProps(modal)} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" alignItems="center" gap={2} borderBottom="1px" borderBottomColor="borderStroke">
            <Icon name="impersonation" size={20} />
            <Heading>Impersonate {userName}?</Heading>
          </ModalHeader>
          <ModalCloseButton top={{ base: 2.5, md: 3.5 }} />
          <ModalBody>
            <Text>
              Are you sure you want to impersonate {userName} ({RoleLabels[membership.roles[0]]} at{' '}
              {membership.business.companyName})?
            </Text>
            <Flex gap={2} mt={4}>
              <Button flex={1} variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button flex={1} onClick={handleAccept}>
                Impersonate
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  },
);
