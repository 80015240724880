import { OrganizationSwitcher as ClerkOrganizationSwitcher } from '@clerk/clerk-react';
import type { OrganizationSwitcherProps } from '@clerk/types';

import { colors } from '@fin/theme';
import { deepMerge } from '@fin/utils';

export const OrganizationSwitcher = ({ appearance, ...rest }: OrganizationSwitcherProps) => {
  return (
    <ClerkOrganizationSwitcher
      hidePersonal={true}
      hideSlug
      {...rest}
      appearance={deepMerge(
        {
          elements: {
            rootBox: {
              minWidth: 0,
            },

            organizationSwitcherTrigger: {
              minWidth: 0,
              padding: 0,
              ':hover': {
                backgroundColor: colors.brand['100'],
              },
            },

            avatarBox: {
              width: 32,
              height: 32,
              borderRadius: 4,
              backgroundColor: 'transparent',
              '> span': {
                display: 'none',
              },
            },
            organizationPreview: {
              gap: 8,
            },
            organizationPreviewMainIdentifier: {
              color: colors.contentPrimary,
            },
            organizationPreview__organizationSwitcherActiveOrganization: {
              padding: '12px 8px 12px 16px',
              gap: 12,
            },
            organizationSwitcherPopoverActionButton__manageOrganization: {
              marginRight: '-10px',
              '&:hover': {
                backgroundColor: colors.brand['100'],
              },
            },
            organizationSwitcherPopoverActionButtonIcon__manageOrganization: {
              width: 20,
              height: 20,
              color: colors.contentSecondary,
            },
            organizationSwitcherPreviewButton: {
              padding: '12px 16px',
              gap: 8,
              '&:hover': {
                backgroundColor: colors.brand['100'],
              },
            },
            organizationSwitcherPopoverActionButton__createOrganization: {
              padding: '12px 16px',
              gap: 11,
              fontSize: 16,
              '& [class*="organizationSwitcherPopoverActionButtonIconBox"]': {
                marginLeft: -3,
              },
              '&:hover': {
                backgroundColor: colors.brand['100'],
              },
            },
            organizationPreviewSecondaryIdentifier: {
              color: colors.contentSecondary,
            },
            organizationSwitcherPopoverActionButtonIcon__createOrganization: {
              width: 30,
              height: 30,
              color: colors.contentSecondary,
            },
            organizationSwitcherPopoverCard: {
              borderRadius: 4,
              boxShadow: '0px 4px 12px 0px rgba(29, 29, 32, 0.06)',
              border: `1px solid ${colors.borderStroke}`,
            },
            organizationSwitcherPopoverMain: {
              borderRadius: 4,
            },
            organizationSwitcherPopoverInvitationActionsBox: {
              padding: '12px 16px',
              gap: 8,
              '& + [class*="organizationSwitcherPopoverInvitationActionsBox"]': {
                borderTop: '1px solid rgba(0, 0, 0, 0.07)',
              },
            },
            organizationSwitcherInvitationAcceptButton: {
              borderRadius: 4,
              '&[data-variant="outline"]': {
                boxShadow: `${colors.borderStroke} 0 0 0 1px, 0px 1px 2px 0px rgba(29, 29, 32, 0.05)`,
              },
            },
            notificationBadge: {
              borderRadius: 20,
              minWidth: '1rem',
              fontSize: 11,
              marginTop: 2,
              background: colors.brand['500'],
            },
          },
        },
        appearance || {},
      )}
    />
  );
};
