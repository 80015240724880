import { useCallback, useEffect, useMemo, useRef } from 'react';

import { type BoxProps } from '@chakra-ui/react';

type UseTableShadowProps = {
  loading: boolean | undefined;
  isMobile: boolean | undefined;
};

export const useTableShadow = ({ loading, isMobile }: UseTableShadowProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const ticking = useRef(false);

  const addShadows = useCallback(() => {
    const firstHeader = tableRef.current?.querySelector('.columnHeader.first');
    if (!tableRef.current || !firstHeader || !wrapperRef.current) return;

    const firstHeaderRect = firstHeader.getBoundingClientRect();
    const nextSibling = firstHeader.nextSibling;
    if (!nextSibling) return;

    const firstHeaderNextRect = (nextSibling as Element).getBoundingClientRect();
    const firstColumnItems = tableRef.current?.querySelectorAll('& .row > .first');

    if (Math.round(firstHeaderNextRect.left - firstHeaderRect.width - firstHeaderRect.left) < 0) {
      firstColumnItems?.forEach((i) => i.classList.add('column-shadow'));
    } else {
      firstColumnItems?.forEach((i) => i.classList.remove('column-shadow'));
    }

    const lastHeader = tableRef.current?.querySelector('.columnHeader.last');
    if (wrapperRef.current.getBoundingClientRect().right < lastHeader!.getBoundingClientRect().right) {
      wrapperRef.current?.classList.add('end-shadow');
    } else {
      wrapperRef.current?.classList.remove('end-shadow');
    }
  }, []);

  const removeShadows = useCallback(() => {
    const columnShadowItems = tableRef.current?.querySelectorAll('.column-shadow');
    columnShadowItems?.forEach((i) => i.classList.remove('column-shadow'));
    wrapperRef.current?.classList.remove('end-shadow');
  }, []);

  useEffect(() => {
    if (!loading && !isMobile) {
      addShadows();
    }
  }, [isMobile, loading, addShadows]);

  useEffect(() => {
    const onScroll = () => {
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          addShadows();
          ticking.current = false;
        });

        ticking.current = true;
      }
    };

    if (!tableRef || !wrapperRef) return;

    const currentTableRef = tableRef.current;

    if (isMobile) {
      removeShadows();
    } else {
      currentTableRef?.addEventListener('scroll', onScroll);
      addShadows();
    }

    return () => {
      currentTableRef?.removeEventListener('scroll', onScroll);
    };
  }, [isMobile, addShadows, removeShadows]);

  const tableShadowStyles: NonNullable<BoxProps['sx']> = useMemo(
    () => ({
      '&.end-shadow': {
        position: 'relative',
        overflow: 'hidden',
        '&::after': {
          content: '""',
          width: '1px',
          display: 'block',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: '-1px',
          boxShadow: '2px 0px 7px 0px rgb(0 0 0)',
          clipPath: 'inset(-10px 0 -10px -8px)',
        },
      },
    }),
    [],
  );

  const columnShadowStyles: NonNullable<BoxProps['sx']> = useMemo(
    () => ({
      '.column-shadow': {
        zIndex: 1,
        '&::after': {
          content: '""',
          width: '1px',
          display: 'block',
          position: 'absolute',
          right: 0,
          height: '100%',
          boxShadow: '-2px 0px 7px 0px rgb(0 0 0)',
          clipPath: 'inset(-10px -8px -10px 1px)',
        },
      },
    }),
    [],
  );

  return {
    tableRef,
    wrapperRef,
    tableShadowStyles,
    columnShadowStyles,
  };
};
