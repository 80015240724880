import {
  type CardTransaction,
  type CompanyInfoType,
  type DirectDeposit,
  type Payment,
  type Withdrawal,
} from '@fin/types';
import type { Currency } from '@fin/utils';

import { api } from '@app/network';

export type SimulateFundsReceiptFormType = {
  currency: Currency;
  amount: number;
  payerName: string;
  reference: string;
};

export type SimulateCardAuthorizationFormType = {
  cardId: string;
  transactionCurrency: Currency;
  transactionAmount: number;
  merchantDescription: string;
  merchantCategoryCode: string;
};

export const enum PaymentSimulationStatuses {
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export const enum DDDTransactionStatuses {
  reject = 'reject',
  reverse = 'reverse',
  settle = 'settle',
}

export type BusinessAddress = Pick<
  CompanyInfoType,
  'addressLine_1' | 'addressLine_2' | 'city' | 'administrativeArea' | 'postalCode'
>;

export type SkipKYCForm = BusinessAddress &
  Pick<CompanyInfoType, 'country' | 'companyNumber' | 'companyName' | 'email'>;

type UpdateBusinessInfo = SkipKYCForm &
  Pick<
    CompanyInfoType,
    | 'email'
    | 'companyName'
    | 'status'
    | 'registrationType'
    | 'administrativeAreaOfRegistration'
    | 'approvalStatus'
    | 'businessDescription'
  >;

export const SimulationService = {
  fundsReceipt: (businessId: CompanyInfoType['id'], { currency, ...rest }: SimulateFundsReceiptFormType) =>
    api.post(`/simulation/businesses/${businessId}/wallet/${currency}/funding_account/deposit`, rest),
  cardAuthorization: (businessId: CompanyInfoType['id'], { cardId, ...rest }: SimulateCardAuthorizationFormType) =>
    api.post(`/simulation/businesses/${businessId}/card_issuing/cards/${cardId}/authorization`, rest),
  captureCardTransaction: (
    businessId: CompanyInfoType['id'],
    transactionId: CardTransaction['id'],
    merchantDescription?: string,
  ) =>
    api.post(`/simulation/businesses/${businessId}/card_issuing/authorization/${transactionId}/capture`, {
      merchantDescription,
    }),
  changePaymentStatus: (
    businessId: CompanyInfoType['id'],
    paymentId: Payment['id'],
    nextStatus: PaymentSimulationStatuses,
  ) => api.post(`/simulation/businesses/${businessId}/payments/${paymentId}/transition`, { nextStatus }),
  changeWithdrawalStatus: (
    businessId: CompanyInfoType['id'],
    withdrawalId: Withdrawal['id'],
    nextStatus: PaymentSimulationStatuses,
  ) => api.post(`/simulation/businesses/${businessId}/withdrawals/${withdrawalId}/transition`, { nextStatus }),
  changeDDStatus: (
    businessId: CompanyInfoType['id'],
    depositId: DirectDeposit['id'],
    nextStatus: DDDTransactionStatuses,
  ) => api.post(`/simulation/businesses/${businessId}/direct_debit_deposits/${depositId}/${nextStatus}`),
  connectToAW: (businessId: CompanyInfoType['id']) => api.post(`/simulation/businesses/${businessId}/provider_account`),
  updateBusiness: (businessId: CompanyInfoType['id'], body: UpdateBusinessInfo) =>
    api.put(`/admin/businesses/${businessId}`, body),
  loadBeneficiaries: (businessId: CompanyInfoType['id']) =>
    api.post(`/simulation/businesses/${businessId}/beneficiaries/preset`),
};
