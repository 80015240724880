import useSWR from 'swr';

import { type KYCBusiness } from '@fin/types';

export const useKYCList = () => {
  const { data, isLoading } = useSWR<{ businessKycs: KYCBusiness[] }>('/admin/kyc');

  return {
    kycList: data?.businessKycs,
    kycListIsLoading: isLoading,
  };
};
