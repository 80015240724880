import type { FunctionComponent } from 'react';

import { Badge, Flex, Text } from '@chakra-ui/react';

import { TransactionItem, type TransactionItemProps } from '@fin/components';
import { formatPrice } from '@fin/utils';

import type { PayoutSimulationTransaction } from '@app/types';

export const PayoutTransactionItem = ({
  transaction,
  as,
}: {
  transaction: PayoutSimulationTransaction;
  as?: FunctionComponent<TransactionItemProps>;
}) => {
  const { amount, currency } = transaction;

  const TransactionItemComponent = as || TransactionItem;

  return (
    <TransactionItemComponent
      icon="transactionPayment"
      title={<Text variant="primary">Payment</Text>}
      iconColor="brandGreen.500"
      amount={
        <Flex alignItems="center" gap={1}>
          <Text size="inherit" variant="number" color={amount < 0 ? 'inherit' : 'brandGreen.500'}>{`${
            amount < 0 ? '-' : '+'
          }${formatPrice(Math.abs(amount))}`}</Text>
          <Badge variant="textual">{currency}</Badge>
        </Flex>
      }
    />
  );
};
