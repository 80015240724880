import {
  formatPhone,
  getActiveFormattingMask,
  guessCountryByPartialPhoneNumber,
} from '@oz_dev/react-international-phone';
import { type Dayjs, type OpUnitType } from 'dayjs';

import {
  type BeneficiaryType,
  CardIssuingLimitInterval,
  type DDBankAccount,
  type WithdrawalBankAccount,
} from '@fin/types';

import { dayjs } from './dayjsInstance';

export const formatApiDate = (date: Dayjs | null) => (date ? dayjs(date).format('MMM DD, YYYY') : null);

export const formatDate = (date?: string | Dayjs | null, type?: 'date' | 'time' | 'fullLong' | 'fullShort') => {
  if (!date || !dayjs(date).isValid()) return '';
  let formatType = 'll';

  if (type === 'time') formatType = 'LT';
  if (type === 'fullLong') formatType = 'LLL';
  if (type === 'fullShort') formatType = 'lll';

  return dayjs(date).format(formatType);
};

export const formatIsoDate = (date?: string | Dayjs | null) => (date ? dayjs(date).format('YYYY-MM-DD') : null);

// FUTURE IMPROVEMENT: Add support for different languages ([on] keyword)
export const formatTransactionDate = (date?: string | Dayjs | null) =>
  date && dayjs(date).isValid() ? dayjs(date).format('LT [on] LL') : '';

export const formatPrice = (value?: number | null, options?: Intl.NumberFormatOptions) =>
  value !== null && value !== undefined
    ? new Intl.NumberFormat('en-CA', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
      }).format(value)
    : '';

export const getNumberWithOrdinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return `${n}${s[(v - 20) % 10] || s[v] || s[0]}`;
};

export const getArticle = (noun: string): string =>
  ['a', 'e', 'i', 'o', 'u'].includes(noun.charAt(0).toLowerCase()) ? 'an' : 'a';

export const getBeneficiaryName = (
  beneficiary:
    | Pick<BeneficiaryType, 'nickname' | 'vendorName' | 'beneficiaryFirstName' | 'beneficiaryLastName'>
    | undefined,
) => {
  if (!beneficiary) return '';

  return (
    beneficiary.nickname ||
    beneficiary.vendorName ||
    `${beneficiary.beneficiaryFirstName || ''} ${beneficiary.beneficiaryLastName || ''}`
  );
};

export const getBankAccountTitle = (account: WithdrawalBankAccount | DDBankAccount | undefined) => {
  if (!account) return '';

  const accountNumber = account.bankAccountInformation.fields.find((i) => i.key === 'account_number')?.value;

  const accountNumberValue = accountNumber ? `****${accountNumber.slice(-4)}` : '';
  return account.nickname ? `${account.nickname} (${accountNumberValue})` : accountNumberValue;
};

export const prettifyPostalCode = (postalCode: string) => postalCode.trim().toUpperCase();

export const stripExtraSymbols = (str: string) => str.replaceAll(/[^\w\d]/g, '');

export const formatFileSize = (size: number) => {
  const sign = Math.sign(size);
  const absSize = Math.abs(size);
  const units = ['B', 'kB', 'MB', 'GB', 'TB'];
  let i = 0;

  if (absSize >= 1) {
    i = Math.min(Math.floor(Math.log(absSize) / Math.log(1024)), units.length - 1);
  }

  return `${((absSize / Math.pow(1024, i)) * sign).toFixed(2)} ${units[i]}`;
};

export const formatPhoneNumber = (phone: string | undefined | null) => {
  if (!phone) return '';

  const country = guessCountryByPartialPhoneNumber({ phone }).country;

  if (!country) return phone;

  return formatPhone(phone, {
    prefix: '+',
    mask: getActiveFormattingMask({
      phone,
      country,
    }),
    maskChar: '.',
    dialCode: country.dialCode,
    charAfterDialCode: ' ',
    forceDialCode: true,
  });
};

export const roundMoney = (amount: number): number => Math.round(amount * 100) / 100;

export const getCardLimitResetNextDate = (interval: CardIssuingLimitInterval) => {
  const values: Record<CardIssuingLimitInterval, OpUnitType> = {
    [CardIssuingLimitInterval.ALL_TIME]: 'year',
    [CardIssuingLimitInterval.MONTHLY]: 'month',
    [CardIssuingLimitInterval.WEEKLY]: 'week',
    [CardIssuingLimitInterval.DAILY]: 'day',
  };

  return dayjs().endOf(values[interval]).add(1, 'day').startOf('day');
};
