import { Box } from '@chakra-ui/react';

import { ColumnHeader } from './ColumnHeader';
import type { TableProps } from './types';

type TableHeaderProps<T> = Pick<TableProps<T>, 'columns' | 'sortBy' | 'onChangeSort' | 'loading' | 'withSkeleton'> & {
  isBorderHidden: boolean;
  minColumnsWidth?: number[];
};

export const TableHeader = <T,>({
  isBorderHidden,
  columns,
  sortBy,
  onChangeSort,
  loading,
  withSkeleton,
  minColumnsWidth,
}: TableHeaderProps<T>) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="subgrid"
      gridColumn="1/-1"
      borderBottom="1px"
      borderBottomColor={isBorderHidden ? 'transparent' : 'borderStroke'}
      className="row"
      position="sticky"
      top={0}
      bg="bg.0"
      zIndex={2}
    >
      {columns.map((column, i, arr) => (
        <ColumnHeader
          key={`${column.title || ''}_${i}`}
          title={column.title || ''}
          icon={column.icon}
          onClick={onChangeSort}
          field={column.field}
          sortBy={sortBy}
          className={`columnHeader cell ${i === 0 ? 'first' : ''} ${arr.length - 1 === i ? 'last' : ''}`}
          bg="white"
          renderHeader={column.renderHeader}
          withSkeleton={withSkeleton}
          loading={loading}
          minW={minColumnsWidth?.[i] ? `${minColumnsWidth[i]}px` : 'auto'}
        />
      ))}
    </Box>
  );
};
