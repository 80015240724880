import { useEffect } from 'react';

import { SignedIn, SignedOut, useAuth } from '@clerk/clerk-react';
import { useLocation } from 'react-router-dom';

import { CloseAllModals } from '@fin/components';

import { NotFound } from '@app/modules/NotFound';
import { UserService } from '@app/services';

import { MainRouting } from './MainRouting';
import { PreLoggedInRouting } from './PreLoggedInRouting';

const FINOFO_ADMIN_ORG_SLUG = 'finofo-admin';

export const AppRouting = () => {
  const { orgSlug, isSignedIn } = useAuth();

  useEffect(() => {
    if (!isSignedIn || orgSlug !== FINOFO_ADMIN_ORG_SLUG) {
      UserService.logout();
    }
  }, [isSignedIn, orgSlug]);

  const location = useLocation();

  return (
    <>
      <CloseAllModals pathname={location.pathname} isLoggedIn={!!isSignedIn} />
      <SignedIn>{orgSlug === FINOFO_ADMIN_ORG_SLUG ? <MainRouting /> : <NotFound showLogout />}</SignedIn>
      <SignedOut>
        <PreLoggedInRouting />
      </SignedOut>
    </>
  );
};
